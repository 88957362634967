import { useState, useEffect, useCallback } from "react";
import moment from "moment";

import getMetrics from "../api/getMetrics";

/**
 * Time range
 * @typedef {Object} Range
 * @property {string} from - Formatted date
 * @property {string} to - Formatted date
 */

/**
 * @param {number|Range} range a range object or a number to generate a range from.
 *
 * @returns {Range} the range
 */
const getRange = range => {
  if (typeof range === "number") {
    const now = moment();

    return {
      to: now.format(),
      from: now.subtract(range, "seconds").format()
    };
  }

  return range;
};

/**
 * sets up a polling every 10 seconds based on the query
 *
 * @param {String} chorusURL
 * @param {Object} query
 * @param {number} range in seconds
 * @returns [ { metrics, range }, isLoading, error ]
 */
const useMetrics = (chorusURL, query, range) => {
  const [metrics, setMetrics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const load = useCallback(async () => {
    if (!query || !range) {
      return;
    }
    try {
      const data = await getMetrics(chorusURL, {
        ...query,
        range: getRange(range)
      });
      setError(undefined);
      setMetrics({ metrics: data, range });
    } catch (error) {
      setError(error);
    }
  }, [query, range]);

  useEffect(() => {
    let isCanceled = false;
    let id = -1;
    async function poll() {
      const shouldPoll = typeof range === "number";
      await load();
      if (id && shouldPoll) {
        id = window.setTimeout(poll, 10000);
      }
    }

    (async () => {
      setIsLoading(true);
      await poll();
      if (isCanceled) {
        return;
      }
      setIsLoading(false);
    })();

    return () => {
      isCanceled = true;
      window.clearTimeout(id);
      id = undefined;
    };
  }, [range, query]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return [metrics, isLoading, error];
};

export default useMetrics;
