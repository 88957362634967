import styled from "styled-components";

import { css, SECTION, HEADER, LINK } from "Libs/themes";

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const Loading = styled.div`
  text-align: center;
  color:var(--section-night-color,var(--section-night,var(--night)));
  h2 {
    margin-bottom: 16px;
    color:var(--header-night-color,var(--header-night,var(--night)));
  }
  p {
    line-height: 25px;
    font-size: 15px;
  }
  .icon-loading {
    width: 80px;
    height: auto;
    margin-bottom: 30px;
  }
  .graphic {
    margin-bottom: 40px;
  }
  a {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
  .close-link {
    margin-bottom: 160px;
    a {
      display: inline-block;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const ErrorHelpText = styled.div`
  margin-bottom: 32px;
`;
