import styled from "styled-components";
import { css, SECTION, getCSSVarString, LINK } from "Libs/themes";
import Link from "Components/styleguide/Link";

export const Layout = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 32px;
  min-width: 268px;
  max-height: 183px;
  flex: 0.5;
  border-radius: 4px;
  cursor: not-allowed;
  .* {
    pointer-events: none;
  }
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 12px;
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
  ${props => props.withTopSpacing && "margin-top: 32px"};
  ${props =>
    props.border &&
    `
    border-${props.border}: 1px solid ${getCSSVarString(
      SECTION,
      "skye-light",
      "border-color"
    )};
    margin-${props.border}: 31px;
    padding-${props.border}: 30px;
  `};
`;

export const Text = styled.span`
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 14px;
  line-height: 22px;
  min-height: 32px;
`;

export const LearnMore = styled.div`
  margin-top: 32px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DisabledLink = styled(Link)`
  color:var(--link-light-color,var(--link-light,var(--light)));
  pointer-events: none;
`;
