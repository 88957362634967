import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import * as S from "./IntegrationScopesText.styles";

const IntegrationScopesText = ({ type, link }) => {
  const intl = useIntl();

  const getIntlKey = key => {
    if (intl.messages[`integration.configure.${type}.${key}`] !== undefined)
      return `integration.configure.${type}.${key}`;
    return `integration.configure.${key}`;
  };

  return (
    <S.ConfigureBody>
      <p>
        {intl.formatMessage(
          {
            id: getIntlKey("sub_intro"),
            defaultMessage: "Sub Intro"
          },
          {
            // eslint-disable-next-line react/display-name
            a: (...chunks) => (
              <a href={link} target="_blank">
                {chunks}
              </a>
            )
          }
        )}
      </p>

      <S.TitleScopesText>
        {intl.formatMessage(
          {
            id: getIntlKey("ensure_scopes"),
            defaultMessage: "Ensure Scopes"
          },
          {
            b: (...chunks) => <b>{chunks}</b> // eslint-disable-line react/display-name
          }
        )}
      </S.TitleScopesText>

      <S.ScopesText>
        {intl.formatMessage(
          {
            id: getIntlKey("scopes"),
            defaultMessage: "Scopes"
          },
          {
            // eslint-disable-next-line react/display-name
            br: <br />,
            // eslint-disable-next-line react/display-name
            code: (...chunks) => <code style={{ marginLeft: 0 }}>{chunks}</code>
          }
        )}
      </S.ScopesText>
    </S.ConfigureBody>
  );
};

IntegrationScopesText.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string
};

export default IntegrationScopesText;
