import { useCallback, useMemo } from "react";
import moment from "moment";

import useURLSearchState, { transforms } from "Hooks/useURLSearchState";

import { timeframes as defaultTimeframes } from "../settings";

const isValidRange = (from, to) => {
  const diff = moment.unix(to).diff(moment.unix(from), "seconds");
  const isUnderADay = diff <= 60 * 60 * 24;
  const fromNow = moment().diff(moment.unix(to), "seconds");
  const startsWithinADay = fromNow <= 60 * 60 * 24;
  return isUnderADay && startsWithinADay;
};

const isUnderMinimumRange = (from, to, minimumLength) => {
  const diff = moment.unix(to).diff(moment.unix(from), "seconds");
  return diff < minimumLength;
};

const getMinimumEndTime = (from, minimumRangeLength) => {
  return moment.unix(from).add(minimumRangeLength, "seconds");
};

const toUNIXTimestamp = string => moment(string).unix();

const derivateIntervalFromRange = (from, to) => {
  const diff = to.diff(from, "seconds");
  if (diff < 60 * 30) {
    return 10;
  } else if (diff <= 60 * 60 * 2) {
    return 60;
  } else if (diff <= 60 * 60 * 5) {
    return 60 * 5;
  } else {
    return 60 * 10;
  }
};

const customTransform = (value, key) => {
  switch (key) {
    case "from":
    case "to":
      return transforms.number(value);
    default:
      return value;
  }
};

const initialState = {
  from: undefined,
  to: undefined,
  range: undefined
};

/**
 * Takes a custom timeframe or an id of a default timeframe.
 *
 * @typedef {(timeframe: Timeframe | string) => void} setRange
 */

/**
 * Open timeframes represents a rolling time based on the difference of the
 * current time and a specified distance.
 *
 * @typedef {Object} OpenTimeframe
 * @property {string} [id] unique identifier of the the timeframe
 * @property {number} range In seconds - time from now
 * @TODO remove this property and calculate the intervals before making the request
 * @property {number} interval In seconds - Distance between data points
 */

/**
 * Closed timeframes represent a date range specified by a from and to date.
 *
 * @typedef {Object} ClosedTimeframe
 * @property {string} from start timestamp
 * @property {string} to end timestamp
 */

/**
 * usage
 * ```js
 *      const [range, setRange] = useRange();
 * ```
 *
 * Url formats:
 *
 * open timeframes:
 * `?range=15m`
 * `?range=24h`
 *
 * closed timeframes:
 * `?from=<unix-timestamp>&to=<unix-timestamp>`
 *
 * @returns {[ClosedTimeframe | OpenTimeframe, setRange]} tuple including the
 * current timeframe and a function that sets the value
 */
const useRange = (
  timeframes = defaultTimeframes,
  minimumRangeLength = null
) => {
  const [state, setState] = useURLSearchState(
    initialState,
    null,
    customTransform
  );

  const setRange = useCallback(
    range => {
      if (typeof range === "string") {
        setState({
          from: undefined,
          to: undefined,
          range
        });
      } else {
        setState({
          from: toUNIXTimestamp(range.from),
          to: toUNIXTimestamp(range.to),
          range: undefined
        });
      }
    },
    [setState]
  );

  const range = useMemo(() => {
    if (state.range) {
      return (
        timeframes.find(({ label }) => label.startsWith(state.range)) ||
        timeframes[0]
      );
    } else if (state.from && state.to) {
      if (!isValidRange(state.from, state.to)) {
        setState({
          from: undefined,
          to: undefined,
          range: timeframes[0].label
        });

        return timeframes[0];
      }
      const from = moment.unix(state.from);
      const to = moment.unix(state.to);

      // Extend the query's range to meet minimum requirement (currently applies to Grid only)
      if (
        minimumRangeLength &&
        isUnderMinimumRange(state.from, state.to, minimumRangeLength)
      ) {
        const newEndTime = getMinimumEndTime(state.from, minimumRangeLength);
        return {
          from: from.format(),
          to: newEndTime.format(),
          interval: derivateIntervalFromRange(from, newEndTime)
        };
      }

      return {
        from: from.format(),
        to: to.format(),
        interval: derivateIntervalFromRange(from, to)
      };
    } else {
      return timeframes[0];
    }
  }, [state, timeframes]);

  const result = useMemo(() => [range, setRange], [range, setRange]);

  return result;
};

export default useRange;
