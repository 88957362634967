import styled, { keyframes } from "styled-components";

import { css, ICON } from "Libs/themes";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ActivityLine = styled.rect`
  animation: 0.1s linear 1 ${fadeIn};
  fill:var(--icon-periwinkle-grey-fill,var(--icon-periwinkle-grey,var(--periwinkle-grey)));
  cursor: pointer;
  ${({ someActive, isActive }) =>
    someActive && isActive ?"fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)))": ""};

  ${({ someActive, isActive }) =>
    someActive && !isActive ? `opacity: 0.3;` : ""};
`;
