import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { LiveMessage } from "react-aria-live";
import moment from "moment";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { getSubscriptionEditUrl } from "Libs/utils";
import { getCSSVarString, ICON } from "Libs/themes";

import { reloadProject, gitProjectSelector } from "Reducers/project";
import { environmentsSelector } from "Reducers/environment";
import {
  addDomain,
  deleteDomain,
  initDomain,
  loadDomains,
  makeDefaultDomain,
  domainErrorSelector,
  domainStatusSelector,
  domainsSelector,
  domainsLoadingSelector,
  lastDomainUpdatedSelector
} from "Reducers/project/settings/domain";
import { subscriptionSelector } from "Reducers/subscription";

import { HelpIcon } from "@platformsh/ui-kit";
import { Link, AddButton } from "ds/Button";

import ChevronIcon from "Icons/ChevronIcon";
import Loading from "Components/Loading";
import ModalConfirmDelete from "../../components/DeleteModal";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageMeta from "Components/PageMeta";

import SettingLine from "Components/SettingLine";
import DomainForm from "../../components/DomainForm";
import DomainItem from "../../components/DomainItem";
import Sticker from "ds/Stickers";

import * as S from "./ProjectDomains.styles";

const ProjectDomains = ({ isNew = false, url }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationId, projectId, domainId } = useDecodedParams();

  const [modal, setModal] = useState({ isOpen: false });
  const [isChanged, setIsChanged] = useState(false);
  const [sort, setSort] = useState({ type: "created_at", desc: true });

  const encodeDomainId = id => {
    return id.replace(/\./g, "");
  };

  const project = useSelector(state =>
    gitProjectSelector(state, { organizationId, projectId })
  );
  const environments = useSelectorWithUrlParams(environmentsSelector);
  const subscription = useSelectorWithUrlParams(subscriptionSelector, {
    id: project.subscription_id
  });

  const domains = useSelectorWithUrlParams(domainsSelector)?.toArray();
  const loading = useSelectorWithUrlParams(domainsLoadingSelector) ?? true;
  const lastUpdated = useSelectorWithUrlParams(lastDomainUpdatedSelector);

  const currentDomain = domains?.find(
    elt => domainId === encodeDomainId(elt.id)
  );
  const domainParams = {
    organizationId,
    projectId,
    domainId: isNew ? "new" : currentDomain?.id
  };
  const errors = useSelector(state => domainErrorSelector(state, domainParams));
  const status = useSelector(state =>
    domainStatusSelector(state, domainParams)
  );

  useEffect(() => {
    dispatch(initDomain(domainParams));
  }, [domainId]);

  useEffect(() => {
    if (!["added", "deleted", "updated"].includes(status)) return;

    dispatch(reloadProject({ projectId }));

    if (status === "added") {
      history.push(`${url}/${encodeURIComponent(encodeDomainId(lastUpdated))}`);
    } else cancel();
  }, [status]);

  useEffect(() => {
    if (project.id && project.subscription?.plan !== "development") {
      dispatch(loadDomains({ organizationId, project }));
    }
  }, [project?.id]);

  useEffect(() => {
    if (loading || isNew) return;
    if (domains?.length || (domainId && currentDomain)) return;

    if (project.id && project.subscription?.plan !== "development") {
      history.replace(`${url}/-/new`);
    }
    if (domainId) cancel();
  }, [loading, project, domains, domainId]);

  useEffect(() => {
    if (domains?.length === 0) {
      dispatch(reloadProject({ projectId }));
    }
  }, [domains?.length]);

  const checkIsDefault = id => {
    return id === project.default_domain;
  };

  const cancel = () => {
    history.push(url);
  };

  const handleMakeItDefault = domain => {
    dispatch(
      makeDefaultDomain({
        organizationId,
        project,
        domain
      })
    );
  };

  const handleSave = data => {
    setIsChanged(false);
    dispatch(addDomain({ organizationId, project, data }));
  };

  const handleCancel = () => {
    if (isChanged && !modal.isOpen) {
      setModal({ isOpen: true, type: "leave" });
      setIsChanged(true);
    } else {
      setIsChanged(false);
      cancel();
    }
  };

  const handleDelete = (domain, newDefault) => {
    dispatch(
      deleteDomain({
        domain,
        newDefault,
        organizationId,
        project
      })
    );
  };

  const expand = id => {
    if (isNew && id === "new") return;
    if (!id || domainId === encodeDomainId(id)) {
      handleCancel();
    } else {
      if (isChanged && !modal.isOpen) {
        setModal({ isOpen: true, type: "leave", next: id });
        setIsChanged(true);
      } else {
        history.push(
          `${url}/${
            id === "new" ? "-/new" : encodeURIComponent(encodeDomainId(id))
          }`
        );
        setIsChanged(false);
      }
    }
  };

  const handleSort = type => {
    setSort({ type, desc: type === sort.type ? !sort.desc : true });
  };

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );
  const hasDomainPermission = useMemo(
    () => project?.hasPermission && project.hasPermission("#manage-domains"),
    [project]
  );
  const mainEnvironment = useMemo(
    () => environments?.find(environment => environment.is_main),
    [environments]
  );
  const isDevelopment = useMemo(
    () => project?.subscription?.plan === "development",
    [project]
  );
  const hasDomains = useMemo(() => domains?.length > 0, [domains]);
  const sortDomains = useMemo(() => {
    const list = domains?.sort((a, b) =>
      a.data[sort.type].localeCompare(b.data[sort.type])
    );
    if (sort.desc) {
      return list?.reverse();
    }
    return list;
  }, [domains, sort]);

  if (!mainEnvironment) return null;

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project.title} domain settings`}
        aria-live="polite"
      />
      <PageMeta title={`Domains | ${project?.title}`} />

      {hasDomainPermission && hasDomains && (
        <AddButton
          id="add-new-domain"
          css={{ float: "right" }}
          onClick={() => expand("new")}
        >
          {intl.formatMessage({
            id: "settings.domains.add.button"
          })}
        </AddButton>
      )}

      <S.Heading2 id="settings-heading">
        {intl.formatMessage({ id: "domains" })}
      </S.Heading2>
      <S.Info>
        {intl.formatMessage(
          {
            id: `settings.domains.info.${
              project.default_domain ? "has_domain" : "empty"
            }`
          },
          {
            code: (...chunks) => <code>{chunks}</code>, // eslint-disable-line react/display-name
            domain: project.default_domain || mainEnvironment.data.edge_hostname
          }
        )}
      </S.Info>

      <S.Help>
        <HelpIcon />
        <Link href={intl.formatMessage({ id: "links.documentation.domain" })}>
          {intl.formatMessage({ id: "settings.domains.help" })}
        </Link>
      </S.Help>

      {isDevelopment ? (
        <Sticker
          priority="information"
          variant="button"
          text={intl.formatMessage({ id: "settings.domains.development" })}
          button={
            subscriptionEditUrl && (
              <S.ButtonLink
                to={
                  !subscriptionEditUrl.external && {
                    pathname: subscriptionEditUrl.url,
                    state: { from: "project_settings" }
                  }
                }
                href={subscriptionEditUrl.external && subscriptionEditUrl.url}
              >
                {intl.formatMessage({
                  id: "settings.domains.upgrade_plan"
                })}
              </S.ButtonLink>
            )
          }
        />
      ) : (
        <section aria-labelledby="settings-heading">
          {isNew && (
            <SettingLine
              id={`project-domain-new`}
              isNew={true}
              addNewTitle={intl.formatMessage({
                id: `settings.domains.add${hasDomains ? ".new" : ""}.title`
              })}
              noToggle={!hasDomains}
              isOpen={true}
              onClick={expand}
            >
              <DomainForm
                key="domain-new-form"
                errors={errors}
                isChangedUpdate={() => setIsChanged(true)}
                isFirst={!hasDomains}
                isLoading={status === "pending"}
                onSave={handleSave}
                onCancel={handleCancel}
              />
            </SettingLine>
          )}

          {loading ? (
            <Loading />
          ) : (
            <>
              {domains?.length > 1 && (
                <S.Sort>
                  <S.Label
                    onClick={() => handleSort("name")}
                    onKeyUp={e => e.keyCode === 13 && handleSort("name")}
                    as="button"
                  >
                    {intl.formatMessage({ id: "settings.domains.name" })}
                    <ChevronIcon
                      color={
                        sort.type === "name"
                          ?"var(--icon-skye-fill,var(--icon-skye,var(--skye)))":"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"
                      }
                      isOpen={sort.type === "name" && sort.desc}
                      animate={true}
                    />
                  </S.Label>

                  <S.Label
                    onClick={() => handleSort("created_at")}
                    onKeyUp={e => e.keyCode === 13 && handleSort("created_at")}
                    as="button"
                  >
                    {intl.formatMessage({ id: "settings.domains.date" })}
                    <ChevronIcon
                      color={
                        sort.type === "created_at"
                          ?"var(--icon-skye-fill,var(--icon-skye,var(--skye)))":"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"
                      }
                      isOpen={sort.type === "created_at" && sort.desc}
                      animate={true}
                    />
                  </S.Label>
                </S.Sort>
              )}

              {sortDomains?.map(domain => {
                const isDefault = checkIsDefault(domain.id);

                return (
                  <SettingLine
                    key={`domain-${domain.id}`}
                    id={`project-domain-list-${encodeDomainId(domain.id)}`}
                    isOpen={domainId === encodeDomainId(domain.id)}
                    onClick={() => expand(domain.id)}
                    openText={intl.formatMessage({ id: "edit" })}
                    info={
                      <S.InfoLayout>
                        <S.Domain>{domain.name}</S.Domain>
                        {isDefault && (
                          <S.Default>
                            {intl.formatMessage({
                              id: "settings.domains.default"
                            })}
                          </S.Default>
                        )}
                        <S.Date>
                          {moment(domain.data.updated_at).format(
                            "MMMM D, YYYY"
                          )}
                        </S.Date>
                      </S.InfoLayout>
                    }
                  >
                    {domainId === domain.id.replace(/\./g, "") && (
                      <DomainItem
                        key={`domain-${encodeDomainId(domain.id)}-form`}
                        cname={mainEnvironment.data.edge_hostname}
                        domain={domain}
                        errors={errors}
                        isDefault={isDefault}
                        isLoading={status === "pending"}
                        makeItDefault={() => handleMakeItDefault(domain)}
                        onDelete={() =>
                          setModal({ isOpen: true, domain, type: "delete" })
                        }
                      />
                    )}
                  </SettingLine>
                );
              })}
            </>
          )}

          {modal.isOpen && (
            <>
              <ModalConfirmDelete
                closeModal={() => setModal({ isOpen: false })}
                deleteDomain={handleDelete}
                domain={modal.domain}
                domains={domains}
                isDefault={checkIsDefault(modal.domain.id)}
                isOpen={modal.isOpen && modal.type === "delete"}
              />
              <ModalConfirmLeaveForm
                isOpen={modal.isOpen && modal.type === "leave"}
                closeModal={() => setModal({ isOpen: false })}
                continueFunction={() => expand(modal.next)}
                cancelFunction={() => setModal({ isOpen: false })}
              />
            </>
          )}
        </section>
      )}
    </S.Wrapper>
  );
};

ProjectDomains.propTypes = {
  isNew: PropTypes.bool,
  url: PropTypes.string.isRequired
};

export default withReducers({
  project: () => import("Reducers/project"),
  projectDomain: () => import("Reducers/project/settings/domain")
})(ProjectDomains);
