import styled, { keyframes } from "styled-components";
import { css, ICON, SECTION } from "Libs/themes";

export const Layout = styled.div`
  .header-arrow + .header-arrow {
    border-top: 1px solid #d8e2ef;
  }
  .setting-line {
    padding-right: 16px;
  }
  .setting-content {
    flex: 1 1 0;
  }
`;

const expandOut = keyframes`
  0% {
    width: 68px;
  }
  100% {
    width: 176px;
  }
`;

const expandIn = keyframes`
  0% {
    width: 176px;
  }
  100% {
    width: 68px;
  }
`;

export const FiltersDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: -5px;
  .info-button > button {
    &.open svg {
      transform: none;
    }
    span.icon svg rect {
      fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)));
    }
  }
  .input-wrapper {
    width: 100px;
  }
  .select-box__control {
    background: transparent;
  }

  label {
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
  }

  .search-wrapper {
    height: inherit;
    margin-right: 8px;
    display: none;
    input.search {
      width: 86px;
      animation: ${expandIn} 0.18s linear 1;
      padding: 0 8px 0 32px;
      &.active {
        width: 176px;
        animation: ${expandOut} 0.18s linear 1;
      }
    }
  }

  @media (min-width: 580px) {
    .search-wrapper {
      display: inline-block;
    }
  }

  > div:last-child {
    margin-left: 24px;
  }
`;

export const EmptyWrapper = styled.div`
  text-align: center;
  margin: 96px 0 130px 0;
  width: 100%;
  h2 {
    margin-bottom: 16px;
  }
  p {
    color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
    font-size: 14px;
    width: 432px;
    max-width: 100%;
    margin: 0 auto 40px;
  }
  span.create-project {
    display: inline-block;
    margin-bottom: 170px;
  }
`;

export const EmptyImage = styled.div`
  margin: 0 auto 48px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
`;
