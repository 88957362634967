import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Map } from "immutable";
import { LiveMessage } from "react-aria-live";

import ProjectBasicForm from "./ProjectBasicForm";
import Heading2 from "Components/styleguide/Heading2";
import SettingLine from "Components/SettingLine";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import Loading from "Components/Loading";
import PageMeta from "Components/PageMeta";

import { updateProject } from "Reducers/project";
import { subscriptionSelector } from "Reducers/subscription";

import AccessibleTooltip from "Components/AccessibleTooltip";
import PlanNav from "../../../components/PlanNav";

import * as S from "./ProjectBasicSettings.styles";

const ProjectBasicSettings = () => {
  const [isOpen, setOpen] = useState(true);
  const [isModalLeaveOpen, setModalLeaveOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  const { organizationId, projectId } = useParams();

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId], Map())
  );
  const loading = useSelector(state => state.project?.get("loading", true));
  const subscription = useSelector(state =>
    subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project.subscription_id
    })
  );

  const onSave = data => {
    dispatch(updateProject(organizationId, projectId, data));
    setOpen(false);
    setIsEdit(false);
  };

  const toggle = () => {
    if (isEdit && !isModalLeaveOpen) {
      setModalLeaveOpen(true);
    } else {
      setModalLeaveOpen(false);
      setOpen(!isOpen);
    }
  };

  if (!project?.id) {
    return false;
  }

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project.title} basic settings`}
        aria-live="polite"
      />
      {!process.env.ACCOUNT_PAGES_DISABLED && subscription && (
        <PlanNav project={project} subscription={subscription} />
      )}
      <PageMeta title={`Settings | ${project.title}`} />
      <Heading2 id="settings-heading">
        {intl.formatMessage({ id: "general", defaultMessage: "General" })}
      </Heading2>

      <section aria-labelledby="settings-heading">
        {loading && <Loading />}
        <SettingLine
          id="project-basics"
          info={
            <S.Info className="project-info">
              <span className="project-name">
                <span>
                  {intl.formatMessage({
                    id: "project.settings.basic.name",
                    defaultMessage: "Project name"
                  })}
                </span>{" "}
                <AccessibleTooltip
                  id="project-title"
                  tooltipProps={{
                    id: "project-name-full",
                    wrapper: "div",
                    children: project.title
                  }}
                  as="strong"
                >
                  {project.title}
                </AccessibleTooltip>
              </span>
              <span id="project-owner">{organizationId}</span>
            </S.Info>
          }
          isOpen={isOpen}
          onClick={toggle}
          openText={intl.formatMessage({ id: "edit" })}
        >
          {isOpen && (
            <ProjectBasicForm
              project={project}
              closeForm={toggle}
              onSave={onSave}
              onChange={() => setIsEdit(true)}
            />
          )}
        </SettingLine>
      </section>

      <ModalConfirmLeaveForm
        isOpen={isModalLeaveOpen}
        closeModal={() => setModalLeaveOpen(false)}
        continueFunction={toggle}
        cancelFunction={() => setModalLeaveOpen(false)}
      />
    </S.Wrapper>
  );
};

export default ProjectBasicSettings;
