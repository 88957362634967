import styled from "styled-components";
import { css, SECTION, getCSSVarString, HEADER } from "Libs/themes";

export const Layout = styled.div`
  display: flex;
  @media (max-width: 550px) {
    flex-wrap: wrap-reverse;
    margin-top: 0;
    min-width: auto;
    padding: 24px;
  }
  gap: 88px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 32px 72px 32px 32px;
  min-width: 300px;
  flex: 0.5;
  margin-top: 44px;
  min-height: 143px;

  border-radius: 4px;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 12px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  text-transform: capitalize;
  ${props => props.withTopSpacing && "margin-top: 32px"};
  ${props =>
    props.border &&
    `
    border-${props.border}: 1px solid ${getCSSVarString(
      SECTION,
      "skye-light",
      "border-color"
    )}};
    margin-${props.border}: 31px;
    padding-${props.border}: 30px;
  `};
`;

export const Text = styled.span`
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 14px;
  line-height: 22px;
`;

export const LearnMore = styled.div`
  margin-top: 12px;
`;

export const IntroductionIllustration = styled.img`
  width: 132px;
  height: 94px;
  margin-top: 19px;
`;

export const TextAndLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;

export const LeftPane = styled.div`
  display: flex;
  flex-direction: column;
`;
