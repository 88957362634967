import styled, { css as styledCSS } from "styled-components";

import InfoDialog from "Components/InfoDialog";

import { makePalette } from "Libs/theme";
import { css, HEADER } from "Libs/themes";

export const palette = makePalette(palette => ({
  max_color: {
    default: palette.granite
  },
  light_text: {
    default: palette.granite,
    dark: palette.periwinkleGrey,
    contrast: palette.ebonyLight
  }
}));

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  min-width: 0;
  flex: 1;

  ${({ flexEnd }) =>
    flexEnd &&
    styledCSS`
      justify-content: flex-end;
    `};
`;

export const Metadata = styled(Row)`
  flex-grow: 2;
`;

export const Nowrap = styled.div`
  white-space: nowrap;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Value = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const Max = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${palette.max_color};
`;

export const Chart = styled.div`
  height: 18px;
  width: 64px;
  outline: 1px solid red;
`;

export const Percentage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;

export const Mountpoint = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${palette.light_text};
`;

export const StyledInfoDialog = styled(InfoDialog)`
  margin-right: 0;
`;

export const MetricNameLayout = styled.div`
  display: flex;
  gap: 8px;
`;
