import styled from "styled-components";

import { css, INPUT } from "Libs/themes";

export const Wrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
  .project-basic-setting {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 10px 15px;
    color:var(--input-grey-light-color,var(--input-grey-light,var(--grey-light)));
    cursor: pointer;
    .project-name,
    .project-owner {
      padding: 0 30px 0 0;
    }
    .project-info {
      display: flex;
      align-items: center;
    }
    .project-name {
      font-size: 16px;
      span {
        min-width: 104px;
      }
    }
    .project-owner {
      font-size: 15px;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  > span:first-of-type {
    width: 50%;
  }

  > span:nth-of-type(2) {
    min-width: 20%;
    padding-left: 16px;
  }
  > span {
    display: inline-flex;
  }
  .project-name {
    flex-direction: column;
    span {
      min-width: 104px;
      width: 104px;
    }
  }
  strong {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  @media (min-width: 980px) {
    .project-name {
      flex-direction: row;
    }
    strong {
      margin-left: 8px;
    }
  }
`;
