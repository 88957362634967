import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import Attachments from "../../../../common/components/Attachments";
import Header from "../../../../common/components/Header";

import TextAreaField from "Components/fields/TextAreaField";
import Button from "ds/Button";

import { Form, Label, Actions, Layout } from "./styles";

const AddComment = ({ onSubmit, isLoadingNewComment, ...props }) => {
  const intl = useIntl();

  const initialState = {
    body: "",
    attachments: null
  };
  const [comment, setComment] = useState(initialState);

  const onBodyChange = event =>
    setComment({ ...comment, body: event.target.value });

  const onAttachmentsChange = attachments =>
    setComment({ ...comment, attachments });

  const addComment = event => {
    event.preventDefault();
    onSubmit(comment);
    reset(event);
  };

  const reset = event => {
    event.preventDefault();
    setComment(initialState);
  };

  const isValid = comment.body !== "";

  return (
    <Layout {...props}>
      <Header>
        <FormattedMessage id="tickets.comments.header" />
      </Header>

      <Form onSubmit={addComment} onReset={reset}>
        <Label htmlFor={comment}>
          <FormattedMessage id="tickets.comments.add" />
        </Label>

        <TextAreaField
          id="comment"
          placeholder={intl.formatMessage({
            id: "tickets.comments.comment.placeholder"
          })}
          width="100%"
          required
          value={comment.body}
          onChange={onBodyChange}
        />

        <Attachments
          append
          value={comment.attachments}
          onChange={onAttachmentsChange}
        />

        <Actions>
          <Button type="submit" disabled={!isValid || isLoadingNewComment}>
            <FormattedMessage id="tickets.comments.send" />
          </Button>

          <Button type="reset" variant="secondary">
            <FormattedMessage id="cancel" />
          </Button>
        </Actions>
      </Form>
    </Layout>
  );
};

AddComment.propTypes = {
  onSubmit: PropTypes.func,
  isLoadingNewComment: PropTypes.bool
};

export default AddComment;
