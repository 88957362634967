export const timeframes = [
  {
    id: "short",
    label: "15m",
    range: 60 * 15,
    interval: 10
  },
  {
    id: "medium",
    label: "1h",
    range: 60 * 60,
    interval: 60
  },
  {
    id: "long",
    label: "24hrs",
    range: 60 * 60 * 24,
    interval: 60 * 10
  }
];

export const gridTimeframes = [
  {
    id: "short",
    label: "15m",
    range: 60 * 15,
    interval: 60
  },
  {
    id: "medium",
    label: "1h",
    range: 60 * 60,
    interval: 60
  },
  {
    id: "long",
    label: "8hrs",
    range: 60 * 60 * 8,
    interval: 60 * 10
  }
];

export const gridFields = {
  "cpu.average.value":
    "SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'service')",
  "cpu.average.percentage":
    "SUM(100 * (`cpu.user` + `cpu.kernel`) / ( `interval` * `cpu.cores`), 'service')",
  "cpu.max": "`cpu.cores`",
  "memory.average.value":
    "SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, 'service')",
  "memory.average.percentage":
    "SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, 'service')",
  "memory.max": "`memory.limit`",
  "disk.average.value": "`disk.space.used`",
  "disk.average.percentage": "(`disk.space.used`/`disk.space.limit`)*100",
  "disk.max": "AVG(`disk.space.limit`, 'mountpoint', 'service')"
};
