import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useMediaQuery from "Hooks/useMediaQuery";
import ChevronIcon from "Components/icons/ChevronIcon";
import Chevron from "Components/icons/Chevron";
import useToggle from "./useToggle";
import EnvironmentSettingsMenu from "./EnvironmentSettingsMenu";
import ProjectSettingsMenu from "./ProjectSettingsMenu";
import {
  BackLinkContainer,
  BREAKPOINT,
  ExpandedMobileMenu,
  MenuTriggerContainer,
  MobileMenuContainer,
  Sidebar
} from "./Menu.styles";

const Menu = () => {
  const showMobileMenu = !useMediaQuery(BREAKPOINT);

  const MenuType = showMobileMenu ? MobileMenu : SidebarMenu;

  return (
    <MenuType>
      <ProjectSettingsMenu />
      <EnvironmentSettingsMenu />
    </MenuType>
  );
};

const SidebarMenu = ({ children }) => {
  return (
    <Sidebar>
      <BackLinkContainer>
        <Link to="/">
          <Chevron />
          Back
        </Link>
      </BackLinkContainer>
      <MenuTriggerContainer>
        <h3>Settings</h3>
      </MenuTriggerContainer>
      {children}
    </Sidebar>
  );
};

SidebarMenu.propTypes = {
  children: PropTypes.node
};

const MobileMenu = ({ children }) => {
  const [expanded, { toggle }] = useToggle(false);
  return (
    <MobileMenuContainer>
      <BackLinkContainer>
        <Link to="/">
          <Chevron />
          Back
        </Link>
      </BackLinkContainer>
      <MenuTriggerContainer onClick={toggle}>
        <h3>Settings</h3>
        <ChevronIcon isOpen={expanded} animate={true} />
      </MenuTriggerContainer>
      {expanded && <ExpandedMobileMenu>{children}</ExpandedMobileMenu>}
    </MobileMenuContainer>
  );
};

MobileMenu.propTypes = {
  children: PropTypes.node
};

export default Menu;
