import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, getCSSVarString, LINK, SECTION, HEADER } from "Libs/themes";

export const DetailLayout = styled.div`
  .table-layout {
    margin: 0 -32px;
    width: calc(100% + 64px);
    font-size: 15px;
    .product {
      h3 {
        font-weight: bold;
        font-size: 15px;
        line-height: 24px;
        margin: 0;
      }
    }
    .table-row-header {
      border-bottom-color:var(--header-ebony-light-border-bottom-color,var(--header-ebony-light,var(--ebony-light)));
      .cell {
        color:var(--header-granite-color,var(--header-granite,var(--granite)));
      }
      .total {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
      }
    }
    .subtotal-discount {
      background-color:var(--section-slate-background-color,var(--section-slate,var(--slate)));
      padding: 0 8px;
      width: calc(100% - 32px);
      margin: -1px 16px 0;
      border-bottom: none;
      ${semiBoldAlias};
      + .subtotal-discount {
        margin-top: 0;
      }
      > .cell {
        border-bottom: 1px
          var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
        line-height: 56px;
        &.product {
          color:var(--section-slate-color,var(--section-slate,var(--slate)));
        }
        &:first-child {
          padding-left: 8px;
        }
        &:last-child {
          padding-right: 8px;
        }
      }
      .total {
        font-weight: 600;
      }
    }
    .total-row {
      margin: 0 16px;
      padding: 0 16px;
      width: calc(100% - 32px);
      border-top: 1px
        var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
      background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
      > div:first-child {
        color:var(--section-snow-color,var(--section-snow,var(--snow)));
      }
    }
    .total-info {
      var(--section-slate-color,var(--section-slate,var(--slate)));
      font-size: 14px;
      font-weight: normal;
      text-align: right;
      margin-right: 32px;
    }
    .total {
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
    }
    .footer-row {
      margin: 0;
      padding: 0 16px;
      width: 100%;
    }
    strike {
      margin-left: 16px;
    }
    footer {
      h4,
      strong {
        font-weight: bold;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: normal;
      }
      div {
        color:var(--section-snow-color,var(--section-snow,var(--snow)));
      }
    }
  }
  .total-row {
    border-top: 1px solid
      var(--section-ebony-background-color,var(--section-ebony,var(--ebony)));
    margin-bottom: 32px;
    font-weight: 700;
    .total {
      font-weight: 700;
    }
  }
  .order-totals {
    margin-bottom: 32px;
    .footer-row {
      border-bottom: none;
      &:first-child {
        border-bottom: 1px solid
          var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
      }
      &.order-total {
        font-weight: 700;
        background-color:var(--section-ebony-background-color,var(--section-ebony,var(--ebony)));
        border-top: 1px solid var(--link-skye-border-color,var(--link-skye,var(--skye)));
        border-bottom: 1px solid
          var(--link-skye-border-color,var(--link-skye,var(--skye)));
        .total {
          font-weight: 700;
        }
      }
    }
  }
`;

export const OrderDetailLayout = styled.div`
  h2 {
    line-height: 33px;
  }
  h3 {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .table-layout {
    margin-top: -16px;
    .table-row-header {
      margin-top: 16px;
    }
    .cell {
      color:var(--section-snow-color,var(--section-snow,var(--snow)));
      &.duration {
        flex: 0 0 180px;
      }
      &.unit-price {
        flex: 0 0 230px;
        display: flex;
        span {
          flex: 0 0 100px;
        }
        .discount {
          text-decoration: line-through;
        }
      }
      &.quantity {
        flex: 0 0 100px;
      }
      .total {
        color:var(--section-snow-color,var(--section-snow,var(--snow)));
      }
    }
    .product h3 {
      font-weight: normal;
      color:var(--header-snow-color,var(--header-snow,var(--snow)));
    }
    .subtotal-discount {
      .product {
        font-size: 13px;
        font-weight: 600;
      }
      .total {
        color:var(--section-snow-color,var(--section-snow,var(--snow)));
        font-weight: bold;
      }
      + .subtotal-discount .cell {
        border-bottom: none;
      }
    }
    .total-row {
      border-top: none;
      border-radius: 0;
    }
  }
`;

export const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    margin-bottom: 8px;
  }
  .button a {
    padding: 6px 24px;
  }
`;

export const OrderLayout = styled.div`
  .settings-item {
    .setting-line {
      padding-right: 98px;
    }
    .table-layout {
      .subtotal-discount {
        background: none;
      }
      .total-row {
        color: red;
        ${props =>
          props.theme.name === "dark" &&"background-color:var(--section-night-background-color,var(--section-night,var(--night)))"};
      }
    }
  }
  @media (max-width: 1100px) {
    .settings-item {
      .setting-line {
        padding-right: 88px;
      }
    }
  }
`;
