import { fromJS, Map } from "immutable";

import { entities } from "Libs/platform";
import logger from "Libs/logger";
import { normalize } from "Libs/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MapStoreStateType,
  StoreMapStateType,
  SetErrorMessageAction
} from "Reducers/types";

export type ApiTokenParams = {
  tokenId?: string;
  username: string;
  name?: string;
  userId: string;
};

export const getApiTokens = createAsyncThunk(
  "apiToken/list",
  async ({ userId }: ApiTokenParams) => {
    const tokens = await entities.ApiToken.query({
      userId
    });
    return normalize(tokens);
  }
);

export const addApiToken = createAsyncThunk(
  "apiToken/add",
  async ({ name, userId }: ApiTokenParams) => {
    const newApiToken = new entities.ApiToken({ name }, { userId });
    const responseFromSavingToken = await newApiToken
      .save()
      .catch((err: string) => {
        const message = JSON.parse(err).message;
        logger(
          {
            message
          },
          {
            action: "addApiToken",
            meta: {
              userId
            }
          }
        );
        throw new Error(message);
      });

    const finalApiToken = new entities.ApiToken(responseFromSavingToken.data, {
      userId
    });
    return finalApiToken;
  }
);

export const deleteApiToken = createAsyncThunk(
  "apiToken/delete",
  async ({ tokenId, username, userId }: ApiTokenParams, { getState }) => {
    const tokenToDelete = (getState() as MapStoreStateType).apiToken.getIn([
      "data",
      username,
      tokenId
    ]);

    await tokenToDelete.delete({ userId }).catch((err: string) => {
      const errMessage = JSON.parse(err);
      logger("deleteApiToken", { errMessage, userId });
      throw new Error(errMessage.error);
    });

    return tokenToDelete;
  }
);

export const setError = (
  state: StoreMapStateType,
  action: SetErrorMessageAction
) =>
  state
    .set("currentlySavingToken", false)
    .set("errors", action.error.message)
    .set("loading", false);

const apiToken = createSlice({
  name: "apiToken",
  initialState: Map() as StoreMapStateType,
  reducers: {
    expandNewApiTokenForm(state) {
      return state.set("canAddNew", true);
    },
    cancelAddNew(state) {
      return state.set("canAddNew", false).set("errors", null);
    },
    closeTokenBanner(state) {
      return state.delete("newApiToken");
    }
  },
  extraReducers: builder => {
    builder.addCase(getApiTokens.pending, state => state.set("loading", true));

    builder.addCase(getApiTokens.fulfilled, (state, action) => {
      const { username } = action.meta.arg;

      return state
        .setIn(["data", username], fromJS(action.payload))
        .set("loading", false);
    });

    builder.addCase(getApiTokens.rejected, (state, action) =>
      state.set("errors", action.payload).set("loading", false)
    );

    builder.addCase(addApiToken.pending, state =>
      state.set("currentlySavingToken", true).remove("errors")
    ),
      builder.addCase(addApiToken.rejected, (state, action) =>
        setError(state, action)
      ),
      builder.addCase(addApiToken.fulfilled, (state, action) => {
        const { username } = action.meta.arg;
        const { id } = action.payload;

        return state
          .setIn(["data", username, id], fromJS(action.payload))
          .set("newApiToken", fromJS(action.payload))
          .set("currentlySavingToken", false)
          .set("canAddNew", false);
      }),
      builder.addCase(deleteApiToken.pending, state =>
        state.set("loading", true)
      ),
      builder.addCase(deleteApiToken.fulfilled, (state, action) => {
        const { username } = action.meta.arg;
        const { id } = action.payload;

        return state.deleteIn(["data", username, id]).set("loading", false);
      }),
      builder.addCase(deleteApiToken.rejected, (state, action) =>
        setError(state, action)
      );
  }
});

export const { expandNewApiTokenForm, cancelAddNew, closeTokenBanner } =
  apiToken.actions;

export default apiToken.reducer;
