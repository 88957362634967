import styled from "styled-components";

import Pane from "../../../../common/components/Pane";

export const Layout = styled(Pane)`
  @media screen and (min-width: 768px) {
    padding-bottom: 32px;
  }
`;

export const Form = styled.form``;

export const Label = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #302f45;
  display: inline-block;

  margin-bottom: 16px;
`;

export const Actions = styled.div`
  padding-top: 24px;
  gap: 8px;
  display: flex;
`;
