/**
 * Given an expiration date, verify if the token should be refreshed.
 *
 * @param {number} expirationDate access token expiration date in seconds
 * @returns {boolean} whether the token should be refreshed or not
 */
const shouldRefreshToken = expirationDate => {
  const currentDate = Math.round(new Date().getTime() / 1000.0);

  return expirationDate !== -1 && currentDate >= expirationDate;
};

export default shouldRefreshToken;
