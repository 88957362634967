import React from "react";
import ContentLayout from "Components/ContentLayout";

import styled from "styled-components";

import Heading2 from "Components/styleguide/Heading2";
import { Link } from "ds/Button";

const Container = styled.div`
  margin-top: 17px;
`;

const HeaderSection = styled.div`
  overflow: hidden;
  display: flex;
`;

const PageTitle = styled(Heading2)`
  flex-grow: 1;
  margin-bottom: 0;
`;

const CustomPage = () => (
  <ContentLayout>
    <Container>
      <HeaderSection>
        <PageTitle>Manage your account</PageTitle>
        <Link variant="primary" href={process.env.CUSTOM_VENDOR_CONSOLE_URL}>
          {process.env.CUSTOM_VENDOR_CONSOLE_TITLE}
        </Link>
      </HeaderSection>
    </Container>
  </ContentLayout>
);

export default CustomPage;
