import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const DisplayName = styled.div`
  display: flex;
`;

export const Tag = styled.div`
  margin: 0 4px 0 0px;
  padding: 2px 8px;
  border-radius: 2px;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 20px;
  background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const EmptyState = styled.div`
  text-align: center;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 16px 0px;
  border-radius: 4px;

  font-size: 14px;
  line-height: 24px;
  ${semiBoldAlias};
`;
