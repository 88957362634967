import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// We use dynamic imports for these routes, so they are not
// included in the final bundle if the feature is not enabled
let billingRoutes = null;
let settingsRoutes = null;
let ticketsRoutes = null;
let redirectRoutes = [];

const SettingsRoute = React.lazy(() => import("./settings/Routes"));

settingsRoutes = (
  <Route path="/-/users/:username/settings">
    <SettingsRoute />
  </Route>
);

redirectRoutes.push(
  <Redirect
    key="redirect-settings"
    exact
    from="/-/users/:username"
    to="/-/users/:username/settings"
  />
);

if (!process.env.ACCOUNT_PAGES_DISABLED) {
  if (!process.env.CUSTOM_BILLING_URL && !process.env.ENABLE_ORGANIZATION) {
    const BillingRoute = React.lazy(() => import("./billing/Routes"));

    billingRoutes = (
      <Route path="/-/users/:username/billing">
        <BillingRoute />
      </Route>
    );
  }

  if (!process.env.CUSTOM_SUPPORT_URL) {
    const TicketsRoute = React.lazy(() =>
      import("../../../tickets/pages/Routes")
    );

    ticketsRoutes = (
      <Route path="/-/users/:username/tickets">
        <TicketsRoute />
      </Route>
    );
  }

  // We redirect so users do not need to type the /-/ to go to a route
  if (!process.env.CUSTOM_BILLING_URL) {
    redirectRoutes.push(
      <Redirect key="redirect-users" from="/users/*" to="/-/users/*" />
    );
  }
}

const Routes = () => (
  <Switch>
    {process.env.ENABLE_ORGANIZATION && (
      <Redirect
        key="redirect-organizations"
        from="/-/users/:username/billing*"
        to="/:username/-/billing*"
      />
    )}
    {billingRoutes}
    {settingsRoutes}
    {ticketsRoutes}
    {redirectRoutes}
  </Switch>
);

export default Routes;
