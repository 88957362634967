import styled from "styled-components";

import { Square } from "Components/Skeleton";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, HEADER } from "Libs/themes";

import Heading3 from "Components/styleguide/Heading3";
import Metric from "../../../../metrics/containers/Service/Metric";
import * as S from "../../../../metrics/containers/Service/Metric/Metric.styles";
import ServiceSkeleton from "../../../../metrics/containers/Service/Service.skeleton";
import * as ServiceStyles from "../../../../metrics/containers/Service/Service.styles";

export const Container = styled.div`
  margin-top: 24px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const Heading = styled(Heading3)`
  margin: 0 0 11px 0;
  line-height: 24px;
  font-weight: bold;
`;

export const HeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const Timeframe = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 4px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  border-radius: 2px;
`;

export const ServiceMetric = styled(Metric)`
  ${S.Metadata} {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
`;

export const ServiceMetricsSkeleton = styled(ServiceSkeleton)`
  ${ServiceStyles.Header} {
    display: none;
  }

  ${S.Row}:first-child {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;

    ${Square} {
      height: 22px;
    }
  }
`;

export const EmptyMetrics = styled.p`
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;
