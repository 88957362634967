import React, { useEffect } from "react";
import { useLocation, Switch, Route, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useIntl } from "react-intl";

import { loadMembers } from "Reducers/organization/member";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import {
  addInvitations,
  openAddModal as openAddModalAction,
  addModalStateSelector,
  closeAddModal as closeAddModalAction,
  closeConfirmationModal as closeConfirmModalAction,
  confirmModalStateSelector,
  loadInvitations,
  invitationErrorDetailSelector,
  invitationErrorSelector
} from "Reducers/organization/invitation";
import withReducers from "Hocs/withReducers";

import MemberList from "./containers/MemberList";
import InvitationList from "./containers/InvitationList";
import AddMemberModal from "./components/AddMemberModal";
import InvitationConfirmationModal from "./components/InvitationConfirmationModal";
import TableFilters from "Components/Datatable/TableFilters";
import SubNavBar from "Components/SubNavBar";

import useDecodedParams from "Hooks/useDecodedParams";

const OrganizationSettingsLayout = styled.div`
  margin-top: 24px;
  background-color: transparent;
`;

const CustomSubnavBar = styled.div`
  & > div > div {
    padding-bottom: 16px;
  }

  a:not(.button) {
    padding-left: 0;
    &.active {
      &:before {
        left: 0;
        width: calc(100% - 10px);
      }
    }
  }
`;

const Page = () => {
  const { organizationId } = useDecodedParams();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const intl = useIntl();

  const dispatch = useDispatch();

  const isAddModalOpen = useSelector(addModalStateSelector);
  const isConfirmModalOpen = useSelector(confirmModalStateSelector);

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const fieldsErrors = useSelector(state =>
    invitationErrorDetailSelector(state, { organizationId })
  );

  const invitationErrorMessage = useSelector(state =>
    invitationErrorSelector(state, { organizationId })
  );

  const tabs = [
    {
      name: "users",
      route: `/${organizationId}/-/settings/users`
    },
    {
      name: "invitations",
      route: `/${organizationId}/-/settings/users/invitations`
    }
  ];

  useEffect(() => {
    if (organization) {
      dispatch(loadMembers({ organizationId }));
      dispatch(loadInvitations({ organizationId }));
    }
  }, [organizationId, organization]);

  const openAddModal = () => {
    dispatch(openAddModalAction());
  };

  const inviteMembers = ({ permissions, invitations }, force) => {
    dispatch(
      addInvitations({ permissions, invitations, organizationId, force })
    );
  };

  const closeAddModal = () => {
    dispatch(closeAddModalAction());
  };

  const closeConfirmModal = () => {
    dispatch(closeConfirmModalAction());
  };

  return (
    <>
      <OrganizationSettingsLayout>
        <TableFilters
          title={intl.formatMessage({ id: "manage_users" })}
          onAddButtonClick={openAddModal}
          addButtonText={intl.formatMessage({ id: "invite_users" })}
          showFilter={false}
          showAddButton={!!organization?.hasLink("create-member")}
        />
        <CustomSubnavBar>
          <SubNavBar currentPathName={pathname} tabs={tabs} />
        </CustomSubnavBar>
        <Switch>
          <Route path={`${path}/invitations`}>
            <InvitationList
              inviteMembers={inviteMembers}
              organizationId={organizationId}
              setAddModelOpen={openAddModal}
            />
          </Route>
          <Route path={`${path}`}>
            <MemberList
              organizationId={organizationId}
              setAddModelOpen={openAddModal}
            />
          </Route>
        </Switch>
      </OrganizationSettingsLayout>
      <AddMemberModal
        isOpen={isAddModalOpen}
        closeModal={closeAddModal}
        addMembers={inviteMembers}
        fieldsErrors={fieldsErrors}
        errorMessage={invitationErrorMessage}
      />
      <InvitationConfirmationModal
        isOpen={isConfirmModalOpen}
        closeModal={closeConfirmModal}
      />
    </>
  );
};

export default withReducers({
  app: () => import("Reducers/app"),
  theme: () => import("Reducers/app/theme"),
  organization: () => import("Reducers/organization"),
  organizationInvitation: () => import("Reducers/organization/invitation"),
  user: () => import("Reducers/user"),
  organizationMember: () => import("Reducers/organization/member")
})(Page);
