import styled from "styled-components";

export const BaseLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export const Layout = styled(BaseLayout)`
  margin-top: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  margin-top: 16px;
`;

export const SectionHeader = styled.div`
  font: normal 700 14px/24px "Open Sans";
  margin-top: 8px;
  :first-of-type {
    margin-top: -8px;
  }
`;
