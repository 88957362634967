import { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import useDimensions from "./useDimensions";
import sortAlphabetically from "../utils/sortAlphabetically";
import selector from "Reducers/metrics/services.selector";

const useServices = (
  organizationId,
  projectId,
  environmentId,
  collection,
  href,
  range = 60 * 15,
  useInstance
) => {
  const query = useMemo(() => {
    const now = moment();
    return {
      stream: {
        collection,
        stream: "metrics"
      },
      range: {
        to: now.format(),
        from: now.subtract(range, "seconds").format()
      }
    };
  }, [range, collection]);

  const [dimensions, isLoadingDimensions, errorDimensions] = useDimensions(
    href,
    query
  );

  const hosts = useMemo(() => {
    const hostSet = new Set(
      dimensions?.map(dimension =>
        useInstance && dimension?.instance
          ? dimension?.instance.split(".")[1]
          : dimension?.hostname
      )
    );
    const hostObjects = sortAlphabetically(
      Array.from(hostSet).map(host =>
        useInstance
          ? {
              id: `Host ${host}`,
              label: host
            }
          : {
              id: host,
              label: host
            }
      ),
      ({ id }) => id
    ).map(({ label }) => label);
    return ["average", ...hostObjects];
  }, [dimensions, useInstance]);

  const [services, isLoadingServices, errorServices] = useSelector(state =>
    selector(state, { organizationId, projectId, environmentId })
  );

  const metricsServices = useMemo(() => {
    if (!services || !dimensions) {
      return undefined;
    }

    const servicesWithMetrics = Array.from(
      new Set(dimensions?.map(dimension => dimension.service))
    );

    return Object.entries(services)
      .filter(([service]) => servicesWithMetrics.includes(service))
      .map(([key, value]) => ({ id: key, ...value }));
  }, [services, dimensions]);

  const isLoading = useMemo(
    () => isLoadingDimensions || isLoadingServices,
    [isLoadingServices, isLoadingDimensions]
  );

  const error = useMemo(
    () => errorDimensions || errorServices || null,
    [errorDimensions, errorServices]
  );

  return [metricsServices, hosts, isLoading, error];
};

export default useServices;
