import styled from "styled-components";

import { css, SECTION } from "Libs/themes";

import DropdownUnstyled from "Components/Dropdown";

export const ModalBody = styled.div`
  margin-bottom: 48px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const Dropdown = styled(DropdownUnstyled)`
  margin-top: 32px;

  .select-box__single-value:first-letter {
    text-transform: initial;
  }
`;
