import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import * as S from "./AuthenticationNameBox.styles";
import Button from "UI/Button";

const AuthenticationNameBox = ({ name, avatarUrl, onClickChangeToken }) => {
  const intl = useIntl();
  return (
    <S.AuthenticationNameBox>
      <S.AvatarBox>
        <S.Avatar size={32} name={name} picture={avatarUrl} />
        <S.AvatarText>
          {intl.formatMessage(
            {
              id: "integration.configure.field.info.authenticated_as",
              defaultMessage: "Authenticated as"
            },
            {
              b: (...chunks) => <b>{chunks}</b>, // eslint-disable-line react/display-name
              name: name
            }
          )}
        </S.AvatarText>{" "}
      </S.AvatarBox>
      <Button variant="link" onClick={onClickChangeToken}>
        {intl.formatMessage({
          id: "integration.configure.field.info.change_token"
        })}
      </Button>
    </S.AuthenticationNameBox>
  );
};

AuthenticationNameBox.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  type: PropTypes.string,
  onClickChangeToken: PropTypes.func
};

export default AuthenticationNameBox;
