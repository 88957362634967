import styled, { css as styledCSS } from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import SettingLine from "Components/SettingLine";

export const Line = styled(SettingLine)`
  .setting-content {
    display: flex;
    overflow: hidden;
  }
`;

export const Name = styled.div`
  margin-right: 4px;
`;

export const InfoMain = styled.div`
  display: flex;
  flex: 1;
  min-width: 1px;
  padding-right: 16px;
  align-items: center;
  font-size: 15px;
  ${semiBoldAlias};
`;

export const Value = styled.div`
  display: flex;
  min-width: 0;
  margin-left: 4px;
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  ${props =>
    props.disabled &&
    styledCSS`
      ${Name}, ${InfoMain}, ${Value} {
        color:var(--section-granite-color,var(--section-granite,var(--granite)));
      }
    `};
`;

export const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Badge = styled.div`
  margin: 4px 0 0 16px;
  padding: 0 8px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 24px;
  ${semiBoldAlias};
`;

export const Badges = styled.div`
  display: flex;
  min-width: 175px;
  padding-right: 16px;
  justify-content: flex-end;
`;

export const Inherited = styled(Badge)`
  background-color:var(--section-slate-background-color,var(--section-slate,var(--slate)));
  color:var(--section-snow-color,var(--section-snow,var(--snow)));
`;

export const Overridden = styled(Badge)`
  background-color:var(--section-lemon-chiffon-background-color,var(--section-lemon-chiffon,var(--lemon-chiffon)));
  color:var(--section-old-gold-color,var(--section-old-gold,var(--old-gold)));
`;

export const Inactive = styled(Badge)`
  background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
  color:var(--section-periwinkle-grey-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
`;

export const Disabled = styled(Inactive)``;

export const Labels = styled.div`
  width: 210px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  font-size: 13px;
  ${semiBoldAlias};
`;
