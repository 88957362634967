import styled from "styled-components";

export const CreateOrganizationLayout = styled.div`
  background-color: transparent;
  @media (min-width: 768px) {
    margin-left: -40px;
  }
  @media (min-width: 1920px) {
    margin-left: -60px;
  }
`;

export const Layout = styled.div`
  display: flex;
  gap: 32px;
  @media (min-width: 960px) {
    gap: 60px;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    padding-bottom: 64px;
  }
`;
