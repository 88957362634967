import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export default styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 4px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  color:var(--section-night-color,var(--section-night,var(--night)));
  padding: 32px;
  margin-top: 8px;
`;
