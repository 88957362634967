import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

const TicketDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  white-space: pre-wrap;

  :last-child {
    margin-bottom: 0;
  }
`;

export default TicketDescription;
