import styled from "styled-components";
import { Wrapper as Aside } from "../../components/NavBar/NavBar.styles";

export const Content = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 32px;
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row;
    ${Aside} {
      max-width: 316px;
    }
    ${Content} {
      margin: 0 0 0 31px;
    }
  }
`;
