import React, { useEffect, useState } from "react";
import {
  clearCreatedOrganization,
  createdOrganizationSelector,
  createOrganization
} from "Reducers/organization";
import Form from "./form/Form";
import { Action, NewOrgInfo, RightPane } from "./rightPane";
import * as S from "./Page.style";

import { useDispatch, useSelector } from "react-redux";
import { getUrls } from "Libs/utils";
import { useHistory } from "react-router-dom";
import {
  clearUpdatedOrganizationProfile,
  organizationProfileUpdateErrorSelector,
  updatedOrganizationProfileSelector,
  updateOrganizationProfile
} from "Reducers/organization/profile";

const CreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [profileInput, setProfileData] = useState({});

  const createdOrganization = useSelector(createdOrganizationSelector)?.data;
  const updatedOrganizationProfile = useSelector(state =>
    updatedOrganizationProfileSelector(state, createdOrganization?.id)
  );

  const profileUpdateError = useSelector(state =>
    organizationProfileUpdateErrorSelector(state, createdOrganization?.id)
  );

  const gotoSettings = organizationName =>
    history.replace(getUrls({ organizationName }).organizationSettingsUrl);

  useEffect(() => {
    if (
      createdOrganization?.id &&
      (updatedOrganizationProfile || profileUpdateError)
    ) {
      // Organization Profile patched or failed, but we don't care because
      // organization was created succesfully lets take them to the settings page
      // where they can try this again and get the proper error message
      gotoSettings(createdOrganization.name);
    }
  }, [updatedOrganizationProfile, profileUpdateError]);

  const cleanUpCreatedOrganization = () => {
    dispatch(clearCreatedOrganization());
    dispatch(clearUpdatedOrganizationProfile());
  };

  useEffect(() => {
    // clean up created organization before and after mouting since
    // we dont do a full refresh to avoid not being able to visit
    // create organization again due to cache
    cleanUpCreatedOrganization();
    return cleanUpCreatedOrganization;
  }, []);

  useEffect(() => {
    if (createdOrganization?.id) {
      if (!Object.keys(profileInput).some(k => profileInput[k]))
        return gotoSettings(createdOrganization.name);

      // Lazy patch organization profile after organization is created
      dispatch(
        updateOrganizationProfile({
          organizationId: createdOrganization.id,
          data: profileInput
        })
      );
    }
  }, [createdOrganization]);

  const onSubmit = (organization, profile) => {
    setProfileData(profile);
    createOrganization(organization)(dispatch);
  };

  return (
    <S.CreateOrganizationLayout>
      <S.Layout>
        <Form createdOrganization={createdOrganization} onSubmit={onSubmit} />
        <RightPane>
          <div>
            <NewOrgInfo />
            <Action organizationId={{}} organization={null} />
          </div>
        </RightPane>
      </S.Layout>
    </S.CreateOrganizationLayout>
  );
};

export default CreateOrganization;
