import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import FullPageError from "Components/FullPageError";
import { Link } from "ds/Button";

const ProjectError = ({ children }) => {
  const intl = useIntl();
  const error = useSelector(({ project }) => {
    const error = project.get("projectLoadingError");

    return error?.toJS ? error.toJS() : error;
  });
  const isLoading = useSelector(({ project }) => project.get("isLoading"));

  if (!isLoading && error) {
    let errorText = error;
    if (typeof error === "string") {
      try {
        errorText = JSON.parse(error);
      } catch (_) {
        errorText = error;
      }
    }
    let errorTitle = intl.formatMessage({ id: "error_loading_project" });
    let errorMessage = intl.formatMessage({
      id: "error_loading_project_description"
    });

    if (errorText && (errorText.status || errorText.code)) {
      if (errorText.status === 403 || errorText.code === 403) {
        errorTitle = intl.formatMessage({ id: "error_403_title" });
        errorMessage = intl.formatMessage({ id: "error_project_403" });
      }
      if (errorText.status === 404 || errorText.code === 404) {
        errorTitle = intl.formatMessage({ id: "error_loading_project" });
        errorMessage = intl.formatMessage({ id: "error_project_404" });
      }

      if (errorText.status === 502 || errorText.code === 502) {
        errorTitle = intl.formatMessage({ id: "error_loading_project" });
        errorMessage = intl.formatMessage({ id: "error_project_502" });
      }
    } else if (
      errorText &&
      errorText.toString &&
      errorText.toString().indexOf("502 Bad Gateway") !== -1
    ) {
      errorTitle = intl.formatMessage({ id: "error_loading_project" });
      errorMessage = intl.formatMessage({ id: "error_project_502" });
    }

    return (
      <FullPageError title={errorTitle} message={errorMessage}>
        <div className="actions">
          <Link variant="primary" to="/">
            Return to projects
          </Link>
        </div>
      </FullPageError>
    );
  }

  return children;
};

ProjectError.propTypes = {
  children: PropTypes.node
};

export default ProjectError;
