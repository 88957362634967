import styled from "styled-components";

import { css, SECTION } from "Libs/themes";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  margin-bottom: 32px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const Description = styled.div`
  font-size: 15px;
  line-height: 24px;
  p {
    margin: 0 0 5px 0;
  }
`;

export const PlanSummary = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 55px);
  background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  padding: 24px;
  margin: 19px 0 0;
`;

export const Side = styled.div`
  div + div {
    margin-top: 16px;
  }
  width: 218px;
`;

export const PlanName = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 16px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const PlanPrice = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  label {
    margin: 0 0 2px 6px;
  }
`;

export const List = styled.div`
  padding-top: 8px;
`;

export const ListItem = styled.div`
  line-height: 24px;
  margin-bottom: 5px;

  &:before {
    content: "• ";
  }
`;
