import styled from "styled-components";

import { css, SECTION } from "Libs/themes";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  margin-top: 8px;

  > p {
    font-size: 14px;
    line-height: 24px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  }
`;
