import styled from "styled-components";
import { css, HEADER } from "Libs/themes";

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin-bottom: 96px;
`;
