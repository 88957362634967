import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { capitalize } from "Libs/utils";
import Button from "ds/Button";
import Loading from "Components/Loading";
import * as S from "./styles";

const AddApiTokenForm = ({
  addToken,
  cancel,
  currentlySavingToken,
  existingTokens = []
}) => {
  const intl = useIntl();
  const [tokenName, updateTokenName] = useState("");
  const [error, setError] = useState("");

  const save = () => {
    addToken(tokenName);
  };
  const handleOnSubmit = event => {
    event.preventDefault();
    if (existingTokens.includes(tokenName)) {
      setError(intl.formatMessage({ id: "api_token_exists" }));
      return;
    }
    save();
  };
  const handleOnChange = ({ target }) => {
    updateTokenName(target.value);
    if (error) {
      setError("");
    }
  };

  const buttons = [
    {
      id: "save-api-token-btn",
      style: "primary",
      type: "submit",
      text: capitalize(
        intl.formatMessage({
          id: "api_token_create"
        })
      )
    },
    {
      id: "cancel-api-token-btn",
      style: "secondary",
      type: "button",
      onClick: cancel,
      text: capitalize(
        intl.formatMessage({
          id: "cancel"
        })
      )
    }
  ];

  return (
    <S.FormContainer onSubmit={handleOnSubmit}>
      <S.HelpText>
        {intl.formatMessage({ id: "api_token_create_help_text" })}
      </S.HelpText>
      <S.TokenName
        id="api-token-name"
        label={intl.formatMessage({ id: "name" })}
        value={tokenName}
        onChange={handleOnChange}
        error={error} // @todo: Add custom error handling.
      />
      {currentlySavingToken ? (
        <Loading loadType="token" />
      ) : (
        buttons.map(button => (
          <Button
            key={button.id}
            id={button.id}
            variant={button.style}
            aria-label={button.text}
            type={button.type}
            onClick={button.onClick}
          >
            {button.text}
          </Button>
        ))
      )}
    </S.FormContainer>
  );
};

AddApiTokenForm.propTypes = {
  cancel: PropTypes.func,
  addToken: PropTypes.func,
  currentlySavingToken: PropTypes.bool,
  existingTokens: PropTypes.array
};

export default AddApiTokenForm;
