import styled from "styled-components";

import { css, MENU } from "Libs/themes";
import { semiBoldAlias } from "Libs/theme";

export const Text = styled.div.attrs(({ x, y }) => ({
  style: {
    transform: `translate(${x}px, ${y}px)`
  }
}))`
  position: absolute;
  transform-origin: 0 0;
  z-index: 9999;
  background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
  color:var(--menu-ebony-light-color,var(--menu-ebony-light,var(--ebony-light)));
  font-size: 10px;
  ${semiBoldAlias()};
  user-select: none;
  line-height: 1;
  pointer-events: none;
  opacity: ${props => (props.isVisible ? "1" : "0")};
`;
