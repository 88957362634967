import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import { css, SECTION } from "Libs/themes";

import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper } from "ds/Button";
import Error from "Components/Error";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

const RevokeInvitationConfirmationModal = ({
  isOpen,
  revokeInvitation,
  closeModal,
  editedInvitation,
  errors
}) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      modalClass="modal-small"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "invitation.revokeModalTitle" })}
      id={"members-invitation-revoke-modal"}
    >
      <ModalBody className="modal-body">
        {errors && <Error>{errors}</Error>}
        <FormattedMessage
          id="invitation.revokeModalBody"
          values={{
            email: editedInvitation?.email
          }}
        />
      </ModalBody>
      <ButtonWrapper justifyContent="end" hasSpacing>
        <Button onClick={revokeInvitation}>
          <FormattedMessage id="yes" />
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          <FormattedMessage id="cancel" />
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

RevokeInvitationConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  errors: PropTypes.string,
  closeModal: PropTypes.func,
  revokeInvitation: PropTypes.func,
  editedInvitation: PropTypes.object,
  users: PropTypes.object
};

export default RevokeInvitationConfirmationModal;
