import getAccessToken from "./getAccessToken";

import streamToArray from "../utils/streamToArray";

/**
 * @param {string} chorusURL
 * @param {import("../types.js").MetricsQuery} query
 */
const getMetrics = async (chorusURL, query) => {
  const endpoint = `${chorusURL}/v1/metrics/query`;
  let accessToken = await getAccessToken();

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.access_token}`
    },
    body: JSON.stringify(query)
  });

  return streamToArray(response.body);
};

export default getMetrics;
