import { createSelector } from "@reduxjs/toolkit";
import { selectMetrics } from "./metrics.selector";

export const selectActivities = createSelector(
  selectMetrics,
  metrics => metrics.activities
);

export const activeActivitySelector = createSelector(
  selectActivities,
  activities => activities.activeActivity
);

export const selectedActivitySelector = createSelector(
  selectActivities,
  activities => activities.selectedActivity
);
