import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import ProjectMoreLinks from "Components/ProjectMoreLinks";
import PlaceholderText from "../../../../../common/components/PlaceholderText";
import WarningIcon from "Icons/WarningIcon";

import { getOwnerInfoLabel } from "Libs/utils";

import * as S from "./ProjectListItem.styles";

const ProjectListItem = ({
  hasWarning,
  organizationId,
  project,
  region,
  subscription,
  organizations
}) => (
  <S.Wrapper>
    <Link
      to={`/${organizationId}/${project.id}`}
      id={`project-card-${project.id}`}
    >
      <div className="row">
        <div className="cell cell-project">
          {project.title || <PlaceholderText text="loading" />}
        </div>
        <S.Ellipsis className="cell cell-organization col-2">
          {getOwnerInfoLabel(project, organizations.toJS())}
        </S.Ellipsis>
        <div className="cell cell-region col-2">
          {region && (
            <span className="region-label">
              <>
                {region.title}
                <S.RegionSuffix inline>{region.suffix}</S.RegionSuffix>
              </>
            </span>
          )}
        </div>
        <div className="cell cell-plan col-2">{project?.data?.plan}</div>
        <S.ProjectIdCell>{project?.id}</S.ProjectIdCell>
      </div>
    </Link>
    <S.ProjectActions>
      {hasWarning && (
        <S.WarningDialog
          align="right"
          icon={<WarningIcon color="#eda900" />}
          iconColor="#eda900"
          text={<FormattedMessage id="trial_expired_tooltip" />}
        />
      )}
      <ProjectMoreLinks
        from="projects"
        project={project}
        subscription={subscription}
      />
    </S.ProjectActions>
  </S.Wrapper>
);

ProjectListItem.propTypes = {
  hasWarning: PropTypes.bool,
  organizationId: PropTypes.string,
  project: PropTypes.object,
  region: PropTypes.object,
  url: PropTypes.string,
  me: PropTypes.object,
  subscription: PropTypes.object,
  organizations: PropTypes.object
};

export default ProjectListItem;
