import styled from "styled-components";
import UnstyledAvatar from "Components/Avatar";
import Heading2 from "Components/styleguide/Heading2";

export const Avatar = styled(UnstyledAvatar)`
  .UserAvatar--img {
    border-radius: 50% !important;
    overflow: hidden;
  }
`;

export const AvatarBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AvatarText = styled.div`
  padding-left: 20px;
`;

export const AuthenticationNameBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  &.large {
    width: 100% !important;
  }

  font-size: 14px;

  svg {
    vertical-align: middle;
  }
  ${Heading2} {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 16px;
  }

  p {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
