import styled, { css as styledCSS } from "styled-components";
import { css, SECTION, getCSSVarString } from "Libs/themes";

import Description from "Components/fields/Description";

export const Layout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const FileInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

export const FileDescription = styled(Description)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  var(--section-slate-color,var(--section-slate,var(--slate)));

  span {
    font-size: 13px;
    margin-top: 6px;
  }
`;

export const PreviewWrapper = styled.div`
  height: auto;
  width: 80px;
  height: 80px;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 0;
  border-radius: 2px;

  ${props =>
    props.empty &&
    styledCSS`
      border: 1px solid var(--section-stone-border-color,var(--section-stone,var(--stone)));
      svg {
        width: 18px;
        height: auto;
      }
    `};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const MaxSizeText = styled.span`
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  margin-left: 8px;
`;
