import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import ProjectError from "../containers/ProjectError";
import withProjectRedirect from "../../../common/hocs/withProjectRedirect";
import { breakpoints } from "Libs/theme";
import AppVersion from "Components/AppVersion";
import Menu from "./Menu";

const BREAKPOINT = `(min-width: ${breakpoints[1]})`;

const Settings = ({ children }) => {
  const { organizationId, projectId } = useParams();

  return (
    <ProjectError projectId={projectId} organizationId={organizationId}>
      <Container>
        <Menu />
        <SectionContainer>
          {children}
          <AppVersion />
        </SectionContainer>
      </Container>
    </ProjectError>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINT} {
    flex-direction: row;
  }
`;

const SectionContainer = styled.section`
  box-sizing: border-box;
  min-height: 300px;
  margin: 32px 0;
  position: relative; // we want this to absolutely position buttons
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${BREAKPOINT} {
    margin: 32px 0;
    margin-left: 32px;
    width: calc(100% - 274px);
  }
`;

Settings.propTypes = {
  children: PropTypes.node
};

export default withProjectRedirect(Settings);
