import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { StripeProvider, Elements } from "react-stripe-elements";

import { capitalize } from "Libs/utils";
import PaymentForm from "../PaymentForm";
import {
  PaymentFormWrapper,
  PaymentTypeWrapper,
  HeaderLayout,
  Heading3,
  SubHeader,
  Button
} from "./NewPaymentSource.styles";
import { SectionBox } from "../../containers/Billing/Billing.styles";
import PaymentTypeCard from "./PaymentTypeCard";

const NewPaymentSource = ({
  close,
  finalize,
  hasPaymentSource,
  paymentTypes
}) => {
  const intl = useIntl();

  const [paymentSourceType, setPaymentSourceType] = useState();

  const stripePublicKey = useSelector(
    state =>
      state.app && state.app.getIn(["me", "data", "stripe", "public_key"])
  );

  const cancel = () => {
    if (paymentTypes && paymentTypes.length <= 1) {
      close();
    } else {
      setPaymentSourceType(null);
    }
  };

  const showCancelButton = hasPaymentSource && !paymentSourceType;

  const stripeKey = stripePublicKey
    ? stripePublicKey
    : "pk_test_6pRNASCoBOKtIshFeQd4XMUh";

  return (
    <>
      <HeaderLayout className="new-payment">
        <Heading3>
          {intl.formatMessage({
            id: hasPaymentSource ? "new_payment_method" : "payment_method"
          })}
        </Heading3>
        <SubHeader>{intl.formatMessage({ id: "only_one_method" })}</SubHeader>
        {showCancelButton && (
          <Button variant="outline" onClick={close}>
            {capitalize(intl.formatMessage({ id: "cancel" }))}
          </Button>
        )}
      </HeaderLayout>
      {paymentSourceType ? (
        <SectionBox>
          <PaymentFormWrapper>
            <Heading3>
              {intl.formatMessage({
                id:
                  paymentSourceType === "credit-card"
                    ? "credit_card"
                    : "bank_account"
              })}
            </Heading3>
            {paymentSourceType === "credit-card" && (
              <p>
                {intl.formatMessage({
                  id: "payment_information_description"
                })}
              </p>
            )}
            <StripeProvider apiKey={stripeKey}>
              <Elements>
                <PaymentForm
                  cancel={cancel}
                  currentPaymentSourceType={paymentSourceType}
                  edit={true}
                  finalize={finalize}
                />
              </Elements>
            </StripeProvider>
          </PaymentFormWrapper>
        </SectionBox>
      ) : (
        <PaymentTypeWrapper>
          <PaymentTypeCard
            paymentSourceType="credit-card"
            buttonAction={() => setPaymentSourceType("credit-card")}
          />
          <PaymentTypeCard
            paymentSourceType="stripe_sepa_debit"
            buttonAction={() => setPaymentSourceType("stripe_sepa_debit")}
          />
        </PaymentTypeWrapper>
      )}
    </>
  );
};

NewPaymentSource.propTypes = {
  finalize: PropTypes.func,
  close: PropTypes.func,
  hasPaymentSource: PropTypes.bool,
  paymentTypes: PropTypes.array
};

export default NewPaymentSource;
