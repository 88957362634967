import React from "react";

import { Skeleton, Square } from "Components/Skeleton";
import MetricSkeleton from "./Metric/Metric.skeleton";
import * as S from "./Service.styles";

const ServiceSkeleton = props => (
  <Skeleton synchronize {...props}>
    <S.Layout>
      <S.Header>
        <S.Title>
          <Square height="24px" width="124px" fixedWidth />
        </S.Title>
      </S.Header>
      <S.Grid length={3}>
        {[1, 2, 3].map(key => (
          <MetricSkeleton key={key} />
        ))}
      </S.Grid>
    </S.Layout>
  </Skeleton>
);

export default ServiceSkeleton;
