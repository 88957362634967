import client from "Libs/platform";

import shouldRefreshToken from "../utils/shouldRefreshToken";

/**
 * Gets the current access token, it automatically reauthenticates if the token
 * has expried.
 *
 * @returns {Promise<import("../types.js").Accesstoken>} the access token
 */
const getAccessToken = async () => {
  let accessToken = await client.getAccessToken();

  if (shouldRefreshToken(accessToken.expires)) {
    accessToken = await client.reAuthenticate();
  }

  return accessToken;
};

export default getAccessToken;
