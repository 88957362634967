import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import ModalWrapper from "Components/Modal";

import AccessForm from "../../../../../../../common/components/EnvironmentTypeAccessForm/AccessForm";

const CreationModal = ({
  isOpen,
  closeModal,
  onSave,
  environments,
  environmentTypes,
  errors,
  status
}) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      modalClass="modal-large modal-no-padding"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "project.accesslist.add_user" })}
      id={"access-creation-modal"}
    >
      <AccessForm
        create
        onSave={payload => {
          onSave(payload.email, payload.accesses, payload.superUser);
        }}
        enabled={true}
        environmentTypes={environmentTypes}
        onCancel={closeModal}
        environments={environments}
        errors={errors}
        isLoading={status === "pending"}
      />
    </ModalWrapper>
  );
};

CreationModal.propTypes = {
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  status: PropTypes.string,
  closeModal: PropTypes.func,
  onSave: PropTypes.func,
  environments: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  environmentTypes: PropTypes.object
};

export default CreationModal;
