import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { selectIsActivitiesOverlayVisible } from "Reducers/metrics/settings.selector";
import {
  setActiveActivity,
  setSelectedActivity
} from "Reducers/metrics/activities.slice";
import { activeActivitySelector } from "Reducers/metrics/activities.selector";

import * as S from "./Activities.styles";

/**
 * Once you hover one activity flag it as active on the other charts
 * Fade the rest of the stuff while hovering an activtiy.
 * Move it to the URL
 *
 */
const Activities = ({
  activities,
  xAxis,
  yAxis,
  displayRange = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const isActivitiesOverlayVisible = useSelector(
    selectIsActivitiesOverlayVisible
  );

  const activeActivity = useSelector(activeActivitySelector);

  const setActive = useCallback(activity => {
    dispatch(setActiveActivity(activity));
  }, []);

  const clearActive = useCallback(() => {
    dispatch(setActiveActivity(undefined));
  }, []);

  const setSelected = useCallback(activity => {
    dispatch(setSelectedActivity(activity));
  }, []);

  if (!isActivitiesOverlayVisible) {
    return null;
  }

  const [topEdge, bottomEdge] = yAxis.range();

  return (
    <g data-testid="activities" {...props}>
      {activities.map(activity => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <S.ActivityLine
          key={activity.id}
          x={xAxis(new Date(activity.completed_at))}
          y={bottomEdge}
          height={topEdge - bottomEdge}
          isActive={activeActivity?.id === activity.id}
          someActive={!!activeActivity}
          width={
            displayRange
              ? xAxis(new Date(activity.completed_at)) -
                xAxis(new Date(activity.created_at))
              : 2
          }
          onClick={() => setSelected(activity)}
          onMouseOver={() => setActive(activity)}
          onMouseOut={clearActive}
        />
      ))}
    </g>
  );
};

Activities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      completed_at: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired
    })
  ).isRequired,
  yAxis: PropTypes.func.isRequired,
  xAxis: PropTypes.func.isRequired,
  displayRange: PropTypes.bool
};

export default React.memo(Activities);
