import React from "react";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";

import Heading2 from "Components/styleguide/Heading2";
import { AddButton } from "ds/Button";
import { EmptyIntegrations as Illustration } from "Components/illustrations";

import * as S from "./EmptyIntegrations.styles";

const EmptyIntegrations = () => {
  const { projectId, organizationId } = useDecodedParams();
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.ImageWrapper>
        <Illustration />
      </S.ImageWrapper>
      <Heading2>
        {intl.formatMessage({
          id: "integrations.no_integration",
          defaultMessage: "No integrations"
        })}
      </Heading2>
      <p>
        {intl.formatMessage({
          id: "integrations.add_integration_to_project",
          defaultMessage:
            "Connect git services, health checks, or webhoooks to your project"
        })}
      </p>
      <AddButton to={`/${organizationId}/${projectId}/-/integrations/setup`}>
        {intl.formatMessage({
          id: "integrations.add",
          defaultMessage: "Add integration"
        })}
      </AddButton>
    </S.Wrapper>
  );
};

export default EmptyIntegrations;
