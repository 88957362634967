import React from "react";
import { useIntl } from "react-intl";
import proptypes from "prop-types";

import Action from "./Action";

import { Layout } from "./styles";

const Actions = ({ organizationId, organization }) => {
  const intl = useIntl();
  return (
    <Layout>
      {!organization?.hasLink("create-member") && (
        <Action
          title={intl.formatMessage({ id: "add_users" })}
          text={intl.formatMessage({
            id: "organizations.settings.add_users_text"
          })}
          linkText={intl.formatMessage({ id: "add_users" })}
          link={`/${organizationId}/-/settings/users`}
        />
      )}
      {!organization?.hasLink("billing") && (
        <Action
          title={intl.formatMessage({ id: "view_billing_details" })}
          text={intl.formatMessage({
            id: "organizations.settings.view_billing_details_text"
          })}
          linkText={intl.formatMessage({ id: "view_billing_details" })}
          link={`/${organizationId}/-/billing`}
        />
      )}
      {!organization?.hasLink("orders") && (
        <Action
          title={intl.formatMessage({ id: "invoices" })}
          text={intl.formatMessage({
            id: "organizations.settings.invoices_text"
          })}
          linkText={intl.formatMessage({ id: "view_invoices" })}
          link={`/${organizationId}/-/billing/history`}
        />
      )}
    </Layout>
  );
};

Actions.propTypes = {
  organizationId: proptypes.string,
  organization: proptypes.object
};

export default Actions;
