import React from "react";
import PropTypes from "prop-types";

import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentSelector } from "Reducers/environment";

import ServiceOverviewDetails from "./ServiceOverviewDetails";
import AppOverview from "./AppOverview";
import RouterOverview from "./RouterOverview";

import * as S from "./styles";

const ServiceOverview = ({ data, workers }) => {
  const environment = useSelectorWithUrlParams(environmentSelector);

  if (!data) return null;

  let Overview;

  switch (data.get("class")) {
    case "app":
      Overview = AppOverview;
      break;
    case "service":
      Overview = ServiceOverviewDetails;
      break;
    default:
      Overview = RouterOverview;
  }

  const sshUrls = environment?.getSshUrls() || {};
  const serviceKey = data.get("name", "router");
  let ssh = sshUrls[serviceKey];

  if (!ssh) {
    ssh = Object.entries(sshUrls)
      .filter(([key]) => key.startsWith(`${serviceKey}:`))
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .map(([, value], index) => ({
        id: `Host ${index}`,
        value
      }));
  }

  return (
    <S.Wrapper>
      <Overview metadata={data.toJS()} workers={workers} ssh={ssh} />
    </S.Wrapper>
  );
};

ServiceOverview.propTypes = {
  data: PropTypes.object,
  workers: PropTypes.object
};

export default ServiceOverview;
