import styled from "styled-components";
import { getCSSVarString, SECTION, css, HEADER } from "Libs/themes";

export const Layout = styled.div`
  margin-top: 32px;
  .back-link {
    var(--section-slate-color,var(--section-slate,var(--slate)));
  }
  h2 span {
    color:var(--header-night-color,var(--header-night,var(--night)));
  }
  .settings-item {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    box-shadow: none;
    &.open {
      box-shadow: ${props =>
        props.theme.name === "dark"
          ? "0px 6px 44px #01001A"
          : "0px 10px 20px rgba(75, 97, 128, 0.08)"};
    }
  }
  .setting-line {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    min-height: 64px;
    .action button {
      min-width: 70px;
      width: 70px;
      justify-content: flex-end;
    }
    .total strong {
      margin-left: 32px;
    }
  }
`;

export const ErrorBlock = styled.div`
  padding-top: 25px;
`;
