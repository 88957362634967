import styled from "styled-components";
import { css, getCSSVarString, MENU, SECTION } from "Libs/themes";
import { semiBoldAlias } from "Libs/theme";

export const PanelFooter = styled.section`
  ${semiBoldAlias};
  background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  display: flex;
  justify-content: space-between;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -16px;
  padding: 16px;

  .small {
    font-size: 13px;
  }
`;

export const PanelBody = styled.section`
  ul {
    list-style: none;
    padding: 0 0 16px;
    margin: 0;
  }

  li {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
  }

  .grey {
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }

  .multiplier {
    font-size: 18px;
  }

  .align {
    margin-top: -4px;
  }

  .spacing-left {
    margin-left: 20px;
  }

  .cost-container {
    display: flex;
    justify-content: space-between;
  }

  .discount {
    text-decoration: line-through;
  }
`;

export const PanelHeader = styled.header`
  margin: 0;
  border-bottom: 1px solid
    var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));

  h1 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    font-weight: 400;
    margin-top: 6px;
    line-height: 20px;
  }
`;

export const PanelContainer = styled.article`
  font-size: 14px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: var(--menu-box-shadow-box-shadow,var(--menu-box-shadow,var(--box-shadow)))
    var(--menu-ebony-shadow-box-shadow-color,var(--menu-ebony-shadow,var(--ebony-shadow)));
  padding: 16px;
  width: 300px;
  position: absolute;
  right: 0;
  top: 24px;
  border-radius: 2px;
`;
