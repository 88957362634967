import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import List from "../../../../common/components/List";
import ListItem from "../../../../common/components/ListItem";
import Link from "../../../../common/components/Link";

import { Layout } from "./styles";

const links = [
  {
    href: "links.documentation.getting_started",
    text: "tickets.list.quicklinks.gettingstarted"
  },
  {
    href: "links.documentation.going_live",
    text: "tickets.list.quicklinks.live"
  },
  {
    href: "links.community.tutorial",
    text: "tickets.list.quicklinks.tutorials"
  },
  {
    href: "links.api.docs",
    text: "tickets.list.quicklinks.reference"
  },
  {
    href: "links.community.questions_answers",
    text: "tickets.list.quicklinks.qa"
  }
];

const QuickLinks = props => {
  const intl = useIntl();
  return (
    <Layout
      header={<FormattedMessage id="tickets.list.quicklinks.header" />}
      paragraph={<FormattedMessage id="tickets.list.quicklinks.paragraph" />}
      {...props}
    >
      <List>
        {links.map((link, index) => (
          <ListItem key={index}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={intl.formatMessage({ id: link.href })}
            >
              <FormattedMessage id={link.text} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Layout>
  );
};
export default QuickLinks;
