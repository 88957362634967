import React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";

import Setup from "./containers/ProjectSetup";
import * as Steps from "./components/steps";

const Routes = () => {
  const { path } = useRouteMatch();
  const { push } = useHistory();
  const { organizationId: organizationNameId } = useDecodedParams();

  const goToStep = (step, organization) => {
    let path = `/create-project/${step}`;
    if (organization?.name) {
      path = `/${organization.name}${path}`;
    } else {
      path = `/${organizationNameId}${path}`;
    }
    push(path);
  };

  return (
    <Setup goToStep={goToStep}>
      <Switch>
        <Route path={`${path}/template`}>
          <Steps.Template
            goToStep={goToStep}
            organizationNameId={organizationNameId}
          />
        </Route>
        <Route path={`${path}/info`}>
          <Steps.Info
            goToStep={goToStep}
            organizationNameId={organizationNameId}
          />
        </Route>
        <Route path={`${path}/building`}>
          <Steps.Building
            goToStep={goToStep}
            organizationId={organizationNameId}
          />
        </Route>

        <Route path={[path, `${path}/type`]} strict={false}>
          <Steps.Type goToStep={goToStep} />
        </Route>
      </Switch>
    </Setup>
  );
};

export default Routes;
