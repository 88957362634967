import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { AddButton } from "ds/Button";

import * as S from "./Empty.styles";

const Empty = ({ onClick }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <p>
        {intl.formatMessage({
          id: "settings.variables.empty"
        })}
      </p>
      <AddButton id="add-new-variable" onClick={onClick}>
        {intl.formatMessage({
          id: "settings.variables.add.button"
        })}
      </AddButton>
    </S.Wrapper>
  );
};

Empty.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default Empty;
