import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isJson } from "Libs/utils";

// Load project invitations
export const getInvitations = createAsyncThunk(
  "app/invitations",
  async ({ projectId }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const invitations = await client.getInvitations(projectId);
    return invitations;
  }
);

// Invite a user to a project
export const addInvitation = createAsyncThunk(
  "app/invitation/create",
  async (
    { email, environments, force, projectId, role, environmentTypes },
    { rejectWithValue }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    try {
      const result = environmentTypes
        ? await client.createInvitationWithEnvironmentTypes(
            email,
            projectId,
            role ? role : undefined,
            environmentTypes,
            force
          )
        : await client.createInvitation(
            email,
            projectId,
            role,
            environments,
            force
          );
      const newInvitation = new platformLib.entities.Invitation({
        ...result.data,
        projectId
      });
      return newInvitation;
    } catch (err) {
      let errors = JSON.parse(err);
      let resendInvitation = false;

      if (
        errors.error ===
        "A pending invitation already exists for this email address."
      ) {
        resendInvitation = true;
        errors = false;
      }

      if (errors.detail?.errors?.length) {
        errors = errors.detail?.errors[0];
      }
      return rejectWithValue({ errors, resendInvitation });
    }
  }
);

// Delete project invitation
export const deleteInvitation = createAsyncThunk(
  "app/invitation/delete",
  async ({ invitation }) => {
    if (!invitation) return;

    await invitation.delete();
    return invitation;
  }
);

const setError = (state, action) => {
  let message = action.error.message;
  if (isJson(action.error.message)) {
    const errors = JSON.parse(action.error.message);
    if (errors?.detail?.errors?.length) message = errors.detail.errors[0];
  }

  return state.set("errors", message).set("loading", false);
};

const invitations = createSlice({
  name: "invitations",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {
    setCreationModalOpen(state, action) {
      return state.set("isCreationModalOpen", action.payload).delete("edited");
    },
    editInvitation: (state, action) => {
      return state
        .delete("errors")
        .set("status", "idle")
        .set("edited", action.payload.id);
    }
  },
  extraReducers: {
    // GET LIST
    [getInvitations.pending]: state => state.set("loading", true),
    [getInvitations.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId],
          fromJS(
            action.payload.reduce((invitations, invitation) => {
              if (!invitations[projectId]) {
                invitations[projectId] = {};
              }
              invitations[projectId][invitation.id] = fromJS(invitation);
              return invitations;
            }, {})
          )
        )
        .set("loading", false)
        .set("status", "idle")
        .delete("edited");
    },
    [getInvitations.rejected]: (state, action) => setError(state, action),

    // ADD
    [addInvitation.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [addInvitation.fulfilled]: (state, action) => {
      const { organizationId, projectId, invitation } = action.meta.arg;
      if (invitation) {
        return state
          .setIn(
            ["data", organizationId, projectId, invitation.id],
            fromJS(action.payload)
          )
          .set("status", "resent");
      } else {
        return state
          .setIn(
            ["data", organizationId, projectId, action.payload.id],
            fromJS(action.payload)
          )
          .set("isCreationModalOpen", false)
          .set("status", "added")
          .set("edited", action.payload.id);
      }
    },
    [addInvitation.rejected]: (state, action) => {
      const { errors, resendInvitation } = action.payload;
      // manage case when invitation already exists
      if (!errors && resendInvitation)
        return state.set("status", "resent").set("loading", false);

      return state
        .set("errors", action.payload.errors)
        .set("loading", false)
        .set("status", "rejected");
    },

    // Delete
    [deleteInvitation.pending]: state =>
      state.set("status", "delete.pending").delete("errors"),
    [deleteInvitation.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state
        .deleteIn(["data", organizationId, projectId, action.payload.id])
        .set("status", "deleted");
    },
    [deleteInvitation.rejected]: (state, action) =>
      state
        .set("errors", JSON.parse(action.error.message).message)
        .set("status", "rejected")
  }
});

export const { editInvitation, setCreationModalOpen } = invitations.actions;
export default invitations.reducer;

export const invitationErrorsSelector = state =>
  state.invitation?.get("errors", false);

export const invitationErrorSelector = (state, props) =>
  state.invitation?.getIn(
    ["errors", props.organizationId, props.projectId, props.editedLine],
    {}
  );

export const invitationStatusSelector = state =>
  state.invitation?.get("status");

export const invitationsSelector = (state, props) =>
  state.invitation?.getIn(
    ["data", props.organizationId, props.projectId],
    new Map()
  );

export const invitationEditedSelector = state =>
  state.invitation?.get("edited");

export const creationModalSelector = state =>
  state.invitation?.get("isCreationModalOpen", false);

export const statusSelector = state => state.invitation?.get("status", "idle");
