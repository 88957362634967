import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { IbanElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import { request } from "Libs/platform";
import config from "Constants/api_config";

import { getCSSVarString, INPUT, ICON } from "Libs/themes";
import logger from "Libs/logger";

import Button from "ds/Button";
import InputField from "Components/fields/InputField";
import Label from "Components/fields/Label";
import RequiredTag from "Components/fields/RequiredTag";
import InfoDialog from "Components/InfoDialog";
import Error from "Components/Error";

import { Layout, Disclaimer, NewCardWrapper } from "./SepaSection.styles";
import { withTheme } from "styled-components";

const SepaSection = props => {
  const [name, setName] = useState("");
  const [error, setError] = useState();
  const { intl, number, onEdit, onCancel, hideButtons, edit } = props;

  const handleSubmit = async ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    const { email } = props;

    if (props.edit) {
      try {
        const sourceData = {
          type: "sepa_debit",
          // Hardcoded currency -- SEPA transactions must be in EUR.
          currency: "eur",
          owner: {
            name,
            email
          },
          mandate: {
            // Automatically send a mandate notification email to your customer
            // once the source is charged.
            notification_method: "email"
          }
        };

        const response = await props.stripe.createSource(sourceData);

        if (response.error) {
          throw response.error;
        }

        const data = await request(
          `${config.api_url}/platform/payment_source`,
          "POST",
          {
            token: response.source.id,
            type: "stripe_sepa_debit"
          }
        );

        setError(null);

        props.onSuccess && props.onSuccess(data);
      } catch (err) {
        logger(err);
        setError(err);
      }
    } else {
      props.onSuccess && props.onSuccess();
    }
  };

  const onNameChange = e => {
    setName(e.target.value);
  };

  return (
    <Layout>
      {error && (
        <Error>
          {error.message || error.description || JSON.stringify(error, null, 4)}
        </Error>
      )}
      <Disclaimer>
        <InfoDialog
          title={intl.formatMessage({ id: "bank_account_payments" })}
          text={intl.formatMessage({ id: "sepa_disclamer" })}
        />
        <span>{intl.formatMessage({ id: "sepa_disclamer_short" })}</span>
      </Disclaimer>
      <form aria-labelledby="edit-card" id="stripe-payment-form">
        {!edit && number ? (
          <React.Fragment>
            <LiveMessage
              message="click update credit card to edit"
              aria-live="polite"
            />
            <InputField
              label="credit card number"
              className="card-number"
              isDisabled={true}
              value={number && number.replace(/X/g, "•").replace(/-/g, " ")}
            />
            <div>
              <Button onClick={onEdit}>
                {intl.formatMessage({ id: "update_bank_account" })}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <NewCardWrapper className="new-card">
            <LiveMessage
              message={intl.formatMessage({ id: "bank_account_payments" })}
              aria-live="polite"
            />
            <InputField
              id="name_line"
              name="name_line"
              label={intl.formatMessage({ id: "full_name" })}
              placeholder={intl.formatMessage({ id: "full_name" })}
              className="name_line"
              value={name}
              onChange={onNameChange}
            />
            <div>
              <Label>
                {intl.formatMessage({ id: "bank_account_number" })}{" "}
                <RequiredTag />
              </Label>
              <IbanElement
                supportedCountries={["SEPA"]}
                className="stripe-card"
                style={{
                  base: {
                    fontSize: "14px",
                    iconColor:"var(--icon-night-color,var(--icon-night,var(--night)))",
                    color:"var(--input-night-color,var(--input-night,var(--night)))",
                    "::placeholder": {
                      fontSize: "14px",
                      color:"var(--input-night-color,var(--input-night,var(--night)))"
                    }
                  }
                }}
                iconStyle="solid"
              />
            </div>
            {!hideButtons && (
              <React.Fragment>
                <Button id="stripe_cardsection_submit" onClick={handleSubmit}>
                  {intl.formatMessage({ id: "save_changes" })}
                </Button>
                {number && (
                  <Button
                    type="button"
                    id="stripe_cardsection_cancel"
                    variant="secondary"
                    onClick={onCancel}
                  >
                    {"cancel"}
                  </Button>
                )}
              </React.Fragment>
            )}
          </NewCardWrapper>
        )}
      </form>
    </Layout>
  );
};

SepaSection.propTypes = {
  email: PropTypes.string,
  number: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  intl: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  theme: PropTypes.object
};

export default withTheme(injectStripe(injectIntl(SepaSection)));
