import { useState } from "react";

function useToggle(initialState = false) {
  const [expanded, setExpanded] = useState(initialState);
  const expand = () => setExpanded(true);
  const collapse = () => setExpanded(false);
  const toggle = () => (expanded ? collapse() : expand());

  return [expanded, { expand, collapse, toggle }];
}

export default useToggle;
