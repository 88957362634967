import { createRef, useEffect } from "react";
import { createPortal } from "react-dom";

const TooltipPortal = ({ children, forwardedRef }) => {
  if (!forwardedRef) {
    forwardedRef = createRef();
  }
  if (forwardedRef && !forwardedRef.current) {
    const container = document.createElement("div");
    container.setAttribute("data-role", "chart-tooltip-portal");
    container.style.height = 0;
    container.style.width = 0;
    container.style.position = "absolute";
    container.style.top = 0;
    container.style.left = 0;
    forwardedRef.current = container;
  }

  useEffect(() => {
    const app = document.querySelector("#portal-root");
    app.appendChild(forwardedRef.current);
    return () => app.removeChild(forwardedRef.current);
  }, [forwardedRef]);

  return createPortal(children, forwardedRef?.current);
};

export default TooltipPortal;
