import styled from "styled-components";
import { semiBoldAlias, makePalette } from "Libs/theme";

export const colorDefinitions = palette => ({
  average_gradient__stop_1: {
    default: palette.slate,
    dark: palette.snow,
    contrast: "#01001a"
  },
  host_gradient__stop_1: {
    default: palette.granite,
    contrast: "#8c94a1"
  },
  threshold__warning: {
    default: palette.gold,
    contrast: "#b88d00"
  },
  threshold__max: {
    default: palette.red,
    contrast: "#e50000"
  },
  threshold__none: {
    default: palette.periwinkleGrey
  },
  text: {
    default: palette.granite,
    dark: palette.periwinkleGrey,
    contrast: palette.ebonyLight
  }
});

const theme = makePalette(colorDefinitions);

export const Layout = styled.div`
  margin-top: 12px;
  height: ${props => props.chartHeight}px;
`;

export const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
`;

export const XAxis = styled.g`
  .domain,
  .tick line {
    display: none;
  }

  .tick text {
    stroke-width: 0.5;
    font-size: 10px;
    ${semiBoldAlias()};
    user-select: none;
    line-height: 22px;
    fill: ${theme.text};
  }
`;

export const Brush = styled.g`
  .overlay {
    cursor: pointer;
  }

  .selection {
    fill: rgba(71, 134, 255, 0.16);
    stroke: transparent;
    fill-opacity: 1;
  }
`;
