import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, HEADER, SECTION } from "Libs/themes";

export const Note = styled.dl`
  dt {
    margin: 0 0 8px 0;
    padding: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  dd {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const ListTitle = styled.h3`
  margin: 24px 0 8px 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color:var(--header-slate-color,var(--header-slate,var(--slate)));
  span {
    margin: 0 0 0 3px;
    font-weight: normal;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  }
`;

export const EmptyList = styled.div`
  margin-bottom: 16px;
  padding: 24px 32px;
  border-radius: 4px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  font-size: 14px;
  line-height: 18px;
  ${semiBoldAlias};
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  text-align: center;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
