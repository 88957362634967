import styled from "styled-components";

export const Layout = styled.div`
  display: grid;
  margin-top: 32px;
  @media (min-width: 550px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
  gap: 32px;
`;
