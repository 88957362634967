import styled from "styled-components";
import Heading2 from "Components/styleguide/Heading2";
import { SECTION, css } from "Libs/themes";

export const Wrapper = styled.header`
  display: flex;
  margin-bottom: 24px;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 16px;
  }

  ${Heading2} {
    margin: 0 16px 0 0;
  }
`;

export const Kind = styled.span`
  padding: 3px 8px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  border-radius: 2px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;
