import React from "react";
import PropTypes from "prop-types";

import CopyableArea from "Components/CopyableArea";
import SanitizedHTML from "Components/SanitizedHTML";
import Heading2 from "Components/styleguide/Heading2";
import Label from "Components/fields/Label";

import { Application, Routes, Services, SshKey } from "../Steps";

import * as S from "./Step.styles";

const Step = ({ project, step, user }) => {
  const filterImageType = name => {
    const allowedImageTypes = project?.subscription?.image_types?.only;
    const excludedImageTypes = project?.subscription?.image_types?.exclude;

    if (!allowedImageTypes && !excludedImageTypes) {
      return true;
    }

    let allowed = true;
    let excluded = false;

    if (allowedImageTypes) {
      allowed = allowedImageTypes.indexOf(name) !== -1;
    }

    if (excludedImageTypes) {
      excluded = excludedImageTypes.indexOf(name) !== -1;
    }

    return allowed && !excluded;
  };

  return (
    <S.Wrapper>
      <Heading2>
        {step?.title} {!step?.required && <span>(Optional)</span>}
      </Heading2>

      {step?.subtitle && <S.Subtitle>{step.subtitle}</S.Subtitle>}

      {step?.bodyText && (
        <SanitizedHTML
          allowedTags={[
            "strong",
            "b",
            "br",
            "p",
            "em",
            "i",
            "a",
            "ul",
            "ol",
            "li",
            "label",
            "code"
          ]}
          html={step?.bodyText}
        />
      )}

      {/* STEPS: SSH KEY */}
      {step?.id === "sshKey" && <SshKey user={user} />}

      {/* STEPS: SERVICES */}
      {step?.id === "services" && (
        <Services filterImageType={filterImageType} />
      )}

      {/* STEPS: APPLICATION */}
      {step?.id === "application" && (
        <Application filterImageType={filterImageType} />
      )}

      {/* STEPS: ROUTES */}
      {step?.id === "routes" && <Routes />}

      {step?.copyCode &&
        step?.copyCode.map(elt => (
          <S.CodeExample key={elt.label}>
            <Label>{elt.label}</Label>
            {elt.codeType === "noCopy" ? (
              <S.NoCopyCode>
                {elt.code?.join("\n").replace("<project ID>", project.id)}
              </S.NoCopyCode>
            ) : (
              <CopyableArea
                singleLine={elt.code?.length === 1}
                twoLine={elt.code?.length === 2}
                threeLine={elt.code?.length === 3}
                className={elt.code?.length > 3 ? "multi-line" : ""}
                iconAbove={elt.code?.length > 3}
                linkStyle={elt.code?.length > 3}
                content={elt.code
                  .join("\n")
                  .replace("<project ID>", project.id)}
              >
                {elt.code.join("\n").replace("<project ID>", project.id)}
              </CopyableArea>
            )}
          </S.CodeExample>
        ))}
    </S.Wrapper>
  );
};

Step.propTypes = {
  project: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default Step;
