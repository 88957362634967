import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

import Sticker from "ds/Stickers";

export const Wrapper = styled(Sticker)`
  display: flex;
  padding: 12px 12px 12px 32px;
  line-height: 22px;
  font-size: 13px;
  line-height: 32px;
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));

  &::last-child {
    border: 2px solid red !important;
  }
`;

export const Plan = styled.div`
  width: 160px;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

export const Infos = styled.div`
  flex: 1;
  display: flex;
`;

export const Info = styled.div`
  display: flex;
  margin-left: 100px;
  &:first-of-type {
    margin: 0;
  }
`;

export const Label = styled.div`
  margin-right: 25px;
  font-weight: 600;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;
