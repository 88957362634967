import { Map, OrderedSet } from "immutable";
import client from "Libs/platform";

const initialState = Map({
  isLoading: false,
  hasMore: false,
  page: 1
  // tickets: OrderedSet
  // error: Obj
});

const defaultLoadFailureError = {
  message: "An error has occurred while loading tickets"
};

// Actions
const LOAD_START = "tickets/load_start";
const FAILURE = "tickets/load_failure";
const LOAD_SUCCESS = "tickets/load_success";

const LOAD_MORE_SUCCESS = "tickets/load_more_success";

// Action Creators
export const loadStart = () => ({ type: LOAD_START });
export const loadSuccess = tickets => ({
  type: LOAD_SUCCESS,
  payload: tickets
});
export const loadFailure = error => ({ type: FAILURE, payload: error });

export const loadMoreSuccess = (tickets = []) => ({
  type: LOAD_MORE_SUCCESS,
  payload: tickets
});

// Thunks
export const load =
  (filters = {}, onSuccess = loadSuccess) =>
  async dispatch => {
    dispatch(loadStart());
    try {
      const response = await client.getTickets(filters);
      let nextPage;

      if (response?._links?.next) {
        nextPage = new URL(response._links.next.href).searchParams.get("page");
      }

      dispatch(
        onSuccess({
          tickets: response?.tickets,
          hasMore: !!nextPage,
          page: Number(nextPage) - 1 || 1
        })
      );
    } catch (error) {
      dispatch(loadFailure({ message: error.message }));
    }
  };

export const loadMore =
  (filters = {}) =>
  async (dispatch, getState) => {
    const { tickets } = getState();
    if (tickets.get("hasMore")) {
      load(
        {
          ...filters,
          page: Number(tickets.get("page", 0)) + 1
        },
        loadMoreSuccess
      )(dispatch);
    }
  };

const onLoadSuccess = (state, action) =>
  state
    .set("isLoading", false)
    .set("hasMore", action.payload?.hasMore)
    .set("page", action.payload?.page);

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_START:
      return state.set("isLoading", true);
    case FAILURE:
      return state
        .set("isLoading", false)
        .set("error", Map(action.payload || defaultLoadFailureError));
    case LOAD_SUCCESS:
      return onLoadSuccess(state, action).set(
        "tickets",
        OrderedSet(action.payload?.tickets)
      );
    case LOAD_MORE_SUCCESS:
      return onLoadSuccess(state, action).set(
        "tickets",
        state
          .get("tickets", OrderedSet([]))
          .concat(OrderedSet(action.payload?.tickets))
      );
    default:
      return state;
  }
};

export default reducer;
