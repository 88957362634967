import { createSelector } from "@reduxjs/toolkit";

const sortAlphabetically = (list = [], accessor = a => a, lang = "en") =>
  list.sort((a, b) => accessor(a).localeCompare(accessor(b), lang));

const loadingSelector = state => state.deployment.get("loading");

const errorSelector = state => state.deployment.get("errors");

export const deploymentSelector = state => state.deployment;

export const environmentDeploymentSelector = createSelector(
  deploymentSelector,
  (_, organizationId, projectId, environmentId) => [
    organizationId,
    projectId,
    environmentId
  ],
  (deployment, [organizationId, projectId, environmentId]) =>
    deployment.getIn([
      "data",
      organizationId,
      projectId,
      environmentId,
      "current"
    ])
);

const transformServices = (services, kind) =>
  sortAlphabetically(
    Object.entries(services).map(([key, value]) => ({
      ...value,
      icon: value.type.split(":")[0],
      name: key,
      kind
    })),
    service => service.name
  );

export const environmentServicesSelector = createSelector(
  environmentDeploymentSelector,
  immutableDeployment => {
    if (!immutableDeployment) {
      return undefined;
    }

    const deployment = immutableDeployment.toJS();

    const webapps = transformServices(deployment.webapps, "webapp");
    const workers = transformServices(deployment.workers, "worker");
    const services = transformServices(deployment.services, "service");

    return Object.fromEntries(
      [...webapps, ...services, ...workers].map(service => [
        service.name,
        service
      ])
    );
  }
);

const servicesSelector = (
  state,
  { organizationId, projectId, environmentId }
) => {
  const deployment = state.deployment
    .getIn(["data", organizationId, projectId, environmentId, "current"])
    ?.toJS();

  if (!deployment) {
    return {};
  }

  const webapps = deployment.webapps;
  const workers = deployment.workers;
  const services = Object.fromEntries(
    sortAlphabetically(Object.entries(deployment.services), ([key]) => key)
  );

  const allServices = Object.fromEntries(
    Object.entries({
      ...webapps,
      ...services,
      ...workers
    }).map(([key, value]) => {
      const icon = value?.type.split(":")[0];
      value.icon = icon;
      value.name = key;
      return [key, value];
    })
  );

  return allServices;
};

const selector = createSelector(
  [loadingSelector, errorSelector, servicesSelector],
  (isLoading, error, services) => [services, isLoading, error]
);

export default selector;
