import styled from "styled-components";
import BaseHeading2 from "Components/styleguide/Heading2";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Section = styled.section`
  width: 100%;
`;

export const Heading2 = styled(BaseHeading2)`
  margin-bottom: 0;
`;
