import styled from "styled-components";

import Heading3 from "Components/styleguide/Heading3";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, HEADER } from "Libs/themes";

export const Wrapper = styled.section`
  margin: 26px 0 0 0;
`;

export const Heading = styled(Heading3)`
  margin: 0 0 11px 0;
  line-height: 24px;
  font-weight: bold;
  strong {
    margin: 0 0 0 5px;
    color:var(--header-granite-color,var(--header-granite,var(--granite)));
    font-weight: normal;
  }
`;

export const Layout = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 13px 32px 32px;
  margin-top: 8px;
  hr {
    margin: 9px 0 16px 0;
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 44%;
  grid-row-gap: 14px;
  grid-column-gap: 16px;
`;

export const GridHeader = styled.div`
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  ${semiBoldAlias};
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  text-transform: capitalize;
  display: flex;
  align-items: center;
  &.sort {
    cursor: pointer;
    .chevron {
      height: 16px;
      margin-top: 2px;
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  color:var(--section-periwinkle-grey-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  font-size: 15px;

  .copyable-area {
    flex: 1;
  }
`;

export const Title = styled.span`
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  ${semiBoldAlias};
`;
