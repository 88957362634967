import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { getCSSVarString, ICON } from "Libs/themes";
import useDecodedParams from "Hooks/useDecodedParams";

import { nextWizardStep } from "Reducers/project/wizard";

import ArrowIcon from "Components/icons/ArrowIcon";
import Heading2 from "Components/styleguide/Heading2";

import ListSteps from "../ListSteps";

import * as S from "./Intro.styles";

const Intro = ({ project }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { organizationId, projectId } = useDecodedParams();

  const handleStart = e => {
    e?.preventDefault();
    dispatch(nextWizardStep({ organizationId, projectId }));
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer />
        <Heading2>
          {intl.formatMessage(
            { id: "wizard.intro.title" },
            {
              br: <br />,
              name: project.title
            }
          )}
        </Heading2>
        <p>{intl.formatMessage({ id: "wizard.intro.sentence" })}</p>

        <S.Footer>
          <S.FooterButton onClick={handleStart} variant="link">
            {intl.formatMessage({ id: "wizard.intro.start" })}{" "}
            <ArrowIcon color={"var(--icon-skye-color,var(--icon-skye,var(--skye)))"} />
          </S.FooterButton>
        </S.Footer>
      </S.Content>

      <ListSteps />
    </S.Wrapper>
  );
};

Intro.propTypes = {
  project: PropTypes.object
};

export default Intro;
