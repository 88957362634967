import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  color: #4b6180;
  ${semiBoldAlias};
`;

export const CommonSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const DetailLayout = styled.div`
  display: grid;
  grid-template-columns: ${({ hasEditLinks }) =>
    hasEditLinks === true ? "120px 150px 150px" : "120px 150px 182px"};
  grid-column-gap: 100px;
`;

export const Project = styled(CommonSpan)`
  width: 262px;
  font-size: 16px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  strong {
    font-weight: 700;
  }
`;

export const Plan = styled(CommonSpan)`
  display: inline-block;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const End = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;
