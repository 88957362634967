import styled, { css as styledCSS } from "styled-components";

import { css, INPUT, ICON } from "Libs/themes";

import Dropdown from "Components/Dropdown";
import InputField from "Components/fields/InputField";

import Pane from "../../../../common/components/Pane";
import CommonLabel from "Components/fields/Label";

export const Label = styled(CommonLabel)`
  display: flex;
  align-items: center;
`;

export const __input = styledCSS`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color:var(--input-night-color,var(--input-night,var(--night)));
`;

export const __wide_field = styledCSS`
  max-width: 808px;
  width: 100%;
`;

export const Layout = styled(Pane).attrs({
  as: "form",
  hasShadow: true
})`
  padding-bottom: 24px;

  .text-area-field {
    border: 1px solid transparent;
    margin-bottom: 16px;

    textarea {
      ${__input};
    }

    .rcs-custom-scroll {
      ${__wide_field};
      height: 172px;
    }

    &.error-field {
      margin-bottom: 16px;
    }
  }
`;

export const Actions = styled.div`
  padding-top: 24px;

  button:not(last-child) {
    margin-right: 8px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  margin-bottom: 16px;

  .input-wrapper,
  .select-box {
    width: 295px;
  }

  ${props =>
    props.value &&
    styledCSS`
      .select-box__control {
        background-color: transparent;
        border-color:var(--input-periwinkle-grey-border-color,var(--input-periwinkle-grey,var(--periwinkle-grey)))
      }
    `};

  .select-box__placeholder {
    color:var(--input-granite-color,var(--input-granite,var(--granite)));
  }

  .select-box__single-value {
    color:var(--input-night-color,var(--input-night,var(--night)));
  }

  .select-box,
  .select-box__control,
  .select-box__value-container,
  .select-box__value-container .select-box__single-value {
    height: 40px;
    min-height: 40px;
    line-height: 40px;
  }
`;

export const PriorityLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .required-notice {
    margin: 2px 0 0 4px;
  }

  label {
    margin-bottom: 0;
  }

  svg > path {
    fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
  }
`;

export const PriorityDropdown = styled(StyledDropdown)`
  .select-box {
    width: 295px;
  }

  .select-box__menu {
    min-width: 358px;
  }
`;

export const Input = styled(InputField)`
  border: 1px solid transparent;
  width: 295px;

  input {
    ${__input};
  }
`;

export const SubjectField = styled(Input)`
  ${__wide_field};
`;
