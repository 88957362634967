import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ContentLayout from "Components/ContentLayout";
import ProjectDomains from "./containers/ProjectDomains";

const Routes = () => {
  const { path, url } = useRouteMatch();
  const newUrl = url.endsWith("/") ? url.slice(0, -1) : url;

  return (
    <ContentLayout>
      <Switch>
        <Route path={`${path}/-/new`} exact>
          <ProjectDomains isNew={true} url={newUrl} />
        </Route>
        <Route path={`${path}/:domainId?`}>
          <ProjectDomains url={newUrl} />
        </Route>
      </Switch>
    </ContentLayout>
  );
};

export default Routes;
