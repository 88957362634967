import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { List, Map } from "immutable";

import useInput from "Hooks/useInput";

import { getOptions, saveConfig } from "Reducers/project/setup";
import { getOrganizationOptions } from "Reducers/organization/setup";
import {
  canCreateProjectOrganizationsSelector,
  organizationByDescriptionIdSelector
} from "Reducers/organization";
import {
  getOrganizationProfile,
  organizationProfileSelector
} from "Reducers/organization/profile";
import { getIsLoadingState } from "Reducers/utils";

import PageMeta from "Components/PageMeta";
import Heading2 from "Components/styleguide/Heading2";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import Button from "ds/Button";
import InputField from "Components/fields/InputField";
import RegionPicker from "Containers/RegionPicker";

import Sumup from "../../Sumup";

import * as S from "./Info.styles";
import Dropdown from "Components/Dropdown";

const InfoStep = ({ goToStep, organizationNameId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const [modalIsOpen, setOpenModal] = useState(false);
  const [template, setTemplate] = useState();
  const [organizationError, setOrganizationError] = useState(false);

  const user = useSelector(({ app }) => app?.get("me", Map())?.toJS());
  const config = useSelector(({ setup }) => setup?.get("config", Map()));

  const organizations = useSelector(state =>
    canCreateProjectOrganizationsSelector(state)?.toArray()
  );

  const { inputs, handleInputChange, setInputs } = useInput({
    title: config.get("title", ""),
    defaultBranch:
      process.env.CUSTOM_DEFAULT_BRANCH || config.get("defaultBranch", "main"),
    region: config.get("region"),
    organization: {}
  });

  const organizationNameSelected = organizations?.find(
    o => o.id === inputs?.organization?.value
  )?.name;
  const templates = useSelector(({ setup, organizationSetup }) => {
    if (process.env.ENABLE_ORGANIZATION && organizationNameSelected) {
      return organizationSetup
        ?.getIn([organizationNameSelected, "catalog", "data"], List())
        ?.toJS();
    } else if (!organizations?.length) {
      return setup?.getIn(["catalog", "data"], List())?.toJS();
    }
  });

  const organizationProfile = useSelector(state =>
    organizationProfileSelector(state, {
      organizationId: organizationNameSelected
    })
  );
  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationNameSelected
    })
  );
  const isLoadingProfile = useSelector(state =>
    getIsLoadingState(state.organizationProfile, organizationNameSelected)
  );

  useEffect(() => {
    if (
      process.env.ENABLE_ORGANIZATION &&
      organizationNameSelected &&
      organization &&
      !organizationProfile
    ) {
      !isLoadingProfile &&
        dispatch(
          getOrganizationProfile({ organizationId: organizationNameSelected })
        );
    }
  }, [organizationNameSelected, organization]);

  const activeTrial =
    (!process.env.ENABLE_ORGANIZATION &&
      user.trial &&
      user.data?.current_trial?.active) ||
    (process.env.ENABLE_ORGANIZATION &&
      organizationProfile?.data?.current_trial?.active);

  useEffect(() => {
    if (process.env.ENABLE_ORGANIZATION && organizationNameSelected) {
      dispatch(
        getOrganizationOptions({ organizationId: organizationNameSelected })
      );
    } else if (!organizations?.length) {
      dispatch(getOptions());
    }
  }, [organizationNameSelected]);

  useEffect(() => {
    const focusElement = document.getElementById("projectTitle");
    focusElement && focusElement.focus();
    // If the user have only one org, we hide the dropdown and we choose this one by default
    if (process.env.ENABLE_ORGANIZATION && organizations?.length === 1) {
      const orgData = organizations[0];
      setInputs({
        ...inputs,
        organization: { label: orgData.label, value: orgData.id }
      });
    } else if (process.env.ENABLE_ORGANIZATION) {
      const orgData = organizations?.find(o => o.name === organizationNameId);
      if (orgData) {
        setInputs({
          ...inputs,
          organization: { label: orgData.label, value: orgData.id }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!params.has("template") && !config.has("template")) return;
    const tplURL = params.get("template") || config.get("template");
    const regex = /github.*\.com\/(.[^/]*)\/.*\/(.*)\/\.platform.template.yaml/;
    const match = tplURL.match(regex);
    const tpl = templates?.find(
      elt =>
        elt.template === tplURL ||
        (elt.template.match(regex) !== null &&
          match !== null &&
          elt.template.match(regex)[2] === match[2])
    );
    if (!match) return;

    setTemplate({
      image: tpl?.info.image,
      name: tpl?.info.name || `${match[1]}/${match[2]}`
    });
  }, [config?.has("template"), params.has("template"), templates?.length]);

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleBuild = () => {
    setOpenModal(false);
    goToStep("building");
  };

  const onRegionChange = region => {
    setInputs(inputs => ({ ...inputs, region }));
  };

  const canSubmit = () => {
    const { title, region, organization } = inputs;

    if (
      process.env.ENABLE_ORGANIZATION &&
      organizations?.length > 0 &&
      !organization?.value
    ) {
      return false;
    }
    return title !== "" && region;
  };

  const handleGoBack = e => {
    e?.preventDefault();
    goToStep(config.get("template") ? "template" : "type");
  };

  const handleSubmit = e => {
    e?.preventDefault();

    if (
      process.env.ENABLE_ORGANIZATION &&
      organizations?.length > 0 &&
      !inputs.organization?.value
    ) {
      setOrganizationError(intl.formatMessage({ id: "required_field" }));
      return;
    }

    dispatch(saveConfig({ ...inputs }));

    // TODO: use the getTrial function with organizations when we modify the setup
    if (!user.trial) {
      setOpenModal(true);
    } else {
      goToStep("building");
    }
  };

  const handleOrganizationChange = organization => {
    setOrganizationError(false);
    setInputs({
      ...inputs,
      organization: { label: organization.label, value: organization.value }
    });
  };

  return (
    <>
      {modalIsOpen && (
        <ModalConfirmDelete
          modalSize="medium"
          id="modal-plan-pricing"
          className="modal-plan-pricing"
          isOpen={true}
          deleteText={intl.formatMessage({ id: "continue" })}
          deleteFunction={handleBuild}
          cancelFunction={handleCancel}
          closeModal={handleCancel}
          title={intl.formatMessage({ id: "plan_and_pricing" })}
          body={<Sumup activeTrial={activeTrial} />}
        />
      )}

      <S.Wrapper>
        <PageMeta title={intl.formatMessage({ id: `setup.info.title` })} />

        <S.Header>
          {template?.image && (
            <img alt={`${template.name} logo`} src={template.image} />
          )}
          <Heading2>{intl.formatMessage({ id: `setup.info.title` })}</Heading2>
          {template && <p>{template.name}</p>}
        </S.Header>

        <S.Form>
          <InputField
            id="title"
            className="field"
            label={intl.formatMessage({ id: "project_name" })}
            onChange={handleInputChange}
            value={inputs.title}
            placeholder={intl.formatMessage({ id: "project_name" })}
          />
          <InputField
            id="defaultBranch"
            className="field"
            required={false}
            label={intl.formatMessage({ id: "production_environment" })}
            onChange={handleInputChange}
            value={inputs.defaultBranch}
          />
          {process.env.ENABLE_ORGANIZATION && organizations.length > 1 && (
            <Dropdown
              label="Organization"
              options={organizations.map(o => ({
                label: o.label,
                value: o.id
              }))}
              onChange={handleOrganizationChange}
              clearable={false}
              fieldType={true}
              required
              defaultText="Select organization"
              value={inputs.organization}
              error={organizationError}
            />
          )}
          <RegionPicker
            organizationId={organizationNameSelected}
            onChange={onRegionChange}
            region={inputs.region}
          />
        </S.Form>

        <S.ActionButtons>
          {!params.has("template") && (
            <Button variant="secondary" onClick={handleGoBack}>
              {intl.formatMessage({ id: "back" })}
            </Button>
          )}

          <Button type="submit" onClick={handleSubmit} disabled={!canSubmit()}>
            {intl.formatMessage({ id: "create_project" })}
          </Button>
        </S.ActionButtons>
      </S.Wrapper>
    </>
  );
};

InfoStep.propTypes = {
  goToStep: PropTypes.func,
  organizationNameId: PropTypes.string
};

export default InfoStep;
