import { useEffect, createRef } from "react";
import { createPortal } from "react-dom";

const TimeTooltipPortal = ({ children, forwardedRef }) => {
  if (!forwardedRef) {
    forwardedRef = createRef();
  }

  if (forwardedRef && !forwardedRef.current) {
    const container = document.createElement("div");
    container.setAttribute("data-role", "chart-time-tooltip-portal");
    container.style.height = 0;
    container.style.width = 0;
    forwardedRef.current = container;
  }

  useEffect(() => {
    const app = document.querySelector("#portal-root");
    app.appendChild(forwardedRef.current);
    return () => app.removeChild(forwardedRef.current);
  }, [forwardedRef]);

  return createPortal(children, forwardedRef?.current);
};

export default TimeTooltipPortal;
