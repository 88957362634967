import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export const Layout = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;
