import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./TemplateItem.styles";

const TemplateItem = ({ template, onClick, openModal, modalOpen }) => {
  const intl = useIntl();

  const handleSelect = event => {
    event?.preventDefault();
    document.activeElement.blur();
    onClick();
  };

  const showDetails = event => {
    event?.stopPropagation();
    openModal();
    document.activeElement.blur();
  };

  const whiteIconsOnDarkMode = [
    "platformsh/django2",
    "platformsh/django3",
    "platformsh/django4",
    "platformsh/eleventy-strapi",
    "platformsh/express",
    "platformsh/flask",
    "platformsh/php",
    "platformsh/wordpress-composer",
    "platformsh/wordpress-vanilla",
    "platformsh/symfony4",
    "platformsh/symfony5",
    "restyaboard/restyaboard",
    "platformsh/drupal8-opigno",
    "platformsh/wagtail",
    "platformsh/nextjs",
    "platformsh/nextcloud",
    "platformsh/micronaut",
    "platformsh/koa",
    "platformsh/lisp",
    "platformsh/directus",
    "vardot/varbase9",
    "symfonycorp/5.4-php8.0-base",
    "symfonycorp/5.4-php8.1-base",
    "symfonycorp/5.4-php8.0-webapp",
    "symfonycorp/5.4-php8.1-webapp",
    "symfonycorp/6.0-php8.0-base",
    "symfonycorp/6.0-php8.1-base",
    "symfonycorp/6.0-php8.0-demo",
    "symfonycorp/6.0-php8.1-demo",
    "symfonycorp/6.0-php8.0-webapp",
    "symfonycorp/6.0-php8.1-webapp"
  ];

  const { id, image, name } = template.info;
  const tplId = id.replace(/\//gm, "-") || "";

  return (
    <S.Wrapper onClick={handleSelect}>
      <S.Content>
        <AccessibleTooltip
          onClick={handleSelect}
          onKeyUp={e => e.keyCode === 13 && onClick()}
          tooltipProps={{
            id: `template-${tplId}-tooltip`,
            disable: !(name.length > 20),
            children: name
          }}
        >
          <S.Name>{name}</S.Name>
        </AccessibleTooltip>
        <S.DetailsLink
          onClick={showDetails}
          onKeyDown={e => {
            if (e.keyCode === 13 && !modalOpen) {
              openModal();
            }
          }}
          role="link"
          tabIndex="0"
        >
          {intl.formatMessage({ id: "setup.type.template.details" })}
        </S.DetailsLink>
      </S.Content>

      <S.Logo isWhite={whiteIconsOnDarkMode.includes(id)}>
        <img alt={`${name} logo`} src={image} />
      </S.Logo>
    </S.Wrapper>
  );
};

TemplateItem.propTypes = {
  stack: PropTypes.object,
  template: PropTypes.object,
  onClick: PropTypes.func,
  openModal: PropTypes.func,
  modalOpen: PropTypes.bool
};

export default TemplateItem;
