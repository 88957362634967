import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import InfiniteScroll from "react-infinite-scroller";

import {
  subscriptionsSelector,
  loadNextSubscriptionsPage,
  loadingListSelector,
  subscriptionLinksManagerSelector,
  loadingNextListSelector
} from "Reducers/organization/subscription";

import Loading from "Components/Loading";
import ProjectCard from "../ProjectCard";
import ProjectsListLayout from "Components/ProjectsListLayout";
import { getOrganizationDescriptionIdFromProject } from "Libs/utils";
import ErrorBoundary from "Components/ErrorBoundary";
import Label from "Components/fields/Label";
import Heading2 from "Components/styleguide/Heading2";
import EmptyProjects from "Components/illustrations/EmptyProjects";
import CreateNewProject from "Components/illustrations/CreateNewProject";
import PageMeta from "Components/PageMeta";
import NewProjectButton from "Components/NewProjectButton";

import * as S from "./styles";

const Projects = ({
  organizationId,
  gridLayout,
  sortType,
  sortOrder,
  setHasProjects
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const subscriptionLinkManager = useSelector(state =>
    subscriptionLinksManagerSelector(state, { organizationId })
  );

  const subscriptions = useSelector(state =>
    subscriptionsSelector(state, { organizationId })
  );

  const subscriptionsLoading = useSelector(loadingListSelector);
  const subscriptionsNextLoading = useSelector(loadingNextListSelector);

  const search = useSelector(state => state.search?.get("query", ""));
  const me = useSelector(state => state.app?.get("me", new Map()));
  const organizations = useSelector(state =>
    state.organization?.get("data", new Map())
  );

  useEffect(() => {
    if (subscriptions.size > 0) {
      setHasProjects(true);
    }
  }, [subscriptions?.size]);

  const projectFilter = subscription => {
    const title = subscription.project_title;
    const id = subscription.project_id;

    if (!search) {
      return true;
    }

    if (id?.toUpperCase().includes(search.toUpperCase())) {
      return true;
    }

    if (!title) {
      return false;
    }

    return title.toUpperCase().includes(search.toUpperCase());
  };

  if (!subscriptions) {
    return false;
  }

  const filteredProjectsSize = subscriptions.valueSeq();

  const user = me?.toJS();

  const loadMore = () => {
    dispatch(loadNextSubscriptionsPage({ organizationId }));
  };

  return (
    <ErrorBoundary>
      <PageMeta title={"Projects"} />
      {subscriptions.size === 0 ? (
        <S.EmptyProjectsListLayout className="no-projects">
          <S.ImageWrapper className="no-projects-image">
            <CreateNewProject />
          </S.ImageWrapper>
          <Heading2>
            {intl.formatMessage({ id: "create_first_project" })}
          </Heading2>
          <p>
            {intl.formatMessage({ id: "create_first_project_description" })}
          </p>
          <NewProjectButton organizationId={organizationId} user={user} />
        </S.EmptyProjectsListLayout>
      ) : (
        <InfiniteScroll
          threshold={400}
          pageStart={0}
          loadMore={loadMore}
          hasMore={
            !subscriptionsNextLoading &&
            !subscriptionsLoading &&
            subscriptionLinkManager?.hasMore()
          }
          loader={<Loading iconOnly={true} />}
          initialLoad={false}
        >
          <ProjectsListLayout
            className={`${gridLayout ? "grid" : "list"}${
              subscriptions.toArray().filter(projectFilter).length === 0
                ? " no-results"
                : ""
            }`}
          >
            <LiveMessage
              role="status"
              aria-live="assertive"
              message={
                filteredProjectsSize.size
                  ? `${filteredProjectsSize.size} projects found`
                  : "Sorry, no projects found"
              }
            />
            {!gridLayout && (
              <S.LabelWrapper>
                <Label
                  className={`label label-project${
                    sortType === "name" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  as="button"
                >
                  Project name{" "}
                </Label>
                <Label
                  className={`label label-owner col-2${
                    sortType === "owner" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  as="button"
                >
                  Owner{" "}
                </Label>
                <Label
                  className={`label label-region col-2${
                    sortType === "region" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  as="button"
                >
                  Region{" "}
                </Label>
                <Label
                  className={`label label-plan col-2${
                    sortType === "plan" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  as="button"
                >
                  Plan{" "}
                </Label>
                <Label
                  className={`label label-id col-2${
                    sortType === "id" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  as="button"
                >
                  ID{" "}
                </Label>
              </S.LabelWrapper>
            )}
            {subscriptions
              .toArray()
              .filter(projectFilter)
              .map(subscription => (
                <ProjectCard
                  key={subscription.id}
                  subscription={subscription}
                  gridLayout={gridLayout}
                  projectId={subscription.project_id}
                  organizationId={getOrganizationDescriptionIdFromProject(
                    subscription,
                    organizations?.toJS()
                  )}
                  user={user}
                  organizations={organizations}
                  url={`/${getOrganizationDescriptionIdFromProject(
                    subscription,
                    organizations?.toJS()
                  )}/${subscription.project_id}`}
                />
              ))}
            {subscriptions.toArray().filter(projectFilter).length === 0 && (
              <S.EmptyProjectsListLayout>
                <S.ImageWrapper>
                  <EmptyProjects />
                </S.ImageWrapper>
                <Heading2>Sorry, no projects found</Heading2>
                <p>Try adjusting your search</p>
              </S.EmptyProjectsListLayout>
            )}
          </ProjectsListLayout>
        </InfiniteScroll>
      )}
    </ErrorBoundary>
  );
};

Projects.propTypes = {
  organizationId: PropTypes.string,
  gridLayout: PropTypes.bool,
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  sortOrder: PropTypes.string,
  setSortOrder: PropTypes.func,
  setHasProjects: PropTypes.func
};

export default Projects;
