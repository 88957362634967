import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Label from "Components/fields/Label";

import * as S from "./Sumup.styles";

const Sumup = ({ activeTrial }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Description>
        <p>
          {intl.formatMessage(
            { id: "plan_and_pricing_sumup_description_1" },
            {
              planType: "Development",
              b: (...chunks) => <b>{chunks}</b> // eslint-disable-line
            }
          )}
          {activeTrial
            ? intl.formatMessage({ id: "plan_and_pricing_trial_text" })
            : intl.formatMessage({ id: "plan_and_pricing_text" })}
          {". "}
          {intl.formatMessage({ id: "plan_and_pricing_sumup_description_2" })}
        </p>
      </S.Description>
      <S.PlanSummary>
        <S.Side>
          <Label style={{ marginBottom: "4px" }}>
            {intl.formatMessage({ id: "plan" })}
          </Label>
          <S.PlanName>development</S.PlanName>
          <Label style={{ marginBottom: "6px" }}>
            {intl.formatMessage({
              id: "monthly_price",
              defaultMessage: "Monthly price"
            })}
          </Label>
          <S.PlanPrice>
            {intl.formatMessage(
              { id: activeTrial ? "plan_price_discounted" : "plan_price" },
              {
                s: (...chunks) => <s>{chunks}</s>, // eslint-disable-line
                label: (...chunks) => <Label>{chunks}</Label> // eslint-disable-line
              }
            )}
          </S.PlanPrice>
        </S.Side>
        <div>
          <Label style={{ marginBottom: "0" }}>
            {intl.formatMessage({ id: "plan_includes.title" })}
          </Label>
          <S.List>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.user" })}
            </S.ListItem>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.environments" })}
            </S.ListItem>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.storage" })}
            </S.ListItem>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.prod" })}
            </S.ListItem>
          </S.List>
        </div>
      </S.PlanSummary>
    </S.Wrapper>
  );
};

Sumup.propTypes = {
  activeTrial: PropTypes.bool
};

export default Sumup;
