import React from "react";
import { useIntl } from "react-intl";

import Heading2 from "Components/styleguide/Heading2";
import IntegrationButton from "../../components/IntegrationButton";

import getThirdParties from "../../../third_parties";

import useLocalForage from "Hooks/useLocalForage";

import * as S from "./GettingStarted.styles";

const GettingStarted = () => {
  // eslint-disable-next-line no-unused-vars
  const [isClosed, setIsClosed, _error, isLoading] = useLocalForage(
    "integration_getting_started"
  );
  const intl = useIntl();

  if (isLoading || isClosed === true) return null;
  return (
    <S.Wrapper>
      <S.CloseButton onClick={() => setIsClosed(true)} />
      <Heading2>
        {intl.formatMessage({
          id: "integrations.getting_started.title",
          defaultMessage: "Getting started"
        })}
      </Heading2>
      <p>
        {intl.formatMessage({
          id: "integrations.getting_started.intro"
        })}
      </p>

      <S.List>
        {getThirdParties(intl).map(elt => (
          <IntegrationButton key={elt.type} integration={elt} />
        ))}
      </S.List>
    </S.Wrapper>
  );
};

export default GettingStarted;
