import { Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Data, StateType, Window } from "./types";

export const callStatusPage = (path: string): Promise<Data> => {
  const status = new (window as Window).StatusPage.page({
    page: process.env.STATUS_PAGE
  });
  return new Promise(resolve => {
    status.get(path, {
      success: (data: Data) => resolve(data)
    });
  });
};

export const getSystemStatus = createAsyncThunk("system/status", async () => {
  if (!process.env.STATUS_PAGE || !(window as Window).StatusPage) return false;
  const result = await callStatusPage("summary");
  return result;
});

export const setError = (state: StateType, error?: string) =>
  error ? state.set("errors", error).set("loading", false) : state;

const systemStatus = createSlice({
  name: "systemStatus",
  initialState: Map(),
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(getSystemStatus.pending, state => state.set("loading", true))
      .addCase(getSystemStatus.rejected, (state, action) =>
        setError(state as StateType, action.error.message)
      )
      .addCase(getSystemStatus.fulfilled, (state, action) => {
        const payload = action.payload as Data;

        if (!payload.status) return state.set("loading", false);
        return state
          .set("status", { ...payload.status, ...payload.page })
          .set("incidents", payload.incidents)
          .set("maintenances", payload.scheduled_maintenances)
          .set("loading", false);
      })
});

export default systemStatus.reducer;
