import { fromJS, Map } from "immutable";

// import { PROJECT_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";

const LOAD_ENVIRONMENT_TYPES_START =
  "app/projectEnvironmentTypes/load_environment_types_start";
const LOAD_ENVIRONMENT_TYPES_SUCCESS =
  "app/projectEnvironmentTypes/load_environment_types_success";
const LOAD_ENVIRONMENT_TYPES_FAILURE =
  "app/projectEnvironmentTypes/load_environment_types_failure";

const ADD_ENVIRONMENT_TYPE_START =
  "app/projectEnvironmentType/add_environment_type_start";
const ADD_ENVIRONMENT_TYPE_SUCCESS =
  "app/projectEnvironmentType/add_environment_type_success";
const ADD_ENVIRONMENT_TYPE_FAILURE =
  "app/projectEnvironmentType/add_environment_type_failure";
const ADD_ENVIRONMENT_TYPE_CANCEL =
  "app/projectEnvironmentType/add_environment_type_cancel";

const UPDATE_ENVIRONMENT_TYPE_START =
  "app/projectEnvironmentType/update_environment_type_start";
const UPDATE_ENVIRONMENT_TYPE_SUCCESS =
  "app/projectEnvironmentType/update_environment_type_success";
const UPDATE_ENVIRONMENT_TYPE_FAILURE =
  "app/projectEnvironmentType/update_environment_type_failure";

const EDIT_LINE = "app/projectEnvironmentType/edit_line";

export const editLine = (index, isNew) => ({
  type: EDIT_LINE,
  payload: index,
  meta: { isNew }
});

export const cancelAddEnvironmentType = () => ({
  type: ADD_ENVIRONMENT_TYPE_CANCEL
});

export const loadEnvironmentTypes = (organizationDescriptionId, projectId) => {
  return async dispatch => {
    dispatch({ type: LOAD_ENVIRONMENT_TYPES_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const environmentTypes =
        await client.getProjectEnvironmentTypesWithAccesses(projectId);

      dispatch({
        type: LOAD_ENVIRONMENT_TYPES_SUCCESS,
        payload: {
          environmentTypes
        },
        meta: {
          organizationDescriptionId,
          projectId
        }
      });
    } catch (err) {
      logger(err);
      dispatch({
        type: LOAD_ENVIRONMENT_TYPES_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const updateEnvironmentTypeAccess = (
  organizationDescriptionId,
  projectId,
  environmentTypeId,
  access
) => {
  return async dispatch => {
    dispatch({ type: UPDATE_ENVIRONMENT_TYPE_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      if (access.role === "no_access" && access.id) {
        await client.deleteEnvironmentTypeAccess(
          projectId,
          environmentTypeId,
          access
        );
      } else {
        const newAccess = await client.updateEnvironmentTypeAccess(
          projectId,
          environmentTypeId,
          access
        );
        dispatch({
          type: UPDATE_ENVIRONMENT_TYPE_SUCCESS,
          payload: {
            access: newAccess
          },
          meta: {
            organizationDescriptionId,
            environmentTypeId,
            projectId
          }
        });
      }
    } catch (err) {
      logger(err);
      dispatch({
        type: UPDATE_ENVIRONMENT_TYPE_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const addEnvironmentTypeAccess = (
  organizationDescriptionId,
  projectId,
  environmentTypeId,
  access,
  editedLine
) => {
  return async dispatch => {
    dispatch({ type: ADD_ENVIRONMENT_TYPE_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const newAccess = await client.createEnvironmentTypeAccess(
        projectId,
        environmentTypeId,
        access
      );

      dispatch({
        type: ADD_ENVIRONMENT_TYPE_SUCCESS,
        payload: {
          access: newAccess
        },
        meta: {
          organizationDescriptionId,
          environmentTypeId,
          projectId
        }
      });
    } catch (err) {
      logger(err, {
        action: "addEnvironmentType"
      });
      dispatch({
        type: ADD_ENVIRONMENT_TYPE_FAILURE,
        payload: err.detail || err,
        meta: { editedLine, isNew: true }
      });
    }
  };
};

export default function projectEnvironmentTypeReducer(
  state = new Map(),
  action
) {
  switch (action.type) {
    case LOAD_ENVIRONMENT_TYPES_START:
      return state.set("loading", true);
    case EDIT_LINE:
      return state
        .set("editedLine", action.payload)
        .set("isNew", action.meta.isNew);
    case ADD_ENVIRONMENT_TYPE_CANCEL:
      return state.set("editedLine", false).set("isNew", false);
    case LOAD_ENVIRONMENT_TYPES_SUCCESS:
      return state
        .set("loading", false)
        .set(
          "data",
          fromJS(
            action.payload.environmentTypes.reduce(
              (organizationsProjectsEnvironements, environmentType) => {
                if (
                  !organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ]
                ) {
                  organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ] = {};
                }
                if (
                  !organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ][action.meta.projectId]
                ) {
                  organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ][action.meta.projectId] = {};
                }

                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectId][environmentType.id] = environmentType;

                return organizationsProjectsEnvironements;
              },
              {}
            )
          )
        )
        .set(
          "byUsers",
          fromJS(
            action.payload.environmentTypes.reduce(
              (organizationsProjectsUsers, environmentType) => {
                if (
                  !organizationsProjectsUsers[
                    action.meta.organizationDescriptionId
                  ]
                ) {
                  organizationsProjectsUsers[
                    action.meta.organizationDescriptionId
                  ] = {};
                }

                if (
                  !organizationsProjectsUsers[
                    action.meta.organizationDescriptionId
                  ][action.meta.projectId]
                ) {
                  organizationsProjectsUsers[
                    action.meta.organizationDescriptionId
                  ][action.meta.projectId] = {};
                }

                if (environmentType.accesses) {
                  environmentType.accesses.forEach(access => {
                    if (
                      !organizationsProjectsUsers[
                        action.meta.organizationDescriptionId
                      ][action.meta.projectId][access.user]
                    ) {
                      organizationsProjectsUsers[
                        action.meta.organizationDescriptionId
                      ][action.meta.projectId][access.user] = {};
                    }

                    organizationsProjectsUsers[
                      action.meta.organizationDescriptionId
                    ][action.meta.projectId][access.user][environmentType.id] =
                      access;
                  });
                }

                return organizationsProjectsUsers;
              },
              {}
            )
          )
        );
    case ADD_ENVIRONMENT_TYPE_SUCCESS:
      return state
        .setIn(
          [
            "byUsers",
            action.meta.organizationDescriptionId,
            action.meta.projectId,
            action.payload.access?.user,
            action.meta.environmentTypeId
          ],
          action.payload?.access
        )
        .set("editedLine", false)
        .set("isNew", false);
    case UPDATE_ENVIRONMENT_TYPE_SUCCESS:
      return state
        .setIn(
          [
            "byUsers",
            action.meta.organizationDescriptionId,
            action.meta.projectId,
            action.payload.access?.user,
            action.meta.environmentTypeId
          ],
          action.payload?.access
        )
        .set("editedLine", false)
        .set("isNew", false);
    case LOAD_ENVIRONMENT_TYPES_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    case ADD_ENVIRONMENT_TYPE_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    default:
      return state;
  }
}
