import styled from "styled-components";
import { breakpoints, semiBoldAlias, vwGrid } from "Libs/theme";
import InfoDialog from "Components/InfoDialog";
import {
  css,
  HEADER,
  getCSSVarString,
  SECTION,
  ICON,
  INPUT
} from "Libs/themes";

export const FormWrapper = styled.form`
  padding: 0 32px 32px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  @media (max-width: ${breakpoints[1]}) {
    padding-bottom: 0;
  }
`;

export const Row = styled.div`
  margin: 0 -32px;
  padding: 0 32px;
  border-bottom: 1px solid
    var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
  .dropdown {
    .input-wrapper,
    .select-box {
      width: 150px;
    }
    .select-box__control,
    .select-box:hover .select-box__control {
      background: transparent;
      border: none;
    }
    .select-box__control--is-focused {
      margin: -1px -11px 0 0;
      padding-right: 11px;
      caret-color: transparent;
      box-shadow: none;
    }
    .select-box__menu {
      z-index: 20;
    }
    .select-box__single-value {
      width: 100%;
      text-align: right;
    }
    .select-box__value-container {
      padding: 0;
    }
    .select-box__dropdown-indicator {
      padding: 0;
      margin-right: -4px;
    }
  }

  .backupDropdown {
    .input-wrapper,
    .select-box {
      width: 105px;
    }
    .select-box__menu {
      width: 356px;
      margin-left: -250px;
    }
  }

  &.total-row {
    border-bottom: none;
    margin-bottom: 64px;
    .total-wrapper {
      padding-top: 24px;
      padding-bottom: 0;
      min-height: auto;
    }
    .description {
      color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
      margin: 0;
      display: flex;
      align-items: center;
    }
    .info-dialog {
      cursor: default;
    }
  }

  &.info-icon {
    svg path {
      fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
    }
    &:hover svg path {
      fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)));
    }
  }

  @media (max-width: ${breakpoints[1]}) {
    padding: 20px 16px 16px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    &.total-row {
      margin-bottom: 0;
      .description {
        font-size: 13px;
      }
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 64px;
  .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    flex: 0 0 calc(${vwGrid.standard} * 3 + 32px);
    margin-right: 32px;
  }
  .info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .options {
    display: flex;
    align-items: center;
    order: 1;
    margin-left: auto;
  }
  .info-dialog {
    vertical-align: middle;
  }

  .total {
    height: 40px;
    min-width: 80px;
    box-sizing: border-box;
    line-height: 40px;
    padding: 0 12px;
    font-size: 15px;
    font-weight: 600;
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    color:var(--header-night-color,var(--header-night,var(--night)));
    margin-left: 16px;
  }

  .price {
    max-width: 45%;
  }

  .included {
    font-size: 12px;
    font-weight: 600;
  }

  @media (max-width: ${breakpoints[1]}) {
    display: inline;
    line-height: 24px;
    .label,
    .info,
    .price {
      display: block;
      width: auto;
    }
    .label {
      padding: 8px 0 0;
      font-size: 15px;
      line-height: 24px;
    }
    .price {
      font-size: 14px;
      padding-right: 77px;
      position: relative;
      margin-top: 8px;
    }
    .app-size-info.info-dialog {
      position: absolute;
      right: 0;
      top: 0;
    }
    .options {
      float: right;
      &.plan-type {
        .dropdown {
          .input-wrapper,
          .select-box {
            width: 145px;
          }
        }
      }
      &.environments {
        .dropdown {
          .input-wrapper,
          .select-box {
            width: 80px;
          }
        }
      }
      &.app-size {
        .dropdown {
          .input-wrapper,
          .select-box {
            width: 120px;
          }
        }
      }
      &.storage {
        .dropdown {
          .input-wrapper,
          .select-box {
            width: 80px;
          }
        }
      }
      &.users {
        .users {
          margin-left: 10px;
        }
        .dropdown {
          .input-wrapper,
          .select-box {
            width: 80px;
          }
        }
      }
    }
  }
`;

export const Includes = styled.div`
  .label {
    font-size: 15px;
    margin-bottom: 16px;
    ${semiBoldAlias};
    @media (max-width: ${breakpoints[1]}) {
      padding-top: 19px;
    }
  }
  .features {
    display: flex;
    align-items: flex-start;
  }
  &.development .included ul {
    column-count: 2;
    width: 800px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  min-height: 32px;
  box-sizing: border-box;
  margin-top: 32px;
  button + button {
    margin-left: 16px;
  }
`;

export const TableContainer = styled.div`
  width: 598px;
  @media (max-width: ${breakpoints[2]}) {
    width: 400px;
  }
`;

export const StyledInfoDialog = styled(InfoDialog)`
  margin-left: 8px;
  margin-right: 8px;
`;
