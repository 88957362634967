import styled from "styled-components";

import ActionDropdown from "Components/ActionDropdown";
import { semiBoldAlias } from "Libs/theme";
import { css, BUTTON } from "Libs/themes";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  box-sizing: border-box;
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .copyable-area {
    min-width: 0;
  }
`;

export const HostDropdown = styled(ActionDropdown)`
  &.info-button > button {
    padding-left: 0 !important;

    .text {
      padding-right: 8px;
      ${semiBoldAlias};
      font-size: 14px;
      line-height: 16px;
    }
  }
  .tooltip {
    width: 164px;
  }
  section {
    padding: 16px;
  }
`;

export const DropdownButton = styled.button.attrs({ type: "button" })`
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  flex: 1;
  text-align: left;
  cursor: pointer;
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 16px;

  :hover {
    color:var(--button-skye-color,var(--button-skye,var(--skye)));
  }
`;

export const DropdownButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
