import { createSelector } from "reselect";

const selectIdsFromURL = createSelector(
  state => state.router?.location,
  location => location?.pathname.split("/").slice(1, 4) || []
);

const serviceKindSelector = createSelector(
  state => state.deployment,
  selectIdsFromURL,
  (_, name) => name,
  (deployment, [organizationId, projectId, environmentId], name) => {
    const current = deployment
      ?.getIn([
        "data",
        organizationId,
        projectId,
        decodeURIComponent(environmentId),
        "current"
      ])
      ?.toJS();

    if (!current) {
      return;
    }

    if (current.services[name]) {
      return "Service";
    }

    if (current.webapps[name]) {
      return "Application";
    }

    if (current.workers[name]) {
      return "Worker";
    }
  }
);

export default serviceKindSelector;
