import { fromJS, Map } from "immutable";

import { push } from "Reducers/app";
import logger from "Libs/logger";

const LOAD_SYNCHRONISATION_START =
  "app/environmentSynchronisation/load_synchronisation_start";
const LOAD_SYNCHRONISATION_SUCCESS =
  "app/environmentSynchronisation/load_synchronisation_success";
const LOAD_SYNCHRONISATION_FAILURE =
  "app/environmentSynchronisation/load_synchronisation_failure";

export const sync = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  options
) => {
  return async (dispatch, getState) => {
    const catchError = err => {
      if (
        err.message !== "Nothing to synchronize: you must specify data or code"
      ) {
        logger(err, {
          action: "sync",
          payload: sync,
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId
          }
        });
      }
      dispatch({
        type: LOAD_SYNCHRONISATION_FAILURE,
        error: true,
        payload: err
      });
    };

    dispatch({ type: LOAD_SYNCHRONISATION_START });

    try {
      const environment = getState().environment.getIn(
        [
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        ],
        {}
      );

      environment
        .synchronize(options.syncData, options.syncCode)
        .then(sync => {
          dispatch({
            type: LOAD_SYNCHRONISATION_SUCCESS,
            payload: sync,
            meta: {
              organizationDescriptionId,
              environmentDescriptionId,
              projectDescriptionId
            }
          });

          dispatch(
            push({
              path: "/:organizationDescriptionId/:projectDescriptionId/:environmentDescriptionId",
              args: {
                organizationDescriptionId,
                projectDescriptionId,
                environmentDescriptionId
              }
            })
          );
        })
        .catch(err => {
          catchError(err);
        });
    } catch (err) {
      catchError(err);
    }
  };
};

export default function environmentSynchronisationReducer(
  state = new Map(),
  action
) {
  switch (action.type) {
    case LOAD_SYNCHRONISATION_START:
      return state.set("loading", true).set("errors", false);
    case LOAD_SYNCHRONISATION_SUCCESS:
      return state
        .set("loading", false)
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId
          ],
          fromJS(action.payload)
        )
        .remove("errors");
    case LOAD_SYNCHRONISATION_FAILURE:
      return state.set("loading", false).set("errors", fromJS(action.payload));
    default:
      return state;
  }
}
