import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import Heading2Unstyled from "Components/styleguide/Heading2";
import { Button } from "ds/Button";
import LabelUnstyled from "Components/fields/Label";

export const Wrapper = styled.div`
  .setting-content {
    font-size: 15px;
    color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
    > strong {
      ${semiBoldAlias};
    }
  }
`;

export const Heading2 = styled(Heading2Unstyled)`
  margin: 0 0 16px 0;
`;

export const Sort = styled.div`
  display: flex;
  margin: 0 0 8px 0;
  padding: 0 24px;
`;

export const Label = styled(LabelUnstyled)`
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 24px;
  height: 24px;
  ${semiBoldAlias};
  border: 0;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  background: none;
  span {
    margin: 0 0 0 5px;
  }

  &:last-child {
    margin: 0 88px 0 auto;
  }
`;

export const Info = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const Help = styled.p`
  margin: 16px 0 24px 0;
  svg {
    margin: 0 8px 0 0;
    vertical-align: middle;
  }
`;

export const Default = styled.div`
  margin: 0 0 0 8px;
  padding: 1px 8px 3px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #efe7ff;
  border-radius: 2px;
  color: #9d67ff;
  ${semiBoldAlias};
`;

export const ButtonLink = styled(Button)`
  white-space: nowrap;
`;

export const InfoLayout = styled.div`
  display: flex;
`;

export const Domain = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
`;

export const Date = styled.span`
  flex: 1;
  padding: 0 38px 0 0;
  text-align: right;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 24px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;
