import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import getIcon from "Icons/technologies";

import { css, ICON, getCSSVarString } from "Libs/themes";

const Svg = styled.svg`
  overflow: visible;
`;

const Rect = styled.rect`
  transform: rotate(-45deg);
  transform-origin: center;
`;

const Node = styled.g`
  filter: ${props => `url(#shadow-${props.id})`};
  opacity: 1;
  rect {
    fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
  }
`;

class ServicesNode extends Component {
  render() {
    const { name, id } = this.props;
    return (
      <Svg
        id={`svg-node-${this.props.id}`}
        width="57"
        height="57"
        viewBox="0 0 57 57"
      >
        <defs>
          <filter
            id={`shadow-node-${id}`}
            width="200%"
            height="200%"
            x="-50%"
            y="-50%"
          >
            <feDropShadow
              dx="0"
              dy="3"
              stdDeviation="6"
              floodColor="rgba(75, 97, 128, 0.32)"
              floodOpacity="2"
            />
          </filter>
        </defs>
        <Node id={`node-${id}`} width="40" height="40">
          <Rect width="40" height="40" rx="14" ry="14" x="8" y="0" />
          <image
            x="12.5"
            y="14"
            width="20"
            height="20"
            xlinkHref={`data:image/svg+xml;base64,${window.btoa(
              getIcon(
                name,
                "12.5",
                "14",
                "20",
                "20","var(--icon-slate-fill,var(--icon-slate,var(--slate)))")
            )}`}
          />
        </Node>
      </Svg>
    );
  }
}

ServicesNode.propTypes = {
  onClick: PropTypes.func,
  currentDeployment: PropTypes.object,
  theme: PropTypes.object,
  search: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string
};

export default withTheme(ServicesNode);
