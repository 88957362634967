import styled from "styled-components";

import { css, BUTTON, SECTION } from "Libs/themes";

import getWizardImages from "../img";
import Button from "ds/Button";

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 29% 32px 32px 32px;
  background: transparent
    url(${props => getWizardImages(props.theme).startImage}) 0 0 / 100%
    no-repeat;

  h2 {
    margin-bottom: 8px;
    line-height: 33px;
  }

  p {
    flex: 1;
    margin: 0 0 5px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 900px) {
    padding-top: 19%;
  }
  @media (min-width: 1280px) {
    padding-top: 180px;
    background-size: auto 180px;
    background-position: top center;
  }

  @media (min-width: 1680px) {
    padding-top: 236px;
    background-size: auto 236px;
  }

  @media (min-width: 1920px) {
    padding-top: 237px;
  }

  @media (min-width: 1920px) {
    padding-top: 32px;
    background: none;
  }
`;

export const Footer = styled.footer`
  display: flex;
  margin-top: 32px;
  align-items: center;
`;

export const FooterButton = styled(Button)`
  span {
    transition: all 0.1s ease;
  }

  &:hover {
    color:var(--button-skye-dark-color,var(--button-skye-dark,var(--skye-dark)));
    svg path {
      fill:var(--button-skye-dark-fill,var(--button-skye-dark,var(--skye-dark)));
    }
    span {
      margin-left: 10px;
    }
    &:after {
      display: none;
    }
  }

  > span {
    padding-left: 3px;
  }
`;

export const ImageContainer = styled.div`
  display: none;
  @media (min-width: 1920px) {
    display: block;
    height: 172px;
    margin: -32px -32px 32px;
    background: url(${props => getWizardImages(props.theme).startImage}) 0 0
      no-repeat;
    background-size: contain;
  }
`;
