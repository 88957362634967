import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Map } from "immutable";
import { FormattedMessage, useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import Button from "ds/Button";

import { environmentSelector } from "Reducers/environment";

import Loading from "Components/Loading";
import FilePreviewer from "../../../../../../common/components/FilePreviewer";
import EmptyCard from "../../components/EmptyCard";

import * as S from "./styles";

const ServiceConfiguration = () => {
  const [configRaw, setConfigRaw] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  const {
    appName = "routes",
    environmentId,
    organizationId,
    projectId
  } = useDecodedParams();

  const environment = useSelectorWithUrlParams(environmentSelector);
  const service = useSelector(state =>
    state.service
      ?.getIn(
        ["data", organizationId, projectId, environmentId, "current", appName],
        Map()
      )
      ?.toJS()
  );

  const getPath = () => {
    if (service.class === "service") return [".platform", "services.yaml"];
    if (service.class === "app") return [".platform.app.yaml"];
    return [".platform", "routes.yaml"];
  };

  const getTree = async sha => {
    const platformLib = await import("Libs/platform");
    return platformLib.entities.Tree.get(projectId, sha);
  };

  useEffect(() => {
    let isCanceled = false;
    const getFile = async () => {
      setIsLoading(true);
      const platformLib = await import("Libs/platform");

      let treeSha, tree, file;
      const filePath = getPath();
      const treeCommit = await platformLib.entities.Commit.get(
        projectId,
        environment?.head_commit
      );

      treeSha = treeCommit.tree;

      await filePath.reduce(async (memo, path) => {
        await memo;
        tree = await getTree(treeSha);
        file = tree.data.tree.find(obj => {
          return obj.data.path === path;
        });
        treeSha = file?.data.sha;
      }, undefined);

      if (file) {
        const content = await file.getRawContent();
        if (isCanceled) {
          return;
        }
        setConfigRaw(content);
      }
      if (isCanceled) {
        return;
      }
      setIsLoading(false);
    };
    if (environment?.head_commit) getFile();

    return () => (isCanceled = true);
  }, [environment?.head_commit]);

  return (
    <S.Layout>
      {appName === "routes" && (
        <>
          <p>
            {intl.formatMessage(
              { id: "environment.services.configuration.routes" },
              {
                appname: intl.formatMessage({ id: "app_name" }),
                // eslint-disable-next-line react/display-name
                b: txt => <code>{txt}</code>,
                // eslint-disable-next-line react/display-name
                br: <br />
              }
            )}
          </p>
          <p>
            {intl.formatMessage(
              { id: "environment.services.configuration.routes_doc" },
              {
                // eslint-disable-next-line react/display-name
                a: txt => (
                  <a
                    href={intl.formatMessage({
                      id: "links.documentation.routes"
                    })}
                  >
                    {" "}
                    {txt}{" "}
                  </a>
                )
                // eslint-disable-next-line react/display-name
              }
            )}
          </p>
        </>
      )}

      {isLoading && <Loading iconOnly />}
      {!isLoading && !configRaw && (
        <EmptyCard>
          <FormattedMessage id="services.noconfiguration" />{" "}
          <Button
            variant="link"
            as="a"
            target="blank"
            href={intl.formatMessage({ id: "links.documentation.app" })}
          >
            <FormattedMessage id="learnmore" />
          </Button>
        </EmptyCard>
      )}
      {configRaw && (
        <FilePreviewer filename={getPath().join("/")} content={configRaw} />
      )}
    </S.Layout>
  );
};

export default ServiceConfiguration;
