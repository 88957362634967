import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Map } from "immutable";
import { useDispatch, useSelector } from "react-redux";

import { formatAsCurrency } from "Libs/utils";
import { getMe } from "Reducers/app";

import { Link } from "ds/Button";

import * as S from "./styles";

const TrialRemaining = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    // reload user to be sure to have the last estimation of trial credits
    dispatch(getMe(true));
  }, []);

  const me = useSelector(state => state.app?.get("me", new Map())?.toJS());
  const { current_trial } = me.data;

  if (!current_trial || !current_trial.active || !current_trial.expiration)
    return null;

  const credit = current_trial.spend?.amount - current_trial.current?.amount;

  return (
    <S.Wrapper level="low">
      <S.Plan>
        {intl.formatMessage({
          id: "trial-remaining.plan.name",
          defaultMessage: "Free trial"
        })}
      </S.Plan>

      <S.Infos>
        <S.Info>
          <S.Label>
            {intl.formatMessage({
              id: "trial-remaining.credit.label",
              defaultMessage: "Free trial credit"
            })}
          </S.Label>
          {intl.formatMessage(
            {
              id: "trial-remaining.credit.value",
              defaultMessage: "{credit} of free trial credit remaining"
            },
            {
              credit: formatAsCurrency(
                credit,
                current_trial.current?.currency_code
              )
            }
          )}
        </S.Info>

        <S.Info>
          <S.Label>
            {intl.formatMessage({
              id: "trial-remaining.expiration.label",
              defaultMessage: "Expires"
            })}
          </S.Label>
          {moment(current_trial.expiration).format("D MMMM YYYY [at] h:mm a")}
        </S.Info>
      </S.Infos>

      <Link to={`/-/users/${me.username}/billing`} variant="primary">
        {intl.formatMessage({
          id: "trial-remaining.button",
          defaultMessage: "Upgrade now"
        })}
      </Link>
    </S.Wrapper>
  );
};

export default TrialRemaining;
