import React from "react";
import { useIntl } from "react-intl";

import organizationIntroductionIllustration from "Images/organization-introduction-illustration.svg";
import Link from "Components/styleguide/Link";

import * as S from "./styles";

const NewOrgInfo = () => {
  const formatMessage = useIntl().formatMessage;
  return (
    <S.Layout>
      <S.LeftPane>
        <S.SectionTitle>
          {formatMessage({ id: "organization.new" })}
        </S.SectionTitle>
        <S.TextAndLink>
          <S.Text>
            {formatMessage({ id: "settings.organization_intruduction_text" })}
          </S.Text>
          <S.LearnMore>
            <Link
              href={formatMessage({
                id: "links.documentation.organizations"
              })}
            >
              {formatMessage({ id: "learnmore" })}
            </Link>
          </S.LearnMore>
        </S.TextAndLink>
      </S.LeftPane>
      <S.IntroductionIllustration
        src={organizationIntroductionIllustration}
        alt={formatMessage({
          id: "organization.intruduction.illustration-alt"
        })}
      />
    </S.Layout>
  );
};

export default NewOrgInfo;
