import { useMemo, useRef, useEffect } from "react";

import useMetrics from "./useMetrics";
import objectToFields from "../utils/objectToFields";
import parseDimensions from "../utils/parseDimensions";

const parse = (list, key) => {
  const parsedMetrics = list.reduce((result, { point }) => {
    result[point.timestamp] = {
      timestamp: new Date(point.timestamp),
      ...(result[point.timestamp] || {}),
      ...point.values
        .filter(value => value.info.name.includes(key))
        .map(value => {
          let name = value.info.name.replace(`${key}.`, "");
          const dimensions = parseDimensions(point.dimension);

          if (name.includes("average")) {
            name = name.replace("-", "@");
          }
          if (dimensions?.hostname) {
            name = dimensions.hostname + "@" + name;
          }
          if (dimensions?.mountpoint) {
            name = dimensions.mountpoint + "@" + name;
          }

          return [name, value.value.sum / (value.value.count || 1)];
        })
        .reduce(
          (accumulator, [key, value]) => ({
            [key]: value,
            ...accumulator
          }),
          {}
        )
    };
    return result;
  }, {});
  return Object.values(parsedMetrics);
};

const splitByMountpoint = data => {
  const result = {};
  data.forEach(point => {
    const split = Object.keys(point)
      .filter(key => !["timestamp"].includes(key))
      .reduce((accumulator, key) => {
        const mountpoint = key.split("@")[0];
        accumulator[mountpoint] = {
          timestamp: point["timestamp"],
          ...(accumulator[mountpoint] || []),
          [key.replace(`${mountpoint}@`, "")]: point[key]
        };
        return accumulator;
      }, {});

    Object.entries(split).forEach(([key, value]) => {
      result[key] = [...(result[key] || []), value];
    });
  });
  return result;
};

const getDataFormat = points => ({
  data: points,
  last: points[points.length - 1] || {},
  max: Math.max(0, ...(points.map(point => point.max) || []))
});

const useChart = (fields, collection, href, interval, range, queryParams) => {
  const query = useMemo(
    () =>
      interval && {
        interval: `${interval}s`,
        fields: objectToFields(fields),
        stream: {
          collection: collection,
          stream: "metrics"
        },
        ...queryParams
      },
    [interval, collection]
  );
  const [data, isLoading, error] = useMetrics(href, query, range);

  const previousResult = useRef();

  const result = useMemo(() => {
    const metrics = data?.metrics;
    if (!metrics?.length) {
      return {};
    }

    if (data.range !== range) {
      return previousResult.current;
    }

    const cpu = getDataFormat(parse(metrics, "cpu"));
    const memory = getDataFormat(parse(metrics, "memory"));

    const disks = splitByMountpoint(parse(metrics, "disk"));

    for (const disk in disks) {
      const value = disks[disk];
      disks[disk] = getDataFormat(value);
    }
    return { cpu, memory, disks };
  }, [data, previousResult]);

  useEffect(() => {
    previousResult.current = result;
  }, [result]);

  return [result, isLoading, error];
};

export default useChart;
