import getAccessToken from "./getAccessToken";

import streamToArray from "../utils/streamToArray";
import parseDimensions from "../utils/parseDimensions";

/**
 * Returns a promise that resolves into an array of dimensions already
 * transformed into objects. Empty dimensions will be cleared out.
 *
 * @param {string} chorusURL
 * @param {import("../types.js").DimensionsQuery} query
 *
 * @returns {Promise<Array<import("../types.js").DimensionsResponse>>} list of dimensions available
 */
const getDimensions = async (chorusURL, query) => {
  const endpoint = `${chorusURL}/v1/metrics/dimensions`;
  const accessToken = await getAccessToken();

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.access_token}`
    },
    body: JSON.stringify(query)
  });

  return streamToArray(response.body, ({ dimension }) =>
    parseDimensions(dimension)
  ).then(dimensions => dimensions.filter(dimension => !!dimension));
};

export default getDimensions;
