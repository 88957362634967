import styled from "styled-components";

import Title from "Components/styleguide/Heading2";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Note = styled.dl`
  dt {
    margin: 0 0 8px 0;
    padding: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  dd {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Heading2 = styled(Title)`
  margin-bottom: 16px;
`;
