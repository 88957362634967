import styled from "styled-components";

import { Wrapper } from "../../../../../../../common/components/activities/Activity/ActivityTitle/ActivityTitle.styles";

import { css, getCSSVarString, SECTION, MENU_ITEM } from "Libs/themes";

export const Layout = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color:var(--section-night-color,var(--section-night,var(--night)));
  border-bottom: 1px solid var(--section-stone-border-color,var(--section-stone,var(--stone)));
  padding: 8px;
  margin: -10px -8px 12px -8px;
`;

export const Activity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  background-color:var(--menu-item-skye-light-background-color,var(--menu-item-skye-light,var(--skye-light)));
  padding: 0 8px;
  line-height: 24px;
  border-radius: 2px;

  user {
    font-weight: 600;
  }
`;

export const ActivityIcon = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  g {
    fill: currentColor;
  }
`;

export const ActivityTitle = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 242px;

  a {
    text-decoration: none;
    font-weight: 600;
    color:var(--section-night-color,var(--section-night,var(--night)));
  }

  ${Wrapper} {
    max-width: 242px;
  }
`;
