import styled from "styled-components";

export const ConfigureBody = styled.div`
  p {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const ScopesText = styled.p`
  padding-bottom: 24px;
`;

export const TitleScopesText = styled.p`
  padding-bottom: 5px;
  padding-top: 16px;
`;
