import React from "react";
import { useLocation } from "react-router-dom";
import useDecodedParams from "Hooks/useDecodedParams";
import ChevronIcon from "Components/icons/ChevronIcon";
import useToggle from "./useToggle";

import IntegrationsMenu from "./IntegrationsMenu";
import { MenuChildrenContainer, MenuHeader, Submenu } from "./Menu.styles";
import { OverriddenLink } from "./ProjectSettingsMenu.styles";

function getCurrentSection(pathname) {
  //TODO:SETTINGS change ss to settings
  const regex = new RegExp(`/-/ss/?([A-z]*)?$`);
  const trail = pathname.match(regex);

  // We are not in a project settings page
  if (trail === null) {
    return;
  }

  // We are on a settings section, but not in general
  if (trail[1]) {
    return trail[1];
  }

  // we are on the general project settings section
  if (trail[0]) {
    return "general";
  }
}

const ProjectSettingsMenu = () => {
  const [expanded, { toggle }] = useToggle(true);
  const { projectId, organizationId } = useDecodedParams();
  const { pathname } = useLocation();

  const currentSection = getCurrentSection(pathname);

  // TODO:SETTINGS Change ss to settings when ready
  const rootRoute = `/${organizationId}/${projectId}/-/ss`;

  return (
    <MenuChildrenContainer expanded={expanded}>
      <MenuHeader onClick={toggle}>
        <h1>Project settings</h1>
        <ChevronIcon color="currentColor" isOpen={expanded} animate={true} />
      </MenuHeader>
      {expanded && (
        <Submenu>
          <li>
            <OverriddenLink
              isActive={currentSection === "general"}
              to={rootRoute}
            >
              General
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/access`}
              isActive={currentSection === "access"}
            >
              Access
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/certificates`}
              isActive={currentSection === "certificates"}
            >
              Certificates
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/domains`}
              isActive={currentSection === "domains"}
            >
              Domains
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/deploykey`}
              isActive={currentSection === "deploykey"}
            >
              Deploy Key
            </OverriddenLink>
          </li>
          <li>
            <IntegrationsMenu isActive={currentSection === "integrations"} />
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/variables`}
              isActive={currentSection === "variables"}
            >
              Variables
            </OverriddenLink>
          </li>
        </Submenu>
      )}
    </MenuChildrenContainer>
  );
};

export default ProjectSettingsMenu;
