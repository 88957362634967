import styled from "styled-components";
import { breakpoints, semiBoldAlias, vwGrid } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const InfoLayout = styled.div`
  width: 100%;
  font-size: 15px;
  color: #4b6180;
  display: flex;
  justify-content: space-between;
  strong {
    ${semiBoldAlias};
  }
`;

export const Grid = styled.div`
  flex: 0;
  @media (min-width: ${breakpoints[2]}) {
    display: grid;
    grid-template-columns: repeat(8, ${vwGrid.standard});
    grid-column-gap: 32px;
    margin-left: -24px;
    align-items: center;
  }
  @media (min-width: ${breakpoints[3]}) {
    grid-template-columns: repeat(8, ${vwGrid.wide});
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(8, ${vwGrid.extraWide});
  }
`;

export const CommonSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  display: none;
  @media (min-width: ${breakpoints[2]}) {
    display: initial;
  }
`;

export const Project = styled(CommonSpan)`
  font-size: 16px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  display: initial;
  font-weight: 700;
  @media (min-width: ${breakpoints[2]}) {
    grid-area: 1 / 1 / 1 / span 6;
    padding-left: 32px;
  }
`;

export const Plan = styled(CommonSpan)`
  &:first-letter {
    text-transform: uppercase;
  }
  @media (min-width: ${breakpoints[2]}) {
    grid-area: 1 / auto / 1 / span 2;
  }
`;

export const End = styled.div`
  @media (min-width: ${breakpoints[2]}) {
    flex: 1 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 12px;
    > span,
    > div {
      flex: 0 0;
      &:first-child {
        flex: 1 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;
