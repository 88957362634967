import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  border-radius: 4px;

  p {
    margin: 0 0 24px 0;
    font-size: 14px;
    line-height: 22px;
    ${semiBoldAlias};
  }
`;
