import { Link } from "react-router-dom";
import styled from "styled-components";
import { css, getCSSVarString, LINK } from "Libs/themes";

export const OverriddenLink = styled(Link)`
  color: ${props =>
    props.isActive
      ?"var(--link-skye-color,var(--link-skye,var(--skye)))":"var(--link-slate-color,var(--link-slate,var(--slate)))"};
  text-decoration: none;

  &:hover {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
`;

export const SublistButton = styled.span`
  cursor: pointer;
  ${props => props.active &&"color:var(--link-skye-color,var(--link-skye,var(--skye)))"};
  &:hover {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
`;

export const Sublist = styled.ul`
  margin: 0 0 0 8px;
  padding: 0;
  list-style: none;
`;
