import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, HEADER } from "Libs/themes";

import TextAreaField from "Components/fields/TextAreaField";

export const Layout = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 24px;
  .primary {
    margin-right: 10px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 100%;
      box-sizing: boarder-box;
    }
    > div:first-child {
      width: 33%;
      padding-right: 20px;
    }
  }
  .toggle-field {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  .actions {
    position: relative;
    bottom: auto;
    left: auto;
    min-width: 186px;
    padding-top: 26px;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

export const Textarea = styled(TextAreaField)`
  width: 100%;

  > div:first-child {
    max-width: 100%;
  }

  .simplebar-wrapper {
    height: 160px;
    textarea {
      min-height: 156px;
    }
    .error-alert .message {
      margin-top: 4px;
    }
  }

  .description {
    margin-top: 4px;
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 24px;
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));

    .icon-success-wrapper {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
`;
