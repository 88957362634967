import React from "react";
import PropTypes from "prop-types";

import Sticker from "ds/Stickers";

class ServiceMetricsErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  state = { error: null };

  render() {
    if (this.state.error) {
      if (this.state.error.message.startsWith("Metrics not available")) {
        return null;
      }

      return <Sticker priority="critical" text={this.state.error.message} />;
    }

    return this.props.children;
  }
}

ServiceMetricsErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ServiceMetricsErrorBoundary;
