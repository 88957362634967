import styled from "styled-components";

import { getCSSVarString, ICON, SECTION, css } from "Libs/themes";

import ChevronIcon from "Components/icons/ChevronIcon";
import Cell from "../Cell";
import Link from "../../../../../common/components/Link";

export const Layout = styled(Cell).attrs({ as: "th" })`
  padding-top: 0;
  padding-bottom: 8px;
  overflow: visible;
  width: ${props => (props.id === "subject" ? 100 : 1)}%;
`;

export const HeaderSort = styled(Link).attrs({ as: "button" })`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color:var(--header-sort-section-color,var(--section-slate-color,var(--section-slate,var(--slate))));
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  &:hover:not(:focus):after {
    content: none;
  }
`;

export const Chevron = styled(ChevronIcon)`
  width: 20px;
  margin-left: 4px;
  path {
    fill: ${({ isActive }) =>
      isActive
        ?"var(--icon-skye-fill,var(--icon-skye,var(--skye)))":"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"};
  }
`;
