import React from "react";
import PropTypes from "prop-types";

import InfoDialog from "Components/InfoDialog";

import CardSkeleton from "../components/CardSkeleton";
import Chart from "../components/Chart";

import formatPercentage from "../utils/formatPercentage";

import * as S from "../styles";
import { useIntl } from "react-intl";

const Cpu = ({ hosts, data, isLoading, timeframe, onBrush }) => {
  const intl = useIntl();

  if (isLoading || !data) {
    return <CardSkeleton />;
  }

  return (
    <S.Container>
      <S.ChartHeading>
        <S.Row>
          <S.ChartTitle>
            {intl.formatMessage({ id: "metrics.cpu.title" })}
          </S.ChartTitle>
          <S.InfoDialogWrapper>
            <InfoDialog
              title={intl.formatMessage({ id: "metrics.cpu.dialog.title" })}
              text={intl.formatMessage({ id: "metrics.cpu.dialog.text" })}
              to={intl.formatMessage({ id: "links.documentation.metric" })}
              linkText={intl.formatMessage({ id: "learnmore" })}
            />
          </S.InfoDialogWrapper>
        </S.Row>
        <S.Info>
          <S.Current>
            {data.last[`average@value`]?.toFixed(2) || 0}CPUs
          </S.Current>
          /{data.last[`max`] || "X"}CPUs{" "}
          <S.Percentage>
            {formatPercentage(data.last[`average@percentage`])}
          </S.Percentage>
          <S.Description>
            {intl.formatMessage({ id: "metrics.currentavg" })}
          </S.Description>
        </S.Info>
      </S.ChartHeading>
      <Chart
        yFormatter={value => `${value}CPU`}
        tooltipFormatter={number => `${number?.toFixed(2)}CPU`}
        hosts={hosts}
        data={data.data}
        max={data.max}
        timeframe={timeframe}
        onBrush={onBrush}
      />
    </S.Container>
  );
};

Cpu.propTypes = {
  hosts: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  timeframe: PropTypes.object,
  onBrush: PropTypes.func
};

export default Cpu;
