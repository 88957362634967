import React from "react";
import ChevronIcon from "Components/icons/ChevronIcon";
import useToggle from "./useToggle";
import { MenuChildrenContainer, MenuHeader, Submenu } from "./Menu.styles";
import EnvironmentTree from "./EnvironmentTree";

const EnvironmentSettingsMenu = () => {
  const [expanded, { toggle }] = useToggle(true);
  return (
    <MenuChildrenContainer expanded={expanded}>
      <MenuHeader onClick={toggle}>
        <h1>Environments</h1>
        <ChevronIcon color="currentColor" isOpen={expanded} animate={true} />
      </MenuHeader>
      {expanded && (
        <Submenu>
          <EnvironmentTree />
        </Submenu>
      )}
    </MenuChildrenContainer>
  );
};

export default EnvironmentSettingsMenu;
