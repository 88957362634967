import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Map } from "immutable";

import { capitalize, goToEnvironment } from "Libs/utils";

import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";

import AccessibleTooltip from "Components/AccessibleTooltip";
import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper } from "ds/Button";
import Checkbox from "ds/Checkbox";
import ContentPaneLayout from "Components/ContentPaneLayout";
import Loading from "Components/Loading";
import Error from "Components/Error";
import CopyableArea from "Components/CopyableArea";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";

import { sync } from "Reducers/environment/actions/sync";

class EnvironmentSyncPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      syncCode: false,
      syncData: false
    };
    this.toggleSyncData = this.toggleSyncData.bind(this);
    this.toggleSyncCode = this.toggleSyncCode.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  toggleSyncData() {
    this.setState(prevState => ({
      syncData: !prevState.syncData
    }));
  }

  toggleSyncCode() {
    this.setState(prevState => ({
      syncCode: !prevState.syncCode
    }));
  }

  render() {
    const {
      environment = {},
      parent = {},
      isLoading,
      push,
      organizationId,
      projectId,
      environmentId,
      intl,
      sync,
      errors,
      isEnvironmentLoading
    } = this.props;

    const tooltipOffset = (
      { left, top },
      currentEvent,
      currentTarget,
      node
    ) => {
      const d = document.documentElement;
      left = Math.min(d.clientWidth - node.clientWidth, left);
      top = Math.min(d.clientHeight - node.clientHeight, top);

      left = Math.max(0, left) - 45;
      top = Math.max(0, top);
      return { top, left };
    };

    const { syncData, syncCode } = this.state;

    const disableSyncButton = !syncData && !syncCode;

    if (!isLoading && !parent) {
      return <div>To sync an env need to have a parent</div>;
    }

    let command = `${intl.formatMessage({
      id: "CLI_command_name"
    })} sync -e ${environmentId}`;

    if (syncData && syncCode) {
      command = `${command} data code`;
    } else if (syncCode) {
      command = `${command} code`;
    } else if (syncData) {
      command = `${command} data`;
    } else {
      command = "";
    }

    return (
      <ModalWrapper
        id="environment-sync-modal"
        shouldCloseOnOverlayClick={true}
        isOpen={true}
        title={`
        ${intl.formatMessage({ id: "sync" })}
        ${parent.title}
        ${intl.formatMessage({ id: "into" })}
        ${environment.title}`}
        onRequestClose={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        closeModal={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        modalClass="modal-environment-action modal-environment-sync"
        announceTitle={
          intl.formatMessage({ id: "sync" }) +
          " " +
          parent.name +
          " " +
          intl.formatMessage({ id: "into" }) +
          " " +
          environment.name
        }
      >
        <ContentPaneLayout className="modal-body">
          {this.state.errors && this.state.errors.message && (
            <Error>{this.state.errors.message}</Error>
          )}
          <p>
            Select what you’d like to include in your sync of your{" "}
            <strong>{parent.title}</strong> environment to your{" "}
            <strong>{environment.title}</strong> environment.
          </p>
          <div>
            <Checkbox
              id="environment-sync-modal-data-switch"
              forId="environment-sync-modal-data"
              label={
                <span>
                  {intl.formatMessage({ id: "sync.data.replace" })}{" "}
                  <strong>{environment.name}</strong>{" "}
                  {intl.formatMessage({ id: "sync.data.with" })}{" "}
                  <strong>{parent.name}</strong>.
                </span>
              }
              onCheckedChanged={this.toggleSyncData}
              checked={syncData}
              error={errors && errors.detail}
            />
            <Checkbox
              id="environment-sync-modal-code-switch"
              forId="environment-sync-modal-code"
              label={
                <span>
                  {intl.formatMessage({ id: "sync.code.merge" })}{" "}
                  <strong>{parent.name}</strong>{" "}
                  {intl.formatMessage({ id: "sync.code.into" })}{" "}
                  <strong>{environment.name}</strong>.
                </span>
              }
              onCheckedChanged={this.toggleSyncCode}
              checked={syncCode}
              error={errors && errors.detail}
            />
          </div>

          <Heading4 style={{ marginBottom: 16 }}>
            CLI{" "}
            <InfoDialog
              title="Learn more"
              text={`You can also use this terminal command to sync from ${parent.title} to ${environment.title}`}
              to={intl.formatMessage({
                id: "links.documentation.getting_started_CLI"
              })}
              linkText="Help"
            />
          </Heading4>
          <CopyableArea
            id="environment-sync-cmd"
            content={command}
            singleLine={true}
          >
            {command}
          </CopyableArea>
          {isEnvironmentLoading || isLoading ? (
            <Loading />
          ) : (
            <ButtonWrapper hasSpacing justifyContent="end">
              <AccessibleTooltip
                tooltipProps={{
                  children: intl.formatMessage({ id: "sync.select" }),
                  className: "rightArrowDown",
                  place: "top",
                  disable: !disableSyncButton,
                  overridePosition: tooltipOffset
                }}
              >
                <Button
                  id="environment-sync-save-btn"
                  type="submit"
                  onClick={e => {
                    e.preventDefault();
                    sync(syncData, syncCode);
                  }}
                  disabled={disableSyncButton}
                >
                  {capitalize(intl.formatMessage({ id: "sync.button" }))}
                </Button>
              </AccessibleTooltip>
              <Button
                id="environment-sync-cancel-btn"
                variant="secondary"
                onClick={() =>
                  goToEnvironment(
                    push,
                    organizationId,
                    projectId,
                    environmentId
                  )
                }
              >
                {capitalize(intl.formatMessage({ id: "cancel" }))}
              </Button>
            </ButtonWrapper>
          )}
        </ContentPaneLayout>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (
  state,
  { environmentId, organizationId, projectId }
) => {
  const environment = environmentSelector(state, {
    organizationId,
    projectId,
    environmentId
  });

  const synchronisation = state.synchronisation || Map();

  return {
    environment,
    parent: environmentSelector(state, {
      organizationId,
      projectId,
      environmentId: environment?.parent
    }),
    isEnvironmentLoading: environmentLoadingSelector(state, {
      projectId,
      environmentId
    }),
    isLoading: synchronisation.get("loading"),
    errors: synchronisation.get("errors", {})
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  sync: (syncData, syncCode) =>
    dispatch(
      sync(props.organizationId, props.projectId, props.environmentId, {
        syncData,
        syncCode
      })
    )
});

EnvironmentSyncPane.propTypes = {
  environment: PropTypes.object,
  parent: PropTypes.object,
  intl: PropTypes.object,
  isLoading: PropTypes.bool,
  push: PropTypes.func,
  sync: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired,
  errors: PropTypes.object,
  isEnvironmentLoading: PropTypes.bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentSyncPane));
