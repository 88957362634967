import { Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import StreamWorker from "Libs/stream.worker";

/**
 * Get activity's logs
 *
 * @param {object} activity
 * @param {int} retryNumber
 *
 */

type LoadActivityThunkArgType = {
  activity: {
    id: number;
    getLink: (refName: string) => string;
  };
  retryNumber?: number;
};

type LoadActivityThunkReturnType = {};

export const loadLogFromActivity = createAsyncThunk<
  LoadActivityThunkReturnType,
  LoadActivityThunkArgType
>(
  "app/activity/stream",

  async ({ activity, retryNumber = 0 }, { dispatch, rejectWithValue }) => {
    const worker = new StreamWorker();

    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const accessToken = await client.getAccessToken();

    worker.postMessage({
      url: `${activity.getLink("log")}?max_items=0&max_delay=1000`,
      accessToken,
      maxBatchNumber: 1000
    });
    worker.onmessage = async e => {
      const { data } = e;

      if (data.done) {
        dispatch(
          streamEnded({ activityId: activity.id, stream: data.fragmentSet })
        );
      }

      if (data.error) {
        if (
          retryNumber < 3 &&
          (data.error === 401 || data.error === "Failed to fetch")
        ) {
          // Re-authenticate
          await client.reAuthenticate();

          // Retry
          return dispatch(
            loadLogFromActivity({ activity, retryNumber: ++retryNumber })
          );
        }

        logger(
          {
            errorMessage: data.error,
            activityId: activity.id
          },
          {
            action: "loadLogFromActivity"
          }
        );
        return rejectWithValue({ errors: data.error });
      }

      dispatch(
        streamFinished({ activityId: activity.id, stream: data.fragmentSet })
      );
    };
  }
);

const logs = createSlice({
  name: "logs",
  initialState: Map({ data: Map() }),
  reducers: {
    streamFinished(state, { payload }) {
      const { activityId, stream } = payload;
      return state
        .setIn(["data", activityId, "loading"], false)
        .setIn(["data", activityId, "status"], "fulfilled")
        .setIn(["data", activityId, "log"], stream);
    },
    streamEnded(state, { payload }) {
      const { activityId } = payload;
      return state.setIn(["data", activityId, "streamEnded"], true);
    }
  },
  extraReducers: builder =>
    builder
      .addCase(loadLogFromActivity.pending, (state, action) => {
        const { activity } = action.meta.arg;
        return state
          .setIn(["data", activity.id, "status"], "pending")
          .setIn(["data", activity.id, "loading"], true);
      })
      .addCase(loadLogFromActivity.rejected, (state, action) => {
        const { payload, meta } = action;
        const activity = meta.arg.activity;
        return state
          .setIn(["data", activity.id, "status"], "rejected")
          .setIn(["data", activity.id, "loading"], false)
          .setIn(["data", activity.id, "errors"], payload);
      })
});

export const { streamFinished, streamEnded } = logs.actions;
export default logs.reducer;
