import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { LiveMessage } from "react-aria-live";

import Heading2 from "Components/styleguide/Heading2";
import ListGroup from "Components/ListGroup";
import Loading from "Components/Loading";
import CopyableArea from "Components/CopyableArea";
import SettingLine from "Components/SettingLine";
import PageDescription from "Components/PageDescription";
import PageMeta from "Components/PageMeta";

const Layout = styled.div`
  word-break: break-all;
  word-wrap: break-word;
  width: 100%;
  p {
    margin: 0 0 30px 0;
  }
`;

const ProjectDeployKeyLayout = styled.div`
  word-break: break-all;
  word-wrap: break-word;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

class ProjectDeployKey extends React.Component {
  render() {
    const { isLoading, project } = this.props;

    return (
      <Layout>
        <LiveMessage
          message={`${this.props.projectId} deploy key`}
          aria-live="polite"
        />
        <PageMeta title={`Deploy key | ${project?.title}`} />
        <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
          <FormattedMessage id="deploy_key" />
        </Heading2>
        <PageDescription>
          <FormattedMessage id="deploy_key_description" />
        </PageDescription>
        <ListGroup aria-labelledby="settings-heading">
          {isLoading && <Loading />}
          <SettingLine
            info={
              <span className="deploy-key-field">
                <FormattedMessage id="ssh_deploy_key" />
              </span>
            }
            noToggle={true}
            isOpen={true}
          >
            <ProjectDeployKeyLayout>
              <CopyableArea
                withCopyText
                id="project-deploy-key"
                content={this.props.clientSshKey}
              >
                {this.props.clientSshKey}
              </CopyableArea>
            </ProjectDeployKeyLayout>
          </SettingLine>
        </ListGroup>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const project = state.project.getIn(
    ["data", props.organizationId, props.projectId],
    new Map()
  );

  return {
    clientSshKey: project.repository && project.repository.client_ssh_key,
    isLoading: state.project.get("loading"),
    project: state.project.getIn([
      "data",
      props.organizationId,
      props.projectId
    ])
  };
};

ProjectDeployKey.propTypes = {
  clientSshKey: PropTypes.string,
  isLoading: PropTypes.bool,
  projectId: PropTypes.string,
  project: PropTypes.object
};

export default connect(mapStateToProps)(ProjectDeployKey);
