import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Button, Link } from "ds/Button";
import Loading from "Components/Loading";

const ConnectedAccountLineLink = ({
  provider,
  isConnected,
  isLoading,
  disconnectAccount
}) => {
  if (isLoading) {
    return <Loading />;
  }

  return !isConnected ? (
    <Link
      id={`${provider.name}-connect-link`}
      href={`${process.env.AUTH_URL}/auth/authorize/${provider.name}?intent=signup`}
    >
      <FormattedMessage id="connect_with" /> {provider.label}
    </Link>
  ) : (
    <Button
      variant="link"
      id={`${provider.name}-disconnect-link`}
      onClick={() => disconnectAccount(provider.name)}
    >
      <FormattedMessage id="disconnect" />
    </Button>
  );
};

ConnectedAccountLineLink.propTypes = {
  provider: PropTypes.object,
  disconnectAccount: PropTypes.func,
  isLoading: PropTypes.bool,
  isConnected: PropTypes.bool
};

export default ConnectedAccountLineLink;
