import { createSelector } from "@reduxjs/toolkit";
import { selectMetrics } from "./metrics.selector";

export const selectSettings = createSelector(
  selectMetrics,
  metrics => metrics.settings
);

export const selectIsActivitiesOverlayVisible = createSelector(
  selectSettings,
  settings => settings.isActivitiesOverlayVisible
);
