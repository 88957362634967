import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, HEADER } from "Libs/themes";

import ModalBody from "Components/ModalBody";
import Label from "Components/fields/Label";

export const ModalBodyWrapper = styled(ModalBody)`
  h3 {
    margin-top: 0;
  }
`;

export const UsersLabel = styled.div`
  font-size: 13px;
  line-height: 16px;
  color:var(--section-periwinkle-grey-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  ${semiBoldAlias};

  margin-bottom: 8px;
`;

export const Email = styled.div`
  text-overflow: ellipsis;
  overflow-x: hidden;

  font-size: 12px;
  ${semiBoldAlias};
`;

export const TagGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 500px));
  grid-row-gap: 8px;
  grid-column-gap: 8px;
`;

export const ExtraTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color:var(--section-granite-background-color,var(--section-granite,var(--granite)));
  font-size: 10px;
  line-height: 18px;
  border-radius: 2px;
  ${semiBoldAlias({ fontWeight: 700 })};
`;

export const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -4px;
  font-style: normal;
  font-weight: normal;

  font-size: 14px;
  line-height: 24px;

  width: max-content;
`;

export const CustomLabel = styled(Label)`
  margin-bottom: 0px;
  margin-right: 12px;
  font-size: 14px;
  line-height: 24px;
  ${semiBoldAlias({ fontWeight: "bold" })};

  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;

export const LabelWithInfo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 16px;
`;

export const InformationText = styled.div`
  font-size: 15px;
  line-height: 24px;

  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));

  margin-bottom: 24px;
`;

export const CheckboxLayout = styled.div`
  display: flex;

  span {
    cursor: pointer;
  }
`;
