import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, ButtonWrapper } from "ds/Button";
import Modal from "Components/Modal";

import { Paragraph } from "./styles";

const DisableTFAModal = ({ onDisable, onCancel, isOpen }) => {
  const intl = useIntl();

  return (
    <Modal
      id="disable-tfa-modal"
      closeModal={onCancel}
      isOpen={isOpen}
      noCloseButton={false}
      shouldCloseOnOverlayClick
      modalClass="modal-medium"
      title={intl.formatMessage({ id: "security.manage.modal.title" })}
    >
      <Paragraph>
        <FormattedMessage id="security.manage.modal.text" />
      </Paragraph>
      <ButtonWrapper hasSpacing justifyContent="end">
        <Button onClick={onDisable}>
          <FormattedMessage id="security.manage.modal.confirm" />
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          <FormattedMessage id="security.manage.modal.cancel" />
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

DisableTFAModal.propTypes = {
  onDisable: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
};

export default DisableTFAModal;
