import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";

import ProjectPage from "../new-settings/Page";
import DetailPage from "./detail/Page";
import SettingsRoutes from "./settings/Routes";
import NewSettingsRoutes from "./new-settings/Routes";
import ActionsRoutes from "./actions/Routes";
import ServicesRoutes from "./services/Routes";
import BackupsPage from "./backups/Page";
import MetricsPage from "./metrics/Page";

import Page from "./Page";
import PluginApp from "../app/Page";

const Routes = () => {
  const { path, url } = useRouteMatch();
  const { organizationId, projectId, environmentId } = useDecodedParams();

  // TODO:SETTINGS This function will be no longer needed once the new settings
  // feature is finished
  function newSettings() {
    if (process.env.ENABLE_NEW_SETTINGS) {
      return (
        <Route path={`${path}/ss`}>
          <ProjectPage>
            <NewSettingsRoutes />
          </ProjectPage>
        </Route>
      );
    }

    return null;
  }

  return (
    <Switch>
      {
        // TODO:SETTINGS replace SettingsRoutes by new settings routes
        // And delete this function call once new settings are ready to be deployed
        newSettings()
      }
      <Page environmentId={environmentId}>
        <Route path={`${path}/metrics`}>
          <MetricsPage />
        </Route>
        <Route path={`${path}/backups`}>
          <BackupsPage />
        </Route>
        <Route path={`${path}/actions`}>
          <DetailPage>
            <ActionsRoutes />
          </DetailPage>
        </Route>
        <Route path={`${path}/settings`}>
          <SettingsRoutes />
        </Route>
        <Route exact path={`${path}/services`}>
          <Redirect to={`${url}/services/routes`} />
        </Route>
        <Route path={`${path}/services/:appName?`}>
          <ServicesRoutes />
        </Route>
        <Route path={`${path}/-/app/:appId`}>
          <PluginApp
            route="/:organizationId/:projectId/:environmentId/"
            type="tab"
            organizationId={organizationId}
            projectId={projectId}
            environmentId={environmentId}
          />
        </Route>
        <Route path={`${path}/log/:activityId`}>
          <DetailPage />
        </Route>
        <Route exact path={path}>
          <DetailPage />
        </Route>
      </Page>
    </Switch>
  );
};

export default Routes;
