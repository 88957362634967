import { useMemo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useDecodedParams from "Hooks/useDecodedParams";
import config from "Constants/api_config";

import getAccessToken from "../api/getAccessToken";

import GridWorker from "../utils/workers/grid.worker";
import ActivitiesWorker from "../utils/workers/activities.worker";
import objectToFields from "../utils/objectToFields";
import {
  MetricsOutputEvents,
  MetricsInputEvents,
  ActivityOutputEvents,
  ActivityInputEvents
} from "../utils/workerEventTypes";
import { loadDeployment } from "Reducers/environment/deployment";

import { gridFields as fields } from "../settings";

import useBetterServices from "./useBetterServices";

const refreshToken = async postMessage => {
  const accessToken = await getAccessToken();
  postMessage({ type: MetricsInputEvents.refreshToken, payload: accessToken });
};

const onMessage = (event, postMessage, setState) => {
  switch (event.data.type) {
    case MetricsOutputEvents.refreshToken:
      refreshToken(postMessage);
      break;
    case MetricsOutputEvents.ready:
      setState(state => ({ ...state, ...event.data.payload }));
      break;
    case MetricsOutputEvents.error:
      setState({ error: event.data.payload });
      break;
    case ActivityOutputEvents.ready:
      setState(state => ({ ...state, activities: event.data.payload }));
      break;
    default:
  }
};

/**
 * @param {string} chorusURL
 * @param {string} collection
 * @returns {[object, message => void]}
 */
const useGridWorker = (chorusURL, collection, selectedTimeframe) => {
  const dispatch = useDispatch();

  const { organizationId, projectId, environmentId } = useDecodedParams();

  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [state, setState] = useState({ range: [] });
  const [gridWorker, setGridWorker] = useState();
  const [activitiesWorker, setActivitiesWorker] = useState();

  const services = useBetterServices();

  useEffect(() => {
    const _activitiesWorker = new ActivitiesWorker();
    const activitiesWorkerPostMessage =
      _activitiesWorker.postMessage.bind(_activitiesWorker);

    const _gridWorker = new GridWorker();
    const gridWorkerPostMessage = _gridWorker.postMessage.bind(_gridWorker);

    getAccessToken().then(accessToken => {
      _gridWorker.onmessage = event => {
        onMessage(event, gridWorkerPostMessage, setState);
        setIsLoadingMetrics(false);
      };
      _gridWorker.postMessage({
        type: MetricsInputEvents.init,
        payload: {
          chorusURL,
          accessToken
        }
      });

      _activitiesWorker.onmessage = event =>
        onMessage(event, activitiesWorkerPostMessage, setState);

      _activitiesWorker.postMessage({
        type: ActivityInputEvents.init,
        payload: {
          projectId,
          environmentId,
          apiURL: config.api_url,
          accessToken
        }
      });

      setActivitiesWorker(_activitiesWorker);
      setGridWorker(_gridWorker);
    });

    dispatch(loadDeployment(organizationId, projectId, environmentId));

    return () => {
      _gridWorker.postMessage({ type: MetricsInputEvents.stopPolling });
      _gridWorker.terminate();
      _activitiesWorker.postMessage({ type: MetricsInputEvents.stopPolling });
      _activitiesWorker.terminate();
    };
  }, []);

  useEffect(() => {
    if (!gridWorker) {
      return;
    }

    setIsLoadingMetrics(true);

    gridWorker.postMessage({ type: MetricsInputEvents.stopPolling });

    if (selectedTimeframe.range) {
      gridWorker.postMessage({
        type: MetricsInputEvents.startPolling,
        payload: {
          range: selectedTimeframe.range,
          query: {
            interval: `${selectedTimeframe.interval}s`,
            fields: objectToFields(fields),
            stream: {
              collection: collection,
              stream: "metrics"
            }
          }
        }
      });
    } else {
      gridWorker.postMessage({
        type: MetricsInputEvents.request,
        payload: {
          query: {
            interval: `${selectedTimeframe.interval}s`,
            fields: objectToFields(fields),
            stream: {
              collection: collection,
              stream: "metrics"
            },
            range: {
              from: selectedTimeframe.from,
              to: selectedTimeframe.to
            }
          }
        }
      });
    }
  }, [gridWorker, selectedTimeframe]);

  useEffect(() => {
    if (!activitiesWorker) {
      return;
    }

    activitiesWorker.postMessage({ type: ActivityInputEvents.stopPolling });

    if (selectedTimeframe.range) {
      activitiesWorker.postMessage({
        type: ActivityInputEvents.startPolling,
        payload: {
          range: selectedTimeframe.range
        }
      });
    } else {
      activitiesWorker.postMessage({
        type: ActivityInputEvents.request,
        payload: {
          from: selectedTimeframe.from,
          to: selectedTimeframe.to
        }
      });
    }
  }, [activitiesWorker, selectedTimeframe]);

  // Merges the service metrics with the extra service metadata
  const servicesWithData = useMemo(() => {
    if (!state || !state?.services || !services) {
      return undefined;
    }

    const transformed = Object.entries(services)
      .filter(([serviceId]) => state.services[serviceId])
      .map(([serviceId, serviceInfo]) => ({
        id: serviceId,
        ...serviceInfo,
        metrics: state.services[serviceId]
      }));

    return {
      ...state,
      services: transformed
    };
  }, [state, services]);

  return [servicesWithData, state?.range, isLoadingMetrics];
};

export default useGridWorker;
