import styled from "styled-components";
import { css, ICON, getCSSVarString, HEADER, SECTION } from "Libs/themes";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: auto;
  padding: 0 33px;
  flex-direction: column;
  min-height: 300px;

  .rcs-custom-scroll {
    position: relative;
    width: calc(100% + 48px);
    margin: 0 -24px;
    .rcs-custom-scrollbar {
      right: 0;
      .rcs-inner-handle {
        background-color:var(--section-granite-background-color,var(--section-granite,var(--granite)));
      }
    }
  }

  .rcs-custom-scroll .rcs-positioning + .rcs-inner-container:after {
    background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    background-image: none;
    bottom: 0;
    left: 16px;
    right: 16px;
    top: auto;
    height: 1px;
  }

  .rcs-custom-scroll
    .rcs-positioning
    + .rcs-content-scrolled.rcs-inner-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    z-index: 1;
  }

  .rcs-positioning {
    right: 4px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 40px;
  flex-direction: column;
  h2 {
    color: var(--header-night-color,var(--header-night,var(--night))) !important;
    margin-bottom: 16px;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .search-wrapper {
    &.search-active {
      .search-image svg rect {
        fill:var(--icon-skye-dark-fill,var(--icon-skye-dark,var(--skye-dark)));
      }
    }
    input.search::placeholder {
      font-size: 14px;
    }
    @media (min-width: 1280px) {
      width: 400px;
    }
  }
  .outlined .select-box__control,
  .outlined .select-box:hover .select-box__control {
    border-color: transparent;
  }
  @media (min-width: 1010px) {
    flex-direction: row;
  }
`;

export const ResultsWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 338px;
  grid-template-rows: 80px;
  grid-gap: 24px;
  justify-content: center;
  margin: 0 auto;
  .selected {
    position: fixed;
    z-index: 2;
    right: 15%;
    bottom: 10%;
    padding: 30px;
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 338px);
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, minmax(272px, 296px));
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, minmax(296px, 1fr));
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, minmax(296px, 2fr));
  }
  @media (min-width: 2560px) {
    grid-template-columns: repeat(5, minmax(296px, 2fr));
  }
`;

export const SelectorWrapper = styled.div`
  display: inline-block;
  position: relative;
  z-index: 2;
  .input-wrapper {
    width: auto;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px 0;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 34px;
`;
