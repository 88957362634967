import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "ds/Button";
import Sticker from "ds/Stickers";

import * as S from "./Errors.styles";

const GenericError = ({ children }) => {
  const username = useSelector(({ app }) => app.getIn(["me", "username"]));

  return (
    <>
      <Sticker priority="critical" text={children} />
      <S.H2>Metrics</S.H2>
      <S.Layout>
        <S.Illustration />
        <S.H3>
          <FormattedMessage id="metrics.error.generic.header" />
        </S.H3>
        <S.Paragraph>
          <FormattedMessage id="metrics.error.generic.paragraph" />
        </S.Paragraph>
        <FormattedMessage
          id="metrics.error.generic.link"
          values={{
            // eslint-disable-next-line react/display-name
            a: (...chunks) => (
              <Button
                variant="link"
                as={Link}
                target="_blank"
                to={`/-/users/${username}/tickets/open`}
              >
                {chunks}
              </Button>
            )
          }}
        />
      </S.Layout>
    </>
  );
};

GenericError.propTypes = {
  children: PropTypes.node
};

export default GenericError;
