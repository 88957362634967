import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Tab, TabsLayout } from "./Tabs.styles";

export function getActiveTab(tabs, currentPathName) {
  const exactMatch = tabs.find(
    tab => decodeURIComponent(currentPathName) === decodeURIComponent(tab.route)
  );

  if (exactMatch) {
    return exactMatch;
  }

  if (currentPathName.includes) {
    return tabs
      .filter(tab => currentPathName.includes(tab.route))
      .reduce(
        (acc, tab) => (tab.route.length > acc.route?.length ? tab : acc),
        { route: "" }
      );
  }

  return null;
}

const getTabName = (tabName, intl) =>
  tabName.startsWith("plugin.")
    ? tabName.replace("plugin.", "")
    : intl.formatMessage({ id: `tabs.${tabName}` });

const Tabs = ({ tabs, currentPathName, links, intl }) => {
  const currentTabName = getActiveTab(tabs, currentPathName).name;

  return (
    <TabsLayout role="menubar">
      {tabs.map(tab => {
        if (
          tab.permission &&
          links &&
          ((typeof tab.permission === "function" &&
            !tab.permission(currentPathName, links)) ||
            (typeof tab.permission === "string" && !links[tab.permission]))
        ) {
          return;
        }
        const isActive = tab.name === currentTabName;

        return (
          <Tab key={tab.name} active={isActive} role="menuitem">
            <Link
              id={tab.name}
              to={tab.route}
              className={`tab tab-${tab.name} ${isActive ? "active" : ""}`}
            >
              {getTabName(tab.name, intl)}
            </Link>
          </Tab>
        );
      })}
    </TabsLayout>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  currentPathName: PropTypes.string.isRequired,
  links: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(Tabs);
