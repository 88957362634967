import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import client from "Libs/platform";
import { formatAsCurrency, orderTotal } from "Libs/utils";
import Loading from "Components/Loading";
import Heading2 from "Components/styleguide/Heading2";
import {
  loadOrganizationRecurringOrder,
  organizationRecurringOrderDataSelector,
  organizationRecurringOrderLoadingSelector
} from "Reducers/organization/order";
import * as S from "./OrderDetailSummary.styles";

const OrderDetailSummary = ({ organizationDescriptionId, organizationId }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [currency, setCurrency] = useState();
  const order = useSelector(organizationRecurringOrderDataSelector);
  const loading = useSelector(organizationRecurringOrderLoadingSelector);
  // const me = useSelector(state => state.app?.get("me", new Map())?.toJS());

  useEffect(() => {
    let isCanceled = false;
    const getEstimation = async () => {
      const params = {
        plan: "trial/development",
        storage: 1024,
        environments: 3,
        user_licenses: 1,
        format: "complex"
      };
      const result = await client.getOrganizationSubscriptionEstimate(
        organizationId,
        params
      );
      if (isCanceled) {
        return false;
      }
      setCurrency(result.total?.currency_code);
    };

    if (organizationId) {
      dispatch(loadOrganizationRecurringOrder(organizationDescriptionId));
      getEstimation();
    }

    // reload user to be sure to have the last estimation of trial credits
    // dispatch(getMe(true));

    return () => (isCanceled = true);
  }, [organizationId]);

  const orderCurrency = order?.components.base_price.currency;
  const orderDate = date => momentjs(date).format("D MMM, YYYY");

  if (loading || !currency) return <Loading />;

  if (!order) {
    return (
      <>
        <S.Line className="no-invoices">
          <span>{formatMessage({ id: "no_upcoming_invoices" })}</span>
        </S.Line>
        <S.Line className="subtotal-discount empty">
          <span>{formatMessage({ id: "subtotal" })}</span>
          <span>{formatAsCurrency(0, currency)}</span>
        </S.Line>
        <S.Line className="total-row empty">
          <Heading2>
            {orderTotal(0, currency)}
            <span className="total-currency">{currency}</span>
          </Heading2>
          <span>
            {formatMessage({
              id: "invoice.monthly.total",
              defaultMessage: "Total monthly payment"
            })}
          </span>
        </S.Line>
      </>
    );
  }

  const start = orderDate(order.billing_period_start);
  const end = orderDate(order.billing_period_end);

  return (
    <>
      <S.Line className="billing-period">
        <strong>{`${start} - ${end}`}</strong>
        <Link
          to={{
            pathname: `/${organizationDescriptionId}/-/billing/history/${order.id}`,
            state: { from: "billing_details" }
          }}
          className="order-id"
        >
          {order.id}
        </Link>
      </S.Line>
      {Object.keys(order.components).map((component, i) => (
        <S.Line key={i} className={component}>
          <span>{order.components[component].display_title}</span>
          <span>
            {formatAsCurrency(
              order.components[component].amount,
              order.components[component].currency
            )}
          </span>
        </S.Line>
      ))}

      <S.Line className="total-row">
        <Heading2>
          {orderTotal(order.components, orderCurrency)}
          <span className="total-currency">{orderCurrency}</span>
        </Heading2>
        <span>
          {formatMessage({
            id: "invoice.monthly.total",
            defaultMessage: "Total monthly payment"
          })}
        </span>
      </S.Line>
    </>
  );
};

OrderDetailSummary.propTypes = {
  organizationDescriptionId: PropTypes.string,
  organizationId: PropTypes.string
};

export default OrderDetailSummary;
