import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import copyToClipboard from "copy-to-clipboard";

import Button from "ds/Button";
import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./ShareLink.styles";

const ShareLink = ({ link = "", onCopy = () => undefined, ...props }) => {
  const [copied, setCopied] = useState(false);

  const onClick = useCallback(
    event => {
      event.preventDefault();
      copyToClipboard(link);
      setCopied(true);
      onCopy(link);
      document.activeElement.blur();
    },
    [onCopy, link]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <AccessibleTooltip
      tooltipProps={{
        children: (
          <FormattedMessage
            id={
              copied
                ? "metrics.service.share.copied"
                : "metrics.service.share.copy"
            }
          />
        )
      }}
    >
      <Button variant="secondary" content="icon" {...props} onClick={onClick}>
        {copied ? <S.Success /> : <S.Share />}
      </Button>
    </AccessibleTooltip>
  );
};

ShareLink.propTypes = {
  link: PropTypes.string,
  onCopy: PropTypes.func
};

export default React.memo(ShareLink);
