import styled from "styled-components";
import { css, INPUT } from "Libs/themes";

import InfoDialog from "Components/InfoDialog";

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

export const Form = styled.form`
  padding: 32px;
  width: 100%;
  textarea {
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 100%;
      box-sizing: boarder-box;
    }
    > div:first-child {
      width: 33%;
      padding-right: 20px;
    }
  }
`;

export const FakeInput = styled.div`
  position: relative;
  > div {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    max-width: 100%;
    width: 264px;
    margin-bottom: 16px;
    position: relative;
    label {
      margin-bottom: 8px;
      opacity: 0.5;
    }

    div {
      cursor: not-allowed;
      border: ${props =>
        props.theme.name === "contrast" ? "1px solid #98a0ab" : "none"};
      border-radius: 2px;
      color:var(--input-night-color,var(--input-night,var(--night)));
      background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
      padding: 0 16px;
      line-height: 40px;
      height: 40px;
      width: 100%;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 600;
      span {
        font-size: 12px;
        color:var(--input-granite-color,var(--input-granite,var(--granite)));
      }
    }
  }
`;

export const Timezone = styled.div`
  display: flex;
  .select-box {
    height: auto;
  }
`;

export const Info = styled(InfoDialog)`
  margin: -2px 0 0 0;
  transform: translate(-100%);
`;
