import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import ProjectPage from "./Page";
import OverviewPage from "./overview/Page";
import IntegrationsRoutes from "./integrations/Routes";
import SettingsRoutes from "./settings/Routes";
import NewSettingsRoutes from "./new-settings/Routes";
import EnvironmentRoutes from "./environment/Routes";
import ProjectInvitationWaitingScreen from "./containers/ProjectInvitationWaitingScreen";
import PluginApp from "./app/Page";

const Routes = () => {
  const { path } = useRouteMatch();
  const { organizationId, projectId } = useParams();

  // TODO:SETTINGS This function will be no longer needed once the new settings
  // feature is finished
  function newSettings() {
    if (process.env.ENABLE_NEW_SETTINGS) {
      return (
        <Route path={`${path}/-/ss`}>
          <ProjectPage>
            <NewSettingsRoutes />
          </ProjectPage>
        </Route>
      );
    }

    return null;
  }

  return (
    <Switch>
      <Route path={`${path}/-/integrations`}>
        <ProjectPage>
          <IntegrationsRoutes />
        </ProjectPage>
      </Route>

      {
        //TODO:SETTINGS also remove this function when new settings is ready
        newSettings()
      }

      <Route path={`${path}/-/settings`}>
        <ProjectPage>
          <SettingsRoutes />
        </ProjectPage>
      </Route>

      <Route path={`${path}/-/wait`}>
        <ProjectInvitationWaitingScreen />
      </Route>

      <Route path={`${path}/-/app/:appId`}>
        <ProjectPage>
          <PluginApp
            route="/:organizationId/:projectId/"
            type="tab"
            organizationId={organizationId}
            projectId={projectId}
          />
        </ProjectPage>
      </Route>

      <Route path={`${path}/-/log/:activityId`}>
        <ProjectPage>
          <OverviewPage />
        </ProjectPage>
      </Route>

      <Route path={`${path}/:environmentId`}>
        <ProjectPage>
          <EnvironmentRoutes />
        </ProjectPage>
      </Route>

      <Route path={path}>
        <ProjectPage>
          <OverviewPage />
        </ProjectPage>
      </Route>
    </Switch>
  );
};

export default Routes;
