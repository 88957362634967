import styled, { css as styledCSS } from "styled-components";
import Heading6 from "Components/styleguide/Heading6";

import { css, SECTION, ICON, getCSSVarString } from "Libs/themes";

export const Header = styled.header`
  display: flex;
  align-items: center;
  maring-bottom: 16px;
`;

export const Name = styled(Heading6)`
  line-height: 24px;
  font-weight: 700;
  margin: 0 0 0 16px;
`;

export const Description = styled.p`
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Connected = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color:var(--section-java-color,var(--section-java,var(--java)));

  span {
    margin-right: 12px;
    svg > path {
      fill:var(--icon-java-fill,var(--icon-java,var(--java)));
    }
  }
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 15px 24px;
  ${props =>
    props.theme.name !== "dark" &&
    styledCSS`
      box-shadow: 0px 1px 4px rgba(152, 160, 171, 0.4);
    `}
  border-radius: 2px;
  margin-bottom: 24px;
  box-sizing: border-box;
  ${props =>
    props.theme.name === "dark" &&
    styledCSS`
      background-color:var(--section-ebony-light-background-color,var(--section-ebony-light,var(--ebony-light)));
    `}

  max-width: 100%;
  width: 100%;
  @media (min-width: 1280px) {
    margin-bottom: 0;
    max-width: 100%;
  }
  }
  &:hover {
    box-shadow: ${getCSSVarString(
      SECTION,
      "menu-box-shadow",
      "box-shadow"
    )} var(--section-granite-shadow-box-shadow-color,var(--section-granite-shadow,var(--granite-shadow)));
    transition: box-shadow .3s ease-in-out;
  }
`;
