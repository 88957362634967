import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import getThirdParties from "../../third_parties";

import {
  initForm,
  getGitRepositoriesIntegration,
  initGit
} from "Reducers/integration";

import useInput from "Hooks/useInput";
import { capitalize } from "Libs/utils";

import Button from "ds/Button";
import IdeField from "Components/fields/IdeField";
import Error from "Components/Error";
import InputField from "Components/fields/InputField";
import TextAreaField from "Components/fields/TextAreaField";
import Heading2 from "Components/styleguide/Heading2";
import { Skeleton, Square } from "Components/Skeleton";
import Checkbox from "ds/Checkbox";

import * as S from "./IntegrationForm.styles";
import IntegrationIcon from "../IntegrationIcon";
import AuthenticationNameBox from "../AuthenticationNameBox";
import IntegrationScopesText from "../IntegrationScopesText";
import LoadingIcon from "Icons/LoadingIcon";

const getFields = (list, parentId) => {
  return list
    .reduce((acc, cu) => {
      const obj = Object.assign({}, cu);
      if (parentId) {
        obj.id = `${parentId}/${cu.id}`;
      }

      if (cu.type === "object") {
        acc.push(getFields(cu.fields, obj.id));
      } else {
        acc.push(obj);
      }
      return acc;
    }, [])
    .flat();
};

const getValue = (path, data = []) => {
  const entries = path.split("/");
  const key = entries.shift();

  const value = data[key] || "";
  if (entries.length) {
    return getValue(entries.join("/"), value);
  }
  return value;
};

const IntegrationForm = ({ onCancel, onRemove, onSubmit, integration }) => {
  const [isFirstStepConfiguration, setIsFirstStepConfiguration] =
    useState(true);
  const [repositoryName, setRepositoryName] = useState(null);

  const dispatch = useDispatch();
  const intl = useIntl();
  const params = useParams();

  const errors = useSelector(state => state.integration?.get("errors", ""));
  const status = useSelector(state => state.integration?.get("get-git-data"));

  const gitCheckedBoxes = [
    "fetch_branches",
    "prune_branches",
    "build_pull_requests",
    "resync_pull_requests"
  ];

  useEffect(() => {
    dispatch(initForm({ id: integration?.id }));

    setTimeout(() => {
      document.getElementById("token")?.focus();
    }, 0);
  }, []);

  useEffect(() => {
    if (status === "fulfilled") {
      setIsFirstStepConfiguration(false);
    }
    return () => dispatch(initGit());
  }, [status]);

  const type = integration ? integration.type : params.type;
  const config = getThirdParties(intl).find(
    elt => elt.type === type.replace("health_", "health.")
  );

  const fields = getFields(config?.fields);

  const getInitialValues = () => {
    if (!config) return {};
    const data = integration?.data || [];

    const initialValues = fields?.reduce((acc, cu) => {
      const fieldValue = getValue(cu.id, data) || "";

      if (cu.type === "array") {
        acc[cu.id] = Array.isArray(fieldValue)
          ? fieldValue.join(cu.separator ? cu.separator : " ")
          : fieldValue;
      } else {
        acc[cu.id] = fieldValue;
      }
      return acc;
    }, {});

    const initialOptions = config.options?.reduce((acc, cu) => {
      if (gitCheckedBoxes.includes(cu)) {
        acc[cu] = true;
      } else {
        acc[cu] = data[cu] || false;
      }
      return acc;
    }, {});

    return Object.assign({}, initialValues, initialOptions);
  };

  const { inputs, handleInputChange, initialValues } = useInput(
    getInitialValues()
  );

  const loading = useSelector(state =>
    state.integration?.get("loading", false)
  );

  const gitData = useSelector(state =>
    state.integration?.getIn(["data", inputs["token"]])?.toJS()
  );

  if (!config) return <p>Error</p>;

  const handleSubmit = e => {
    e.preventDefault();
    if (config.type === "github") {
      inputs.repository = repositoryName;
    } else if (config.type === "gitlab") {
      inputs.project = repositoryName;
    }
    if (canSubmit()) onSubmit(formatData(), type);
  };

  const handleCancel = e => {
    e.preventDefault();
    onCancel();
  };

  const handleRemove = e => {
    e.preventDefault();
    onRemove();
  };

  const onChangeRepositoryName = repositoryName => {
    setRepositoryName(repositoryName.value);
  };

  const canSubmit = () => {
    if (initialValues === inputs) return false;
    for (const field of fields) {
      // sensitive fields are not return by the api
      if (integration && field.sensitive === true) continue;
      if (!!field.required && !inputs[field.id]) return false;
    }
    return true;
  };

  const formatData = () => {
    return Object.entries(inputs).reduce((acc, [key, value]) => {
      const field = fields.find(field => field.id === key);
      let formattedValue = value;

      // Add default value if field is empty
      if (formattedValue === "" && field?.default)
        formattedValue = field.default;

      // Transform string to  array
      if (field?.type === "array") {
        formattedValue = formattedValue
          .split(field.separator ? field.separator : " ")
          .map(elt => elt.trim())
          .filter(elt => elt); // remove empty element
      }

      key.split("/").reduce((acc1, cu, i, arr) => {
        if (formattedValue !== "") {
          return acc1[cu] || (acc1[cu] = arr[i + 1] ? {} : formattedValue);
        }
      }, acc);

      return acc;
    }, {});
  };

  const getIntlKey = key => {
    if (intl.messages[`integration.configure.${type}.${key}`] !== undefined)
      return `integration.configure.${type}.${key}`;
    return `integration.configure.${key}`;
  };

  const hasKey = key => {
    return intl.messages[`integration.configure.${key}`] !== undefined;
  };

  const getFieldComp = field => {
    if (field.type === "array" || field.kind === "textarea")
      return TextAreaField;
    if (field.kind === "ide") return IdeField;
    return InputField;
  };

  const handleGetRepositories = e => {
    e?.preventDefault();
    dispatch(
      getGitRepositoriesIntegration({
        baseUrl: inputs.base_url,
        token: inputs.token,
        type: config.type
      })
    );
  };

  const handleChangeToken = e => {
    e?.preventDefault();

    setIsFirstStepConfiguration(true);
    setRepositoryName(null);
  };

  const isGitIntegraton = ["github", "gitlab"].includes(config.type);

  return (
    <S.Form onSubmit={onSubmit} disabled={loading}>
      <S.Header>
        <IntegrationIcon type={config.type} />
        <Heading2>
          {intl.formatMessage({
            id: `integration.type.${config.type}`,
            defaultMessage: type
          })}
        </Heading2>
        <p>
          {intl.formatMessage(
            {
              id: getIntlKey("intro"),
              defaultMessage: "Intro"
            },
            {
              b: (...chunks) => <b>{chunks}</b>, // eslint-disable-line react/display-name
              br: <br />,
              // eslint-disable-next-line react/display-name
              a: (...chunks) => (
                <a href={config.link} target="_blank">
                  {chunks}
                </a>
              )
            }
          )}
        </p>
      </S.Header>
      {errors && (errors[""] || typeof errors === "string") && (
        <Error>{errors[""] || errors}</Error>
      )}

      {fields?.map(field => {
        const FieldComp = getFieldComp(field);
        return (
          <S.Field
            key={`field-${field.id}`}
            className={field.kind === "ide" ? "large" : ""}
          >
            {!isFirstStepConfiguration ? (
              <>
                {loading ? (
                  <Skeleton synchronize>
                    <Square height="24px" width="520px" />
                  </Skeleton>
                ) : (
                  <AuthenticationNameBox
                    name={gitData?.user.name}
                    avatarUrl={gitData?.user.avatarUrl}
                    type={type}
                    onClickChangeToken={handleChangeToken}
                  />
                )}

                <S.Dropdown
                  responsive
                  label={intl.formatMessage({
                    id: "integration.configure.field.repository.git.label",
                    defaultMessage: "Repositories"
                  })}
                  options={gitData?.repositories}
                  searchable
                  onChange={onChangeRepositoryName}
                  clearable={false}
                  fieldType={true}
                  required={true}
                  defaultText={intl.formatMessage({
                    id: "integration.configure.field.repository.git.placeholder",
                    defaultMessage: "Choose repository"
                  })}
                />
              </>
            ) : (
              <FieldComp
                id={field.id}
                name={field.id}
                label={intl.formatMessage({
                  id: getIntlKey(`field.${field.id}.label`),
                  defaultMessage: field.id
                })}
                placeholder={intl.formatMessage({
                  id: getIntlKey(`field.${field.id}.placeholder`),
                  defaultMessage: field.id
                })}
                required={!!field.required}
                value={inputs[field.id]}
                error={errors[field.id]}
                onChange={handleInputChange}
                options={{ lineNumbers: false }}
                expandable={true}
                height="280px"
              />
            )}

            {hasKey(`field.${field.id}.info.title`) && (
              <S.Info
                title={intl.formatMessage({
                  id: getIntlKey(`field.${field.id}.info.title`)
                })}
                text={intl.formatMessage({
                  id: getIntlKey(`field.${field.id}.info.text`)
                })}
                linkText={intl.formatMessage({
                  id: "learnmore"
                })}
                to={intl.formatMessage({
                  id: "links.documentation.integrations.activity_scripts"
                })}
              />
            )}
          </S.Field>
        );
      })}

      {!isGitIntegraton || !isFirstStepConfiguration ? (
        <S.Options>
          {config.options?.map(option => (
            <Checkbox
              key={`options.${option}`}
              onCheckedChanged={checked =>
                handleInputChange({
                  target: {
                    type: "checkbox",
                    checked,
                    name: option
                  }
                })
              }
              checked={inputs[option]}
              label={intl.formatMessage(
                {
                  id: getIntlKey(`option.${option}`),
                  defaultMessage: option
                },
                {
                  i: (...chunks) => <i>{chunks}</i> // eslint-disable-line react/display-name
                }
              )}
              forId={option}
              error={errors[option]}
            />
          ))}
        </S.Options>
      ) : (
        <>
          <IntegrationScopesText type={type} link={config.link} />
          <InputField
            id={"base_url"}
            name={"base_url"}
            label={intl.formatMessage({
              id: getIntlKey(`field.base_url.label`),
              defaultMessage: "base_url"
            })}
            placeholder={intl.formatMessage({
              id: getIntlKey(`field.base_url.placeholder`),
              defaultMessage: "base_url"
            })}
            required={false}
            value={inputs.base_url}
            error={errors["base_url"]}
            onChange={handleInputChange}
          />
        </>
      )}

      <S.Footer>
        {!isGitIntegraton || !isFirstStepConfiguration ? (
          <>
            {isGitIntegraton ? (
              <>
                <Button
                  className="secondary"
                  variant="secondary"
                  onClick={handleChangeToken}
                >
                  {intl.formatMessage({ id: "back" })}
                </Button>
                <Button
                  id="save"
                  onClick={handleSubmit}
                  aria-label={intl.formatMessage({
                    id: integration ? "save" : "integration.add"
                  })}
                  type="submit"
                  disabled={!repositoryName || loading}
                >
                  {capitalize(
                    intl.formatMessage({
                      id: integration ? "save" : "integration.add"
                    })
                  )}
                </Button>
              </>
            ) : (
              <Button
                id="save"
                onClick={handleSubmit}
                aria-label={intl.formatMessage({
                  id: integration ? "save" : "integration.add"
                })}
                type="submit"
                disabled={!canSubmit() || loading}
              >
                {capitalize(
                  intl.formatMessage({
                    id: integration ? "save" : "integration.add"
                  })
                )}
              </Button>
            )}
          </>
        ) : loading && !errors ? (
          <LoadingIcon />
        ) : (
          <Button
            id="continue"
            onClick={handleGetRepositories}
            aria-label={intl.formatMessage({ id: "continue" })}
            disabled={!canSubmit()}
          >
            {capitalize(intl.formatMessage({ id: "continue" }))}
          </Button>
        )}

        {onCancel && (
          <Button
            id="cancel"
            variant="secondary"
            onClick={handleCancel}
            disabled={loading}
            aria-label={intl.formatMessage({ id: "cancel" })}
          >
            {capitalize(intl.formatMessage({ id: "cancel" }))}
          </Button>
        )}
        {onRemove && (
          <Button
            id="remove"
            variant="outline"
            onClick={handleRemove}
            disabled={loading}
            aria-label={intl.formatMessage({ id: "remove" })}
          >
            {capitalize(intl.formatMessage({ id: "remove" }))}
          </Button>
        )}
      </S.Footer>
    </S.Form>
  );
};

IntegrationForm.propTypes = {
  integration: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func
};

export default IntegrationForm;
