export default {
  git: { label: "git", types: ["environment.push"] },
  merge: { label: "merge", types: ["environment.merge"] },
  sync: { label: "sync", types: ["environment.synchronize"] },
  branch: { label: "branch", types: ["environment.branch"] },
  crons: { label: "crons", types: ["environment.cron"] },
  backup: { label: "backup", types: ["environment.backup"] },
  redeploy: { label: "redeploy", types: ["environment.redeploy"] },
  certificate: {
    label: "certificate",
    types: ["environment.certificate.renewal"]
  },
  system: {
    label: "system",
    types: [
      "project.create",
      "project.modify.title",
      "project.domain.create",
      "project.domain.delete",
      "project.domain.update",
      "environment.route.create",
      "environment.route.delete",
      "environment.route.update",
      "environment.access.add",
      "environment.access.remove",
      "environment.delete",
      "environment.initialize",
      "environment.activate",
      "environment.deactivate",
      "environment.merge-pr",
      "environment.update.smtp",
      "environment.update.http_access",
      "environment.subscription.update",
      "environment.variable.delete",
      "environment.variable.update",
      "environment.variable.create"
    ]
  },
  snapshot: {
    label: "snapshot",
    types: [
      "environment.backup",
      "environment.backup.delete",
      "environment.restore"
    ]
  },
  source_operation: {
    label: "source_operation",
    types: ["environment.source-operation"]
  }
};
