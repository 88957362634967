import styled from "styled-components";
import { LINK, css, getCSSVarString } from "Libs/themes";

export default styled.a`
  background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
  color:var(--link-snow-color,var(--link-snow,var(--snow)));
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  position: absolute;
  top: -45px;
  left: 50%;
  margin-left: -100px;
  display: block;
  text-decoration: none;
  width: 200px;
  z-index: 10;
  &:hover {
    top: 0;
    outline: none;
    background-color:var(--link-skye-dark-background-color,var(--link-skye-dark,var(--skye-dark)));
  }
  &:focus {
    top: 0;
    outline: none;
    border: 1px solid var(--link-skye-dark-border-color,var(--link-skye-dark,var(--skye-dark)));
    box-shadow: 0 0 2px
      var(--link-skye-dark-box-shadow-color,var(--link-skye-dark,var(--skye-dark)));
  }
`;
