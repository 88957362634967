import styled from "styled-components";
import { css, SECTION, ICON } from "Libs/themes";

import SearchInput from "Components/SearchInput";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  justify-content: space-between;
  form {
    margin: 0;
  }

  .info-button {
    > button {
      width: auto;
      padding: 0 !important;
      border: none;
      .icon {
        margin: 0 0 0 16px;
      }
      .chevron {
        width: auto;
      }
      > .chevron {
        margin: 0 -4px 0 12px;
      }
      &:hover,
      &.open {
        .icon rect {
          fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)));
        }
        svg {
          transform: none;
        }
      }
    }
  }

  .integration-filter-window {
    width: 300px;
    white-space: nowrap;
    .checkbox-field {
      margin-bottom: 0;
    }
    h6 {
      font-size: 14px;
      color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
      font-weight: bold;
      line-height: 24px;
    }
    .checkbox-field.checked label {
      color:var(--section-skye-color,var(--section-skye,var(--skye)));
    }
  }
`;

export const Search = styled(SearchInput)`
  margin: 0 !important;
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none;
  .search-image {
    top: 4px;
    left: 4px;
  }

  &:focus-within,
  &.search-active {
    input {
      border: none;
      background: none;
    }
  }
  input:focus {
    box-shadow: none;
  }
`;
