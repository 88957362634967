import styled from "styled-components";

import { css, SECTION } from "Libs/themes";
import { CloseButton } from "ds/Button";

export const Container = styled.div`
  display: flex;
  padding: 5px 12px;
  align-items: center;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: 0px 6px 44px rgba(152, 160, 171, 0.6);
  border-radius: 2px;
  margin-bottom: 36px;
  gap: 16px;
`;

export const MessageText = styled.div`
  font-size: 13px;
  line-height: 34px;
`;

export const Close = styled(CloseButton)`
  margin-left: auto;
`;
