import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import ProjectGeneralSettingsForm from "./ProjectGeneralSettingsForm";
import Loading from "Components/Loading";
import PageMeta from "Components/PageMeta";

import { gitProjectSelector, updateProject } from "Reducers/project";
import { subscriptionSelector } from "Reducers/subscription";

import EditPlanButton from "../EditPlanButton";

import * as S from "./ProjectGeneralSettings.styles";

const ProjectGeneralSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { organizationId, projectId } = useParams();

  const project = useSelector(state =>
    gitProjectSelector(state, { organizationId, projectId })
  );

  const loading = useSelector(state => state.project?.get("loading", true));
  const subscription = useSelector(state =>
    subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project.subscription_id
    })
  );

  const onSave = data => {
    dispatch(updateProject(organizationId, projectId, data));
  };

  if (!project.id) {
    return null;
  }

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project.title} basic settings`}
        aria-live="polite"
      />
      <S.SectionHeader>
        <PageMeta title={`Settings | ${project.title}`} />
        <S.Heading id="settings-heading">
          {intl.formatMessage({
            id: "general_project_settings",
            defaultMessage: "General Project Settings"
          })}
        </S.Heading>
      </S.SectionHeader>
      <S.ButtonReposition>
        <EditPlanButton project={project} subscription={subscription} />
      </S.ButtonReposition>

      <section aria-labelledby="settings-heading">
        {loading && <Loading />}
        <ProjectGeneralSettingsForm project={project} onSave={onSave} />
      </section>
    </S.Wrapper>
  );
};

export default ProjectGeneralSettings;
