import styled from "styled-components";
import { css, ICON, SECTION, getCSSVarString, INPUT } from "Libs/themes";

export const Layout = styled.div`
  .StripeElement {
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }
  .setting-line {
    background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));
    border: 1px solid var(--section-ice-border-color,var(--section-ice,var(--ice)));
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
  .input-field,
  .stripe-card {
    width: 316px;
  }
`;

export const Disclaimer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  margin-bottom: 16px;
  .info-dialog {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-right: 12px;
    svg path {
      fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
    }
    &:hover,
    &:focus {
      svg path {
        fill:var(--icon-ebony-light-fill,var(--icon-ebony-light,var(--ebony-light)));
      }
    }
    .dialog > div {
      width: 520px;
    }
  }
`;

export const NewCardWrapper = styled.div`
  padding: 0;
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 316px 316px;
    grid-column-gap: 32px;
    width: auto;
  }
`;
