import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";

import withReducers from "Hocs/withReducers";
import { deleteIntegration } from "Reducers/integration";

import ModalConfirmDelete from "Components/ModalConfirmDelete";

import IntegrationItem from "../IntegrationItem";

import * as S from "./IntegrationCategory.styles";

const IntegrationCategory = ({ name, list }) => {
  const [modal, toggleModal] = useState({ open: false });

  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { projectId } = useParams();

  const sortedList = list.sort((a, b) => {
    const typeA = a.type.toLowerCase();
    const typeB = b.type.toLowerCase();
    return typeA < typeB ? -1 : typeA > typeB ? 1 : 0;
  });

  const integrations = useSelector(state => {
    return state.integration
      ?.getIn(["data", projectId], new Map())
      .toArray()
      .filter(int => {
        return list.find(elt => elt.type === int.type);
      });
  });

  const isConnected = elt => {
    if (elt.category !== "git") return false;
    return integrations.some(int => int.type === elt.type);
  };

  const getConnectedGit = () => {
    if (name !== "git") return false;
    return integrations.find(elt =>
      list.map(cat => cat.type).includes(elt.type)
    );
  };

  const currentGit = getConnectedGit();

  const onSelect = item => {
    if (name !== "git" || !currentGit) {
      const type = item.type.replace(".", "_");
      history.push(`setup/${type}/configure`);
    } else {
      toggleModal({ open: true, type: item.type });
    }
  };

  const onReplaceConfirm = async e => {
    if (e) e.preventDefault();
    const result = await dispatch(
      deleteIntegration({ projectId, integration: currentGit })
    );
    if (result.type === "app/integration/delete/fulfilled") {
      history.push(`setup/${modal.type}/configure`);
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>
          {intl.formatMessage({
            id: `integration.setup.categories.${name}.title`,
            defaultMessage: name
          })}
        </S.Title>
        {intl.formatMessage({
          id: `integration.setup.categories.${name}.sentence`,
          defaultMessage: " "
        })}
      </S.Header>
      <S.List>
        {sortedList.map(elt => (
          <IntegrationItem
            key={`int-${elt.type}`}
            integration={elt}
            isConnected={isConnected(elt)}
            onSelect={() => onSelect(elt)}
          />
        ))}
      </S.List>

      {currentGit && modal.open && (
        <ModalConfirmDelete
          isOpen={modal.open}
          closeModal={() => toggleModal({ open: false })}
          cancelFunction={() => toggleModal({ open: false })}
          deleteFunction={onReplaceConfirm}
          title={intl.formatMessage({
            id: `integration.setup.modal.git.title`,
            defaultMessage: "Replace your Git service"
          })}
          body={intl.formatMessage(
            {
              id: "integration.setup.modal.git.sentence"
            },
            {
              b: (...chunks) => <b>{chunks}</b>, // eslint-disable-line react/display-name
              current: intl.formatMessage({
                id: `integration.type.${currentGit?.type}`
              }),
              new: intl.formatMessage({
                id: `integration.type.${modal?.type}`
              })
            }
          )}
        />
      )}
    </S.Wrapper>
  );
};

IntegrationCategory.propTypes = {
  name: PropTypes.string.isRequired,
  list: PropTypes.array
};

export default withReducers({
  integration: () => import("Reducers/integration")
})(IntegrationCategory);
