import React, { useRef } from "react";
import PropTypes from "prop-types";

import * as S from "./Toggle.styles";

const Toggle = ({ label, checked = false, onChange = () => void 0 }) => {
  const checkbox = useRef();

  return (
    <>
      <S.Checkbox
        ref={checkbox}
        type="checkbox"
        checked={checked}
        name={label}
        onChange={onChange}
        id={label}
      />
      <S.Layout>
        <S.Label htmlFor={label}>{label}</S.Label>
        <S.Toggle checked={checked} onClick={onChange} />
      </S.Layout>
    </>
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};
export default React.memo(Toggle);
