import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

import InfoDialog from "Components/InfoDialog";
import Heading2 from "Components/styleguide/Heading2";

export const Container = styled.div`
  margin-top: ${props => (!props.notificationVisible ? "37px" : "17px")};
`;

export const HeaderSection = styled.div`
  overflow: hidden;
  display: flex;
`;

export const PageTitle = styled(Heading2)`
  flex-grow: 1;
  margin-bottom: 0;
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const HelpTextIntro = styled.div`
  font-size: 14px;
  display: flex;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  line-height: 24px;
  margin: 24px 0 40px;
`;

export const HelpInfo = styled(InfoDialog)`
  vertical-align: bottom;
`;

export const TokenMeta = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TokenName = styled.div`
  font-weight: bold;
  flex-grow: 7;
`;

export const TokenCreatedDate = styled.div`
  flex-grow: 1;
  max-width: 264px;
`;

export const TokenList = styled.div`
  .settings-item {
    box-shadow: none;
  }
`;
