import styled from "styled-components";
import { css, getCSSVarString, SECTION, INPUT } from "Libs/themes";

export const HttpAccessControlInputs = styled.div`
  margin: 0 0 24px;
  width: 100%;
  box-sizing: border-box;
  .field {
    margin-bottom: 24px;
  }
  .login-list-field {
    width: 100%;
    .row {
      width: 100%;
      margin: 0 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .current-values {
        width: 264px;
      }
      &.editing {
        align-items: flex-start;
        .field {
          margin-bottom: 0;
          width: 264px;
        }
        button {
          margin-top: 26px;
        }
      }
      button {
        margin-left: 16px;
      }
    }
  }
  .access-ips {
    display: flex;
    flex-direction: row;
    align-items: top;
    textarea {
      min-width: 50%;
      width: auto;
    }
  }
  .row {
    line-height: 40px;
  }
  input {
    height: 40px;
    line-height: 40px;
  }
  h6 {
    margin-top: 24px;
  }
`;

export const IpTextArea = styled.textarea`
  width: 200px;
  min-height: 50px;
  resize: none;
  font-size: 14px;
  padding: 15px;
  background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
  color:var(--input-night-color,var(--input-night,var(--night)));
  border: 1px solid var(--input-ice-border-color,var(--input-ice,var(--ice)));
  &:focus {
    border: 1px solid var(--section-skye-dark-border-color,var(--section-skye-dark,var(--skye-dark)));
    box-shadow: 0 0 2px
      var(--section-skye-dark-box-shadow-color,var(--section-skye-dark,var(--skye-dark)));
  }
`;
