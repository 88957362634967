import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import Heading6 from "Components/styleguide/Heading6";
import Label from "Components/fields/Label";
import Button from "ds/Button";

export const Layout = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 24px 32px;
  border-radius: 4px;
`;

export const EnvironmentPermissions = styled.div`
  hr {
    margin: 0;
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }
`;

export const EnvironmentHeaders = styled.div`
  display: flex;
  align-items: center;
  label + label {
    width: 33%;
  }
`;

export const PermissionWrapper = styled.div`
  display: flex;
  align-items: center;
  label + label {
    width: 33%;
  }
  label {
    font-size: 13px;
    ${semiBoldAlias()};
    height: 72px;
    line-height: 56px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
`;

export const InputLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const TableLine = styled.hr`
  margin-top: 0;
`;

export const EnvironmentTypeLabel = styled(Label)`
  min-width: 200px;
`;

export const TitlePermissionLabel = styled(Label)`
  max-width: 299px;
`;

export const PermissionLabel = styled(Label)`
  max-width: 200px;
  background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));
  border-radius: 2px;
  padding: 10px 18px;
  max-height: 20px !important;
  cursor: not-allowed;
  font-size: 15px !important;
  line-height: 20px !important;
  font-style: normal !important;
  font-weight: normal !important;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const Title = styled(Heading6)`
  ${semiBoldAlias()};
  font-size: 14px;
  line-height: 24px;
`;

export const EnvironmentsListWrapper = styled.div`
  margin-left: 66px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  h6 + div {
    margin-left: 12px;
  }
`;

export const ResendButton = styled(Button)`
  padding: 0px 8px;

  outline: none !important;
  border: 0 !important;

  &:hover,
  &:active,
  &:focus {
    outline: none !important;
    border: 0 !important;
    margin: 0 !important;
  }
`;
