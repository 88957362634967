import styled, { css as styledCSS } from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import {
  css,
  getCSSVarString,
  HEADER,
  SECTION,
  MENU,
  MENU_ITEM
} from "Libs/themes";

export const Layout = styled.div`
  padding: 10px 8px 10px 8px;
  background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
  box-shadow: var(--menu-box-shadow,var(--box-shadow))
    var(--menu-ebony-shadow-box-shadow-color,var(--menu-ebony-shadow,var(--ebony-shadow)));
  border-radius: 2px;
  min-width: 260px;
  z-index: 9999;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Text = styled.div`
  font: normal 600 13px/18px "Open Sans";
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 16px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
`;

export const TableRow = styled.tr`
  height: 24px;
  font-size: 13px;
  line-height: 18px;
  color:var(--section-night-color,var(--section-night,var(--night)));
  background-color: transparent;
  > :first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  > :last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  td:nth-child(1) {
    width: 1%;
    padding-left: 8px;
    ${semiBoldAlias};
  }

  td:nth-child(2) {
    width: 1%;
    padding-left: 8px;
    padding-right: 8px;
  }

  td:nth-child(3) {
    text-align: left;
    white-space: nowrap;
    padding-right: 8px;
  }

  ${({ isActive }) =>
    isActive &&
    styledCSS`
      background-color:var(--menu-item-skye-light-background-color,var(--menu-item-skye-light,var(--skye-light)));
    `};
`;
