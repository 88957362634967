import React, { useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import withReducers from "Hocs/withReducers";
import { addIntegration, getIntegrations } from "Reducers/integration";

import { CloseButton } from "ds/Button";
import Heading2 from "Components/styleguide/Heading2";
import Heading3 from "Components/styleguide/Heading3";
import { domainMatches } from "Libs/utils";

import IntegrationsList from "./containers/IntegrationsList";
import IntegrationForm from "../components/IntegrationForm";
import Stepper from "./components/Stepper";

import * as S from "./Setup.styles";

const Setup = () => {
  const { path } = useRouteMatch();
  const { organizationId, projectId } = useParams();

  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const lastEdited = useSelector(state => state.integration?.get("lastEdited"));
  const status = useSelector(state => state.integration?.get("status"));

  useEffect(() => {
    dispatch(getIntegrations({ projectId }));
  }, [projectId]);

  useEffect(() => {
    if (status === "added")
      history.push(
        `/${organizationId}/${projectId}/-/integrations/${lastEdited}`
      );
  }, [status]);

  const onSubmit = (inputs, type) => {
    // type use health. on the api object but we use health_ on the url
    if (
      type.toLowerCase() === "github" &&
      domainMatches("github.com", inputs.base_url)
    ) {
      //Pretend the user did not enter any input if the domain matches github.com
      delete inputs.base_url;
    }

    dispatch(
      addIntegration({
        project,
        type: type.replace("health_", "health."),
        data: inputs
      })
    );
  };

  return (
    <S.Layout>
      <S.Wrapper>
        <S.Header>
          <Heading2>
            {intl.formatMessage({
              id: "integration.setup.title",
              defaultMessage: "add integration"
            })}
          </Heading2>
          <Stepper />
          <CloseButton to={`/${organizationId}/${projectId}/-/integrations`}>
            {intl.formatMessage({ id: "cancel" })}
          </CloseButton>
        </S.Header>

        <S.Content>
          <Switch>
            <Route path={`${path}/:type/configure`}>
              <>
                <Heading3>
                  {intl.formatMessage({
                    id: "integration.setup.configure",
                    defaultMessage: "Configure your integration"
                  })}
                </Heading3>

                <IntegrationForm onSubmit={onSubmit} />
              </>
            </Route>
            <Route path={`${path}/`}>
              <Heading3>
                {intl.formatMessage({
                  id: "integration.setup.select",
                  defaultMessage: "Select integration"
                })}
              </Heading3>

              <IntegrationsList />
            </Route>
          </Switch>
        </S.Content>
      </S.Wrapper>
    </S.Layout>
  );
};

export default withReducers({
  integration: () => import("Reducers/integration")
})(Setup);
