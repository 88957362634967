import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const DisplayName = styled.div`
  display: flex;

  font-size: 15px;
  line-height: 24px;

  ${semiBoldAlias};

  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const Tag = styled.div`
  margin: 0 4px 0 16px;
  padding: 2px 8px;
  border-radius: 2px;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 20px;
  background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;
