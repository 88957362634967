import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import activityTemplates from "../../../../../../../common/components/activities/activityTemplates";
import { activeActivitySelector } from "Reducers/metrics/activities.selector";

import * as S from "./ActivityTooltip.styles";

const ActivityTooltip = () => {
  const activity = useSelector(activeActivitySelector);

  const [icon, title] = useMemo(() => {
    if (!activity) return [];

    const template =
      activityTemplates[activity.type] || activityTemplates.unknown;

    const title =
      activity.type === "environment.push" ||
      activity.type === "environment.merge" ? (
        <>
          <b>{activity.data.payload.commits[0].sha.slice(0, 7)}</b>&nbsp;-{" "}
          {activity.data.payload.commits[0].message}
        </>
      ) : (
        template.title({ activity })
      );

    return [template.icon, title];
  }, [activity]);

  if (!activity) return null;

  return (
    <S.Layout>
      <S.Activity>
        <S.ActivityIcon>{icon}</S.ActivityIcon>
        <S.ActivityTitle>{title}</S.ActivityTitle>
      </S.Activity>
    </S.Layout>
  );
};

ActivityTooltip.propTypes = {
  activity: PropTypes.object.isRequired
};

export default React.memo(ActivityTooltip);
