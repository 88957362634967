import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import { css, SECTION } from "Libs/themes";

import Heading2 from "Components/styleguide/Heading2";
import { CloseButton } from "ds/Button";
import Redirect from "Components/Redirect";

import Billing from "./containers/Billing";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 32px;
  box-sizing: border-box;
  color:var(--section-night-color,var(--section-night,var(--night)));
  z-index: 9;
  margin: -30px auto 0;
  position: relative;
  box-sizing: border-box;
`;

const HeadingContainer = styled.div`
  h2 {
    float: left;
  }
  .close {
    float: right;
  }
`;

const BillingPage = ({ intl }) => {
  const [redirectUrl, setRedirectUrl] = useState(false);
  const getRedirect = () => {
    // If there is a orgin URI ...
    const params = new URLSearchParams(window.location.search);
    if (params.get("origin")) {
      return params.get("origin");
    }
    return "/";
  };

  const cancel = () => {
    const redirectUrl = getRedirect();
    setRedirectUrl(redirectUrl);
  };
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }
  return (
    <Layout className="col-10">
      <HeadingContainer>
        <Heading2>Billing details</Heading2>
        <CloseButton onClick={cancel()}>
          {intl.formatMessage({ id: "cancel" })}
        </CloseButton>
      </HeadingContainer>

      <Billing
        plan={{
          value: 1,
          size: "development",
          label: "D",
          title: "Development",
          sku: "PLATFORM-ENVIRONMENT-DEVELOPMENT"
        }}
        cancel={cancel}
      />
    </Layout>
  );
};

BillingPage.propTypes = {
  intl: PropTypes.object
};

export default injectIntl(BillingPage);
