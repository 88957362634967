import styled from "styled-components";
import { getCSSVarString, HEADER } from "Libs/themes";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  overflow: auto;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 40px;
  flex-direction: column;
  h2 {
    color: var(--header-night-color,var(--header-night,var(--night))) !important;
    margin-bottom: 16px;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Cards = styled.div`
  a + a {
    margin-top: 32px;
  }
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (min-width: 900px) {
    button + button {
      margin-top: 0;
      margin-left: 32px;
    }
  }
`;
