import moment from "moment";

const TIME_FORMAT = "HH:mm";
const DATE_FORMAT = "MMM D";
const FULL_DATE_FORMAT = "MMM D, YYYY";

export const getDateFormat = (from, to) => {
  const momentFrom = moment(from);
  const momentTo = moment(to);

  const sameDay = momentFrom.isSame(momentTo, "day");
  const sameYear = momentFrom.isSame(momentTo, "year");

  const fromTime = momentFrom.format(TIME_FORMAT);
  const toTime = momentTo.format(TIME_FORMAT);
  const fromDate = momentFrom.format(DATE_FORMAT);

  const fromFullDate = momentFrom.format(FULL_DATE_FORMAT);
  const toFullDate = momentTo.format(FULL_DATE_FORMAT);

  if (sameDay) {
    return `${fromTime} - ${toTime}, ${toFullDate}`;
  }

  if (sameYear) {
    return `${fromTime} ${fromDate} - ${toTime} ${toFullDate}`;
  }

  return `${fromTime} ${fromFullDate} - ${toTime}, ${toFullDate}`;
};

export const getNowFormat = () => moment().format(FULL_DATE_FORMAT);
