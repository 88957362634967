import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { ENVIRONMENT_TYPES } from "Constants/constants";

import Label from "Components/fields/Label";
import Checkbox from "ds/Checkbox";
import Loading from "Components/Loading";
import InfoDialog from "Components/InfoDialog";
import InvitationConfirmationModal from "../../../../../../../../common/components/EnvironmentTypeAccessForm/InvitationConfirmationModal";
import EnvironmentList from "../../../../../../../../common/components/EnvironmentList";
import Button from "ds/Button";
import Sticker from "ds/Stickers";

import { capitalize } from "Libs/utils";

import * as S from "./styles";

const InvitationDetails = ({
  invitation,
  onRevoke,
  onResendInvitation,
  environments
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const intl = useIntl();

  const status = useSelector(state => state.invitation?.get("status", "idle"));

  useEffect(() => {
    if ((status === "resent") & modalIsOpen) setModalIsOpen(false);
  }, [status]);
  const invitationEnvironments = invitation.environments || [];

  const getEnvironments = environmentType =>
    environments
      .filter(e => e.type === environmentType)
      .map(e => e.name)
      .toArray();

  return (
    <S.Layout>
      <Sticker
        priority="information"
        variant="button"
        text={intl.formatMessage(
          { id: "invitation.info" },
          {
            name: invitation?.owner?.display_name,
            date: momentjs(invitation.createdAt).format("MMMM D, YYYY")
          }
        )}
        button={
          status === "resent" ? (
            <span className="link-style">
              {intl.formatMessage({ id: "invitation.resend_success" })}
            </span>
          ) : (
            <S.ResendButton className="link-style" onClick={onResendInvitation}>
              {intl.formatMessage({ id: "invitation.resend" })}
            </S.ResendButton>
          )
        }
      >
        <InvitationConfirmationModal
          isOpen={modalIsOpen}
          email={invitation.email}
          closeModal={() => setModalIsOpen(false)}
        />
        {status === "pending" && <Loading />}
      </Sticker>

      <S.Title>
        {intl.formatMessage({
          id: "access.project.permissions"
        })}
      </S.Title>

      <S.InputLayout className="field">
        <Checkbox
          label={`${intl.formatMessage({ id: "project_admin" })}`}
          checked={invitation.role === "admin"}
          disabled={true}
          aria-disabled
          forId="super-user"
        />
      </S.InputLayout>

      <S.EnvironmentPermissions className="environment-permissions">
        {!!invitationEnvironments.length && (
          <>
            <S.TitleWrapper>
              <S.Title>
                {intl.formatMessage({
                  id: "invitation.environment_types.permission"
                })}
              </S.Title>
              <InfoDialog
                text={intl.formatMessage({
                  id: "access.environment_types.description"
                })}
                linkText={intl.formatMessage({
                  id: "learnmore"
                })}
                to={intl.formatMessage({
                  id: "links.documentation.users"
                })}
                align="center"
              />
            </S.TitleWrapper>
            <S.EnvironmentHeaders className="environment-headers">
              <S.EnvironmentTypeLabel>
                {intl.formatMessage({ id: "invitation.environment_types" })}
              </S.EnvironmentTypeLabel>
              <S.TitlePermissionLabel>
                {intl.formatMessage({ id: "invitation.environment.type" })}
              </S.TitlePermissionLabel>
              <Label>
                {intl.formatMessage({
                  id: "environments"
                })}
              </Label>
            </S.EnvironmentHeaders>
            <S.TableLine />

            {ENVIRONMENT_TYPES.map(envType => {
              const p = invitationEnvironments.find(p => p.type === envType);
              return (
                <>
                  <S.PermissionWrapper key={p?.type || envType}>
                    <S.EnvironmentTypeLabel>
                      {p?.type || envType}
                    </S.EnvironmentTypeLabel>
                    <S.PermissionLabel>
                      {capitalize(
                        p?.role || intl.formatMessage({ id: "no-access" })
                      )}
                    </S.PermissionLabel>
                    <S.EnvironmentsListWrapper>
                      <EnvironmentList
                        environmentsName={getEnvironments(p?.type || envType)}
                      />
                    </S.EnvironmentsListWrapper>
                  </S.PermissionWrapper>
                  <hr />
                </>
              );
            })}
          </>
        )}
      </S.EnvironmentPermissions>

      <Button
        id={`invitation-revoke-${invitation.id}`}
        variant="outline"
        onClick={onRevoke}
      >
        {intl.formatMessage({ id: "invitation.revoke" })}
      </Button>
    </S.Layout>
  );
};

InvitationDetails.propTypes = {
  invitation: PropTypes.object,
  environments: PropTypes.object,
  onRevoke: PropTypes.func,
  onResendInvitation: PropTypes.func,
  onCancel: PropTypes.func
};

export default InvitationDetails;
