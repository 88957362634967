import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import useToggle from "./useToggle";
import {
  getIntegrations,
  integrationsSelector,
  integrationsLoadingSelector
} from "Reducers/integration";
import {
  OverriddenLink,
  Sublist,
  SublistButton
} from "./ProjectSettingsMenu.styles";

const IntegrationsMenu = ({ isActive }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { projectId, organizationId } = useDecodedParams();

  const integrations = useSelectorWithUrlParams(integrationsSelector);
  const integrationsLoading = useSelector(integrationsLoadingSelector);
  const [expanded, { toggle }] = useToggle(true);

  useEffect(() => {
    dispatch(getIntegrations({ projectId }));
  }, [projectId]);

  // TODO:SETTINGS Change ss to settings when ready
  const rootRoute = `/${organizationId}/${projectId}/-/ss`;
  const integrationsRoute = rootRoute + "/integrations";

  if (integrationsLoading === undefined || integrationsLoading) {
    return <span>Loading</span>;
  }

  return integrations.length === 0 ? (
    <OverriddenLink isActive={isActive} to={integrationsRoute}>
      Integrations
    </OverriddenLink>
  ) : (
    <>
      <SublistButton active={isActive} onClick={toggle}>
        Integrations
      </SublistButton>
      {expanded && (
        <Sublist>
          {integrations.map(i => (
            <OverriddenLink to={`${integrationsRoute}/${i.id}`} key={i.id}>
              <li>
                {intl.formatMessage({ id: `integration.type.${i.type}` })}
              </li>
            </OverriddenLink>
          ))}
        </Sublist>
      )}
    </>
  );
};

IntegrationsMenu.propTypes = {
  isActive: PropTypes.bool
};

export default withReducers({
  integration: () => import("Reducers/integration")
})(IntegrationsMenu);
