import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as s from "./styles";

import organizationIntroductionIllustration from "Images/organization-introduction-illustration.svg";

import Link from "Components/styleguide/Link";

const Introduction = () => {
  const intl = useIntl();

  return (
    <s.Layout>
      <s.LeftPane>
        <s.SectionTitle>
          <FormattedMessage id="settings.introducing_organizations" />
        </s.SectionTitle>
        <s.TextAndLink>
          <s.Text>
            <FormattedMessage id="settings.organization_intruduction_text" />
          </s.Text>
          <s.LearnMore>
            <Link
              href={intl.formatMessage({
                id: "links.documentation.organizations"
              })}
            >
              <FormattedMessage id="learnmore" />
            </Link>
          </s.LearnMore>
        </s.TextAndLink>
      </s.LeftPane>
      <s.IntroductionIllustration
        src={organizationIntroductionIllustration}
        alt="organization introduction illustration"
      />
    </s.Layout>
  );
};
export default Introduction;
