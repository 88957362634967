import styled from "styled-components";
import { css, SECTION, HEADER } from "Libs/themes";

import { semiBoldAlias } from "Libs/theme";

import { Label, Value } from "Components/LabeledInfo/styles";
import ModalBody from "Components/ModalBody";
import LabelField from "Components/fields/Label";

export const ModalBodyWrapper = styled(ModalBody)`
  ${Value} {
    margin-top: 16px;
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
    text-transform: none;
  }

  ${Label} {
    margin-bottom: 16px;
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 18px;
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }

  h3 {
    margin-top: 0;
  }
`;

export const InformationText = styled.div`
  font-size: 15px;
  line-height: 24px;

  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;

export const LabelWithInfo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 16px;
`;

export const CustomLabel = styled(LabelField)`
  margin-bottom: 0px;
  margin-right: 12px;
  font-size: 14px;
  line-height: 24px;
  ${semiBoldAlias({ fontWeight: "bold" })};

  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;

export const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -4px;
  font-style: normal;
  font-weight: normal;

  font-size: 14px;
  line-height: 24px;

  width: max-content;
`;
