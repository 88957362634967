import styled, { css as styledCSS } from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import { css, LINK, SECTION, getCSSVarString } from "Libs/themes";

import Cell from "../Cell";

export const Subject = styled(Cell)`
  position: relative;
`;

export const Project = styled(Cell)`
  ${props =>
    props.theme.name === "dark" &&"color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))"};
`;

export const Layout = styled.tr`
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;

  :not(:last-child) {
    box-shadow: inset 0px -1px 0px var(--section-periwinkle-grey-box-shadow-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  }

  &:focus {
    outline: none;
    ${props =>
      props.theme.name === "contrast" &&
      styledCSS`
        box-shadow: inset 0 0 0 1px ${getCSSVarString(
          SECTION,
          "skye",
          "box-shadow-color"
        )}};
      `};
  }
`;

export const SubjectLink = styled(RouterLink)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0;
  right: 32px;
  top: 17px;
  text-decoration: none;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 15px;
  font-weight: normal;

  &:focus,
  &:hover {
    outline: none;
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
`;
