import styled from "styled-components";
import { css, SECTION, getCSSVarString } from "Libs/themes";

export const VoucherSS = styled.div`
  ${props =>
    props.isExpanded &&
    `box-shadow: ${getCSSVarString(
      SECTION,
      "box-shadow",
      "box-shadow"
    )} var(--section-granite-shadow-box-shadow-color,var(--section-granite-shadow,var(--granite-shadow)))`};
`;

export const VoucherHeaderSS = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: ${props => (props.active ? "0" : "16px")};
  font-size: 13px;
  line-height: 22px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  background: ${props =>
    props.active
      ?"var(--section-ice-background-color,var(--section-ice,var(--ice)))":"var(--section-snow-background-color,var(--section-snow,var(--snow)))"};
  ${props => props.clickable && "cursor: pointer"};

  & strong {
    margin-left: 12px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  }
`;

export const CodeSS = styled.span`
  flex-grow: 1;
  min-width: 35%;
  font-size: 15px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));

  & strong {
    font-weight: 700;
  }
`;

export const VoucherHeaderItemSS = styled.span`
  ${props => props["sm-hidden"] && "display: none"};
  flex-grow: 1;

  @media (min-width: 48rem) {
    display: inline;
  }
`;

export const NoOrdersSS = styled.span`
  display: inline-block;
  padding: 4px 16px;
  line-height: 13px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
  border-radius: 2px;
`;

export const VoucherPanelSS = styled.div`
  margin-bottom: 16px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
`;

export const VoucherPanelSectionSS = styled.section`
  padding: 12px 0;
  margin: 0 24px;
  font-size: 14px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  border-top: 1px var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));

  header {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;

    @media (min-width: 48rem) {
      display: none;
    }
  }

  main {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;

    span {
      flex-grow: 1;
      flex-basis: 25%;
    }
  }
`;

export const VoucherPanelTableHeaderSS = styled.div`
  display: none;

  @media (min-width: 48rem) {
    display: flex;
    padding: 12px 0;
    margin: 0 24px;
    font-size: 13px;
    color:var(--section-granite-color,var(--section-granite,var(--granite)));

    & span {
      flex-grow: 1;
      flex-basis: 25%;
    }
  }
`;

export const OrderStatusSS = styled.span`
  display: none;

  @media (min-width: 48rem) {
    display: block;
    text-transform: capitalize;
  }
`;

export const VoucherMoneyPanelSS = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  margin: 0 24px;
  font-size: 13px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));

  & strong {
    margin-left: 12px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  }
`;
