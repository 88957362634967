import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Link } from "ds/Button";

import { getSubscriptionEditUrl } from "Libs/utils";

const EditPlanButton = ({ project, subscription }) => {
  const intl = useIntl();
  const location = useLocation();

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  const shouldHideButton = process.env.ACCOUNT_PAGES_DISABLED || !subscription;

  if (shouldHideButton || !subscriptionEditUrl) {
    return null;
  }

  return (
    <Link
      to={{
        pathname: subscriptionEditUrl.url,
        state: {
          from: "project_settings",
          url: location.pathname
        }
      }}
      variant="primary"
      target={subscriptionEditUrl.external ? "_blank" : null}
    >
      {intl.formatMessage({ id: "edit_plan" })}
    </Link>
  );
};

EditPlanButton.propTypes = {
  project: PropTypes.object,
  subscription: PropTypes.object
};

export default EditPlanButton;
