import styled from "styled-components";

import { css, HEADER, LINK, SECTION, getCSSVarString } from "Libs/themes";

export const Wrapper = styled.div`
  display: flex;
  width: 338px;
  height: 80px;
  padding: 18px 24px 20px 20px;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: 2px;
  transition: all 0.2s;
  background-color:var(--setup-card-section-background-color,var(--section-snow-background-color,var(--section-snow,var(--snow))));
  align-items: center;
  justify-content: space-between;
  box-shadow: ${props =>
    props.theme.name === "dark"
      ? "none"
      : "0 3px 8px rgba(152, 160, 171, 0.2)"};
  &:hover,
  &:focus-within {
    cursor: pointer;
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? `var(--section-box-shadow,var(--box-shadow))
        var(--section-ebony-shadow-box-shadow-color,var(--section-ebony-shadow,var(--ebony-shadow)))`
        : "0 6px 24px rgba(152, 160, 171, 0.4)"};
    outline: none;
    border-radius: 4px;
    .link-style,
    + button {
      display: inline-block;
    }
  }
  &:last-child {
    margin-bottom: 32px;
  }
  @media (min-width: 1280px) {
    width: 100%;
  }
  @media (min-width: 1440px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  max-width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
`;

export const Name = styled.span`
  display: inline-block;
  width: 100%;
  max-width: 100%;
  max-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color:var(--header-night-color,var(--header-night,var(--night)));
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DetailsLink = styled.span`
  z-index: 1;
  width: 46px;
  margin-top: 7px;
  font-size: 14px;
  color:var(--link-skye-color,var(--link-skye,var(--skye)));
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Logo = styled.div`
  display: block;
  height: 40px;
  width: 40px;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
    filter: ${props =>
      props.isWhite && props.theme.name === "dark"
        ? "brightness(0) invert(1)"
        : "none"};
  }
`;
