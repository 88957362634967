import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import InfoDialog from "Components/InfoDialog";
import Heading3 from "Components/styleguide/Heading3";

export const ScreenshotWrapper = styled.div`
  display: flex;
  max-width: 100%;
  height: 180px;
  margin: 0;
  border-radius: 2px;
`;

export const Details = styled.div`
  padding: 20px 20px 28px 32px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Org = styled.p`
  margin: 7px 0 16px;
  font-size: 12px;
  line-height: 18px;
  ${semiBoldAlias};
  text-transform: uppercase;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const ProjectName = styled(Heading3)`
  margin: 0 0 14px;
  line-height: 24px;
`;

export const Region = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  ${semiBoldAlias};
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const RegionSuffix = styled.span`
  margin-left: 5px;
  font-size: 12px;
  ${semiBoldAlias};
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const Tag = styled.div`
  marginL 0 4px 0 0;
  padding: 2px 8px;
  border-radius: 2px;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 20px;
  background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const Trial = styled(Tag)`
  background-color:var(--section-light-pink-background-color,var(--section-light-pink,var(--light-pink)));
  color:var(--section-wine-color,var(--section-wine,var(--wine)));
`;

export const WarningDialog = styled(InfoDialog)`
  &,
  &:hover {
    svg {
      > path {
        fill: #eda900 !important;
      }
    }
  }
`;

export const ProjectCardLayout = styled.div`
  max-width: 100%;
  margin: 0;
  cursor: pointer;
  border-radius: 2px;
  width: 100%;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  .row {
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    &:hover .cell.cell-project {
      color:var(--section-skye-color,var(--section-skye,var(--skye)));
    }
  }

  &:hover,
  &:focus {
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 6px 44px #01001A"
        : "0 6px 44px rgba(152, 160, 171, 0.6)"};
    ${ProjectName} {
      color:var(--section-skye-color,var(--section-skye,var(--skye)));
    }
  }

  a {
    text-decoration: none;
    display: block;
  }
`;
