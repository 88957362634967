import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

import { semiBoldAlias } from "Libs/theme";

const EmptyCard = styled.div`
  text-align: center;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  line-height: 18px;
  border-radius: 2px;
  ${semiBoldAlias};
`;

export default EmptyCard;
