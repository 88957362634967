import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { getCSSVarString, ICON } from "Libs/themes";

import ActionDropdown from "Components/ActionDropdown";
import IconFilter from "Icons/IconFilter";
import Heading6 from "Components/styleguide/Heading6";

import Show from "./Show";
import SortBy from "./SortBy";

const Dropdown = ({ sortByOptions, visibilityOptions, onFilterChange }) => {
  return (
    <Fragment>
      <ActionDropdown
        id="environments-filter"
        label="Filter"
        ariaLabel="Filter"
        icon={
          <span className="icon">
            <IconFilter color={"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"} />
          </span>
        }
        iconAfter={true}
        withArrow={false}
        withClose={true}
      >
        <Heading6 className="title">
          <FormattedMessage id="tickets.filter" />
        </Heading6>
        <hr />
        <Show
          options={visibilityOptions}
          setValue={value => onFilterChange({ type: "SHOW", value })}
        />
        <hr />
        <SortBy
          options={sortByOptions}
          setValue={value => onFilterChange({ type: "SORT", value })}
        />
      </ActionDropdown>
    </Fragment>
  );
};

Dropdown.propTypes = {
  visibilityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      isActive: PropTypes.bool
    })
  ),
  sortByOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      isActive: PropTypes.bool
    })
  ),
  onFilterChange: PropTypes.func,
  theme: PropTypes.object
};

export default Dropdown;
