import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActivitiesOverlayVisible: false
};

const settingsSlice = createSlice({
  name: "metricsSettings",
  initialState,
  reducers: {
    toggleActivitiesOverlay: state => {
      state.isActivitiesOverlayVisible = !state.isActivitiesOverlayVisible;
    }
  }
});

export const { toggleActivitiesOverlay } = settingsSlice.actions;

export default settingsSlice.reducer;
