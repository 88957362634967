import styled from "styled-components";
import { breakpoints } from "Libs/theme";
import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.section`
  width: 100%;
`;

export const SectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled(Heading2)`
  font-size: 18px;
  @media (max-width: ${breakpoints[1]}) {
    font-size: 15px;
    margin-bottom: 16px;
  }
`;

export const ButtonReposition = styled.div`
  position: absolute;
  top: -90px;
  right: 0;
  z-index: 1;

  @media (min-width: ${breakpoints[1]}) {
    top: 0;
    right: 0;
  }
`;
