import React from "react";
import proptypes from "prop-types";

import * as S from "./styles";

const Action = ({ title, text, link, linkText }) => (
  <S.Layout onClick={e => e.preventDefault()}>
    <S.SectionTitle>{title}</S.SectionTitle>
    <S.Body>
      <S.Text>{text}</S.Text>
      <S.LearnMore>
        <S.DisabledLink href={link}>{linkText}</S.DisabledLink>
      </S.LearnMore>
    </S.Body>
  </S.Layout>
);

Action.propTypes = {
  title: proptypes.string,
  text: proptypes.string,
  link: proptypes.string,
  linkText: proptypes.string
};

export default Action;
