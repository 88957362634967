export const MetricsOutputEvents = {
  refreshToken: "refreshToken",
  ready: "ready",
  error: "error"
};

export const MetricsInputEvents = {
  refreshToken: "refreshToken",
  startPolling: "startPolling",
  stopPolling: "stopPolling",
  init: "init",
  request: "request"
};

export const ActivityInputEvents = {
  request: "activityRequest",
  startPolling: "startPolling",
  stopPolling: "stopPolling",
  refreshToken: "refreshToken",
  init: "init"
};

export const ActivityOutputEvents = {
  ready: "activityReady",
  refreshToken: "refreshToken",
  error: "activityError"
};
