import styled from "styled-components";
import { semiBoldAlias, breakpoints } from "Libs/theme";
import { css, SECTION, HEADER } from "Libs/themes";

export const BillingDetailsContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  max-width: ${breakpoints[6]};

  @media (min-width: ${breakpoints[1]}) {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
`;

export const Aside = styled.aside`
  margin-top: 32px;
  @media (min-width: ${breakpoints[1]}) {
    flex-basis: 300px;
    margin: 0;
  }

  @media (min-width: ${breakpoints[2]}) {
    flex-basis: 25%;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
  }
`;

export const Content = styled.section`
  flex-direction: column;
  flex-basis: 100%;

  @media (min-width: ${breakpoints[1]}) {
    min-width: 350px;
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 32px;
  }

  @media (min-width: ${breakpoints[2]}) {
    flex-basis: 60%;
    flex-grow: 2;
  }
`;

export const PaymentMethodsSection = styled.section``;

export const InvoicesSection = styled.section``;

export const BillingDetailsSection = styled.section``;

export const SectionBox = styled.div`
  border-radius: 4px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 32px;
  box-sizing: border-box;
  flex: 1 1 0;
  width: 100%;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints[0]}) {
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (min-width: ${breakpoints[1]}) {
    margin-right: 32px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (min-width: ${breakpoints[2]}) {
    margin-bottom: 0;
  }

  > h3 {
    margin-top: 0;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  > p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  strong {
    display: block;
    font-size: 15px;
    line-height: 24px;
    ${semiBoldAlias};
  }
  > span {
    display: block;
    font-size: 13px;
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }
  .field-card-number {
    .label {
      opacity: 1;
    }
  }
`;

export const SideSectionBox = styled(SectionBox)`
  padding: 24px;
`;

export const HeaderLayout = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  align-items: baseline;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  height: 32px;

  @media (min-width: ${breakpoints[1]}) {
    flex-direction: row;
    margin: 32px 0 16px;
    }
  }

  h3 {
    margin: 0;
    font-size: 15px;

    @media (min-width: ${breakpoints[0]}) {
      font-size: 18px;
    }
  }

  span {
    margin-left: 13px;
    font-size: 15px;
    &:first-letter {
      text-transform: uppercase;
    }

    @media(min-width: 768px) {
      margin-left: 0;
    }
  }
  > :nth-last-child(2) {
    flex-grow: 1;
  }
  a {
    text-decoration: none;
    font-weight: 600;
    margin-bottom: 16px;
  }
  button,
  button:active {
    margin: 0;
  }
  &.new-payment {
    h3 {
      flex-grow: 0;
    }
    span {
      flex-grow: 1;
    }
  }
`;

export const HeaderLayoutChangePayment = styled(HeaderLayout)`
  height: auto;
  flex-direction: column;
  position: relative;
  margin-top: 32px;
  margin-bottom: 24px;
  @media (min-width: ${breakpoints[1]}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints[3]}) {
    flex-direction: row;
  }

  span {
    margin-left: 0;

    @media (min-width: ${breakpoints[3]}) {
      margin-left: 16px;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 18px;
  }
`;

export const PaymentTypeWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  > div {
    margin: 0 16px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
