import React from "react";
import { Redirect } from "react-router-dom";

import client from "Libs/platform";

const Page = () => {
  client.wipeToken();

  if (process.env.POST_LOGOUT_URL) {
    window.location.href = process.env.POST_LOGOUT_URL;
    return;
  }

  return <Redirect push to="/" />;
};

export default Page;
