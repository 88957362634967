import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import Page from "./Page";
import { isEnvironmentTypeProjectSelector } from "Reducers/project";
import GeneralSettings from "./GeneralSettings";
import EnvironmentTypeAccess from "./environmentType/Page";
import Access from "./access/Page";
import Certificates from "./certificates/Page";
import DeployKey from "./deployKey/Page";
import Domains from "./domains/Routes";
import Integrations from "./integrations/Routes";
import Variables from "./variables/Routes";

const Routes = () => {
  const { organizationId, projectId } = useParams();
  const { path } = useRouteMatch();

  let isEnvironmentTypeProject = useSelector(state =>
    isEnvironmentTypeProjectSelector(state, { organizationId, projectId })
  );

  return (
    <Page>
      <Switch>
        <Route exact path={path}>
          <GeneralSettings />
        </Route>
        <Route path={`${path}/access`}>
          {process.env.ENVIRONMENT_TYPE_ENABLED && isEnvironmentTypeProject ? (
            <EnvironmentTypeAccess
              projectId={projectId}
              organizationId={organizationId}
            />
          ) : (
            <Access projectId={projectId} organizationId={organizationId} />
          )}
        </Route>
        <Route path={`${path}/certificates`}>
          <Certificates />
        </Route>
        <Route path={`${path}/domains`}>
          <Domains />
        </Route>
        <Route path={`${path}/deploykey`}>
          <DeployKey />
        </Route>
        <Route path={`${path}/integrations`}>
          <Integrations />
        </Route>
        <Route path={`${path}/variables`}>
          <Variables />
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
