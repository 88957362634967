import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ToggleSwitch from "Components/ToggleSwitch";

import * as S from "../styles";

const IndexingSetting = ({
  environment,
  onIndexingChange,
  restrict_robots
}) => {
  const intl = useIntl();

  return (
    <ToggleSwitch
      id="environment-is-hidden-switch"
      title={intl.formatMessage({ id: "is_hidden" })}
      description={
        <S.FieldDescription>
          <S.EnvironmentHiddenInfoDialog
            title={intl.formatMessage({
              id: "is_hidden.info.title"
            })}
            text={intl.formatMessage({
              id: "is_hidden.info.description"
            })}
            to={intl.formatMessage({
              id: "links.documentation.inactive_environment"
            })}
            linkText={intl.formatMessage({
              id: "is_hidden.info.link"
            })}
          />{" "}
          {intl.formatMessage({
            id: "is_hidden.description"
          })}
        </S.FieldDescription>
      }
      onClick={onIndexingChange}
      value={restrict_robots}
      enabled={
        environment.is_main &&
        environment.hasPermission &&
        environment.hasPermission("#edit")
      }
    />
  );
};

IndexingSetting.propTypes = {
  environment: PropTypes.object,
  onIndexingChange: PropTypes.func,
  restrict_robots: PropTypes.bool
};

export default IndexingSetting;
