import styled from "styled-components";
import { css, getCSSVarString, SECTION } from "Libs/themes";
import { breakpoints } from "Libs/theme";

export const Table = styled.table`
  width: 598px;
  font-size: 14px;
  font-weight: 600;
  border-collapse: collapse;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));

  tr {
    border-bottom: 1px solid
      var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    height: 42px;
  }

  tr th {
    font-weight: 600;
  }

  thead tr,
  tfoot tr {
    border-bottom: none;
  }

  td,
  th {
    padding: 9px 0;
  }

  thead {
    text-align: center;
    padding-bottom: 16px;
  }

  tfoot td,
  tfoot th {
    padding-top: 16px;
  }

  thead td,
  thead th {
    padding-bottom: 16px;
  }

  th {
    text-align: left;
  }

  th[scope="row"] {
    font-weight: 400;
    min-width: 254px;
  }

  td {
    width: 100px;
    text-align: center;
  }

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .bold {
    font-weight: 800;
  }

  .granite {
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }

  @media (max-width: ${breakpoints[2]}) {
    width: 100%;
  }
`;
