import styled from "styled-components";

import ActionDropdown from "Components/ActionDropdown";

import { makePalette } from "Libs/theme";
import { css, ICON, SECTION } from "Libs/themes";

const theme = makePalette(palette => ({
  color: {
    default: palette.slate,
    dark: palette.periwinkleGrey,
    contrast: palette.ebonyLight
  }
}));

export const Dropdown = styled(ActionDropdown)`
  margin-right: 16px;

  .tooltip section {
    padding: 16px;
  }

  &.info-button > button {
    border: none !important;
    width: auto;
  }

  .icon svg [fill] {
    fill: ${theme.color};
  }
  :hover .icon svg,
  .open .icon svg {
    transform: none;
    [fill] {
      fill:var(--icon-skye-dark-fill,var(--icon-skye-dark,var(--skye-dark)));
    }
  }

  :hover .text,
  .open .text {
    color:var(--section-skye-color,var(--section-skye,var(--skye)));
  }

  .text {
    margin-right: 16px;
    color: ${theme.color};
  }

  .environments-filter-window {
    width: auto;
  }

  .checkbox-field {
    margin-bottom: 12px;
    label {
      margin-bottom: 0;
      padding-left: 29px;
      white-space: nowrap;
    }
  }

  .checkbox-field:last-child {
    margin-bottom: 0;
    label {
      margin-bottom: 0;
    }
  }
`;
