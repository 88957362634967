import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export const Layout = styled.div`
  margin-bottom: 32px;
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;
  }
  .settings-item {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    box-shadow: none;
    &.open {
      box-shadow: ${props =>
        props.theme.name === "dark"
          ? "0px 6px 44px #01001A"
          : "0px 10px 20px rgba(75, 97, 128, 0.08)"};
    }
  }
  .setting-line {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  }
  .action {
    box-sizing: border-box;
    flex: 0 0 70px;
    margin-right: -12px;
    padding-left: 8px;
  }
  @media (max-width: 1100px) {
    .action {
      flex: 0 0 60px;
    }
  }
`;
