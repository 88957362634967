import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Map } from "immutable";

import { hasSafeRole, getTrial, interpolateURL } from "Libs/utils";

import {
  deleteSubscription,
  loadSubscription,
  subscriptionSelector,
  loadingSelector
} from "Reducers/organization/subscription";
import withReducers from "Hocs/withReducers";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import ErrorBoundary from "Components/ErrorBoundary";
import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import ListGroup from "Components/ListGroup";
import Description from "Components/fields/Description";
import { BackLink, Link } from "ds/Button";
import Sticker from "ds/Stickers";
import ModalConfirmDelete from "Components/ModalConfirmDelete";

import ProjectPlanForm from "./containers/ProjectPlanForm";
import * as S from "./styles";
import { organizationProfileSelector } from "Reducers/organization/profile";

const PlanDetail = () => {
  const { organizationId: organizationNameId, subscriptionId } = useParams();
  const [hasPaymentSource, setPaymentSource] = useState();
  const [modalDeleteOpen, openModalDelete] = useState(false);
  const [errors, setErrors] = useState();

  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector(state => state.app?.get("me", new Map())?.toJS());
  const { username } = user;
  const organizations = useSelector(state =>
    state.organization?.get("data", new Map())
  );

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationNameId
    })
  );
  const orgId = organization?.id;

  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId: organizationNameId,
      subscriptionId
    });
  });
  const { project_id: projectId, organization_id } = subscription;

  const organizationProfile = useSelector(state =>
    organizationProfileSelector(state, { organizationId: organizationNameId })
  );

  const { currency } = organizationProfile?.data || "";

  useEffect(() => {
    let isCanceled = false;
    const getPaymentSource = async () => {
      if (hasPaymentSource !== undefined) return;
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const ps = await client.getOrganizationPaymentSource(orgId);
      if (isCanceled) {
        return;
      }
      setPaymentSource(!!ps.type);
    };
    if (!hasSafeRole(user.roles)) getPaymentSource();

    return () => (isCanceled = true);
  }, []);

  useEffect(() => {
    if (subscriptionId && organization)
      dispatch(
        loadSubscription({
          organizationId: organizationNameId,
          id: subscriptionId
        })
      );
  }, [subscriptionId, organization]);

  const showBanner = (() => {
    if (hasSafeRole(user.roles)) return false;
    if (
      !getTrial(user, { organization_id }, organizations.toJS()) &&
      !hasPaymentSource
    )
      return true;

    const { current_trial } = user.data;
    if (
      current_trial?.active &&
      moment(current_trial?.expiration).isSameOrBefore(moment())
    )
      return true;

    return false;
  })();

  const isOnTrial = useMemo(() => {
    if (hasSafeRole(user.roles)) return false;
    return (
      getTrial(user, { organization_id }, organizations?.toJS()) &&
      !hasPaymentSource
    );
  }, [organization_id, organizations]);

  const canEdit = subscription?.hasLink && subscription.hasLink("update");
  const isLoadingSubscription = useSelector(loadingSelector, false);
  return (
    <ContentLayout className="settings-content">
      <ErrorBoundary>
        <S.HeaderWrapper>
          <BackLink to={`/${organizationNameId}/-/billing/plan`} from="plans" />

          <S.HeaderContent hasBackButton>
            <Heading2 id="plan-heading">{subscription.project_title}</Heading2>
            {canEdit && (
              <S.ButtonWrapper>
                <S.DeleteButton
                  onClick={() => {
                    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
                      window.location.href = interpolateURL(
                        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
                        { projectId }
                      );
                    } else {
                      openModalDelete(true);
                    }
                  }}
                  variant="outline"
                >
                  {intl.formatMessage({
                    id: "settings.plan.project.delete.button",
                    defaultMessage: "Delete project"
                  })}
                </S.DeleteButton>
              </S.ButtonWrapper>
            )}
          </S.HeaderContent>
        </S.HeaderWrapper>

        <S.StickerLayout>
          {showBanner && (
            <Sticker
              variant="button"
              priority="normal"
              className="sticker-message"
              button={
                <Link variant="primary" to={`/${organizationNameId}/-/billing`}>
                  {intl.formatMessage({
                    id: "settings.plan.banner.button",
                    defaultMessage: "Add billing details"
                  })}
                </Link>
              }
              text={intl.formatMessage({
                id: "settings.plan.banner.trial",
                defaultMessage:
                  "To upgrade your plan you need to add a payment method to your billing details."
              })}
            />
          )}
          {errors && (
            <Sticker className="sticker-message" priority="critical">
              {errors}
            </Sticker>
          )}
        </S.StickerLayout>

        <ListGroup>
          {subscription && (
            <ProjectPlanForm
              subscription={subscription}
              organizationNameId={organizationNameId || ""}
              organizationProfile={organizationProfile}
              isLoadingSubscription={
                isLoadingSubscription == "idle" ? false : isLoadingSubscription
              }
              setErrors={setErrors}
              isOnTrial={isOnTrial}
              currency={currency}
            />
          )}
        </ListGroup>

        <Description>
          {intl.formatMessage({
            id: "settings.plan.downgrade_warning",
            defaultMessage:
              "* Note that you can only upgrade storage at this time. Downgrades can be requested by submitting a"
          })}{" "}
          <a
            href={
              process.env.CUSTOM_SUPPORT_URL
                ? process.env.CUSTOM_SUPPORT_URL
                : `/-/users/${username}/tickets`
            }
          >
            {intl.formatMessage({ id: "support_ticket" })}
          </a>
          .
        </Description>

        <ModalConfirmDelete
          title="Delete project"
          deleteFunction={() => {
            dispatch(deleteSubscription({ subscription }));
          }}
          isOpen={modalDeleteOpen}
          closeModal={() => openModalDelete(false)}
          itemId={`subscription-${subscription?.id}`}
          size="medium"
          body={
            <>
              <p>
                {intl.formatMessage(
                  {
                    id: "settings.plan.project.delete.confirm",
                    defaultMessage:
                      "Are you sure you want to delete your project <b>{sub}</b>?"
                  },
                  {
                    sub: subscription?.project_title,
                    // eslint-disable-next-line react/display-name
                    b: txt => <strong>{txt}</strong>
                  }
                )}
              </p>
              <p>
                {intl.formatMessage({
                  id: "settings.plan.project.delete.info",
                  defaultMessage:
                    "Please note that deleting your project is irreversible and all data associated with this project will be deleted, including backups. Lastly, at the end of the month you will be charged for any remaining project costs."
                })}
              </p>
            </>
          }
        />
      </ErrorBoundary>
    </ContentLayout>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  organizationSubscription: () => import("Reducers/organization/subscription")
})(PlanDetail);
