import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDecodedParams from "Hooks/useDecodedParams";
import { loadDeployment } from "Reducers/environment/deployment";

import { environmentServicesSelector } from "Reducers/metrics/services.selector";

const useBetterServices = () => {
  const dispatch = useDispatch();
  const { organizationId, projectId, environmentId } = useDecodedParams();

  const selector = useCallback(
    state =>
      environmentServicesSelector(
        state,
        organizationId,
        projectId,
        environmentId
      ),
    [organizationId, projectId, environmentId]
  );

  const services = useSelector(selector);

  useEffect(() => {
    dispatch(loadDeployment(organizationId, projectId, environmentId));
  }, []);

  return services;
};

export default useBetterServices;
