import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

import { semiBoldAlias } from "Libs/theme";

export const WorkersLayout = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 32px;
  margin-top: 24px;
`;

export const WorkersTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 15px;
`;

export const WorkersGrid = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
`;

export const WorkerdGridHeader = styled.span`
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  ${semiBoldAlias};
`;

export const SshURLList = styled.div`
  grid-column: auto;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
