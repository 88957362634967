import styled from "styled-components";

import { css, SECTION, INPUT } from "Libs/themes";

export const Checkbox = styled.input`
  display: none;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-right: 24px;
`;

export const Toggle = styled.div`
  width: 32px;
  height: 18px;
  border-radius: 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  transition: 0.2s background-color;

  :after {
    content: "";
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    height: 14px;
    width: 14px;
    border-radius: 100%;
    display: block;
    transform: translateX(0);
    transition: 0.3s transform;
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    box-shadow: 0px 1px 4px rgba(152, 160, 171, 0.4);
  }

  ${({ checked }) =>
    checked &&
    `
      background-color:var(--input-skye-background-color,var(--input-skye,var(--skye)));
      :after {
        transform: translateX(100%);
      }
    `};
`;

export const Label = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 0px;
  color:var(--input-slate-color,var(--input-slate,var(--slate)));
`;
