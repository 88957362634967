import styled from "styled-components";
import { breakpoints, semiBoldAlias } from "Libs/theme";
import { css, getCSSVarString, SECTION, LINK } from "Libs/themes";

export const BREAKPOINT = `(min-width: ${breakpoints[1]})`;

export const Sidebar = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  width: 272px;
  min-width: 200px;
  margin-left: -40px;
  padding: 16px 32px 16px 40px;
  min-height: calc(100vh - 96px);

  @media (min-width: 1940px) {
    padding-left: 60px;
  }
`;

export const BackLinkContainer = styled.div`
  position: relative;
  padding-bottom: 18px;

  a {
    ${semiBoldAlias};
    font-size: 13px;
    color:var(--link-slate-color,var(--link-slate,var(--slate)));
    text-decoration: none;
  }

  svg {
    transform: rotate(90deg) scale(70%);
  }
`;

export const MenuTriggerContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
  z-index: 2;

  h3 {
    margin: 0;
  }

  @media ${BREAKPOINT} {
    h3 {
      margin-bottom: 18px;
    }
  }
`;

export const MobileMenuContainer = styled.div`
  margin-top: 16px;
  z-index: 1;
`;

export const ExpandedMobileMenu = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  padding-top: 90px;
`;

export const Submenu = styled.ul`
  color:var(--link-slate-color,var(--link-slate,var(--slate)));
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 2.5;
`;

export const MenuHeader = styled.section`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;

  h1 {
    font-size: 14px;
    ${semiBoldAlias};
  }
`;

export const MenuChildrenContainer = styled.section`
  --color: ${props =>
    props.expanded
      ?"var(--link-skye-color,var(--link-skye,var(--skye)))":"var(--link-slate-color,var(--link-slate,var(--slate)))"};
  --border-color: ${props =>
    props.expanded
      ?"var(--link-skye-border-color,var(--link-skye,var(--skye)))":"var(--link-periwinkle-grey-border-color,var(--link-periwinkle-grey,var(--periwinkle-grey)))"};
  border-top: 1px solid var(--border-color);
  margin: 24px;
  padding-bottom: 16px;
  font-size: 14px;
  ${semiBoldAlias};
  color: var(--color);

  a {
    ${semiBoldAlias};
  }

  @media ${BREAKPOINT} {
    margin: 0;
  }
`;
