import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fromJS } from "immutable";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import CreationModal from "./CreationModal";

import withReducers from "Hocs/withReducers";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { capitalize } from "Libs/utils";
import { ENVIRONMENT_TYPES } from "Constants/constants";

import {
  getAccesses,
  deleteAccess,
  editAccess,
  updateAccess,
  projectAccessSelector,
  editedProjectAccessSelector,
  projectAccessStatusSelector,
  projectAccessErrorsSelector,
  isLoadingSelector,
  projectAccessesSelector
} from "Reducers/project/access";

import {
  loadEnvironmentTypes,
  updateEnvironmentTypeAccess,
  addEnvironmentTypeAccess
} from "Reducers/environment/type";

import {
  addInvitation,
  deleteInvitation,
  editInvitation,
  setCreationModalOpen as setCreationModalOpenAction,
  getInvitations,
  invitationEditedSelector,
  invitationErrorsSelector,
  invitationStatusSelector,
  invitationsSelector,
  creationModalSelector
} from "Reducers/invitation";

import { gitProjectSelector } from "Reducers/project";
import { environmentsSelector } from "Reducers/environment";

import { AddButton } from "ds/Button";
import Error from "Components/Error";
import Heading2 from "Components/styleguide/Heading2";
import Loading from "Components/Loading";
import PageMeta from "Components/PageMeta";
import PageDescription from "Components/PageDescription";
import SettingLine from "Components/SettingLine";
import UserIcon from "Components/icons/UserIcon";
import LabeledInfo from "Components/LabeledInfo";

import EnvironmentAccessListFields from "../EnvironmentAccessListFields";
import ModalConfirmDelete from "Components/ModalConfirmDelete";

import InvitationAlert from "../../../../../../common/components/InvitationAlert";
import InvitationLine from "./InvitationLine";
import InvitationResendModal from "../../../../../../common/components/InvitationResendModal";
import InvitationRevokeModal from "../../../../../../common/components/InvitationRevokeModal";

import * as S from "./styles";

const DEFAULT_ENVIRONMENTS_TYPES = {
  development: false,
  production: false,
  staging: false
};

const ProjectAccessList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { organizationId, projectId } = useParams();

  const [invitAlert, setInvitAlert] = useState({ isOpen: false });
  const [modal, setModal] = useState({ isOpen: false });

  const projectAccesses = useSelector(state =>
    projectAccessesSelector(state, { projectId, organizationId })
  );

  const openCreationModal = useSelector(creationModalSelector);

  const setOpenCreationModal = v => {
    dispatch(editAccess({ id: null }));
    dispatch(setCreationModalOpenAction(v));
  };

  const editedAccess = useSelector(editedProjectAccessSelector);
  const isLoading = useSelector(isLoadingSelector);
  const statusAccess = useSelector(projectAccessStatusSelector);
  const errorsAccess = useSelector(projectAccessErrorsSelector);

  const editedInvitation = useSelector(invitationEditedSelector);
  const invitations = useSelector(state =>
    invitationsSelector(state, { projectId, organizationId })
  );
  const statusInvitation = useSelector(invitationStatusSelector);
  const errorsInvitation = useSelector(invitationErrorsSelector);

  const project = useSelector(state =>
    gitProjectSelector(state, { organizationId, projectId })
  );

  const accesses = useSelector(state =>
    projectAccessSelector(state, { projectId, organizationId })
  );

  const environments = useSelectorWithUrlParams(environmentsSelector);

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  useEffect(() => {
    if (
      ["added", "updated", "deleted"].includes(statusAccess) ||
      ["added", "deleted"].includes(statusInvitation)
    ) {
      cancel();
    }
  }, [statusAccess, statusInvitation]);

  useEffect(() => {
    if (project?.id) {
      dispatch(getAccesses({ organizationId, project }));
      dispatch(loadEnvironmentTypes(organizationId, project.id));
    }
  }, [project]);

  useEffect(() => {
    dispatch(getInvitations({ organizationId, projectId }));
  }, [projectId]);

  const cancel = () => {
    handleEditInvitation();
    handleEditAccess();
  };

  const addNewAccess = () => {
    if (process.env.CUSTOM_USER_MANAGEMENT_URL) {
      window.location.href = process.env.CUSTOM_USER_MANAGEMENT_URL;
      return;
    }

    setOpenCreationModal(true);
  };

  const handleEditAccess = (id = null) => {
    dispatch(editInvitation({ id: null }));
    dispatch(editAccess({ id }));
  };

  const updateProjectAccess = (access, role) => {
    dispatch(updateAccess({ organizationId, project, access, role }));
  };

  const handleCreateInvitation = (
    email,
    accesses = {},
    superUser,
    force = false
  ) => {
    if (checkUserExists(email)) return;

    dispatch(
      addInvitation({
        organizationId,
        projectId,
        email,
        environmentTypes: Object.values(accesses)
          .filter(a => a.role !== "no_access")
          .map(a => ({
            type: a.environmentType,
            role: a.role
          })),
        role: superUser && "admin",
        force
      })
    );
  };

  const checkUserExists = email => {
    const access = projectAccesses.find(elt => {
      return elt.find(access => access.getUser().email === email);
    });

    const invit = invitations.find(elt => elt.email === email);

    if ((access && access.size) || invit) {
      setOpenCreationModal(false);
      setInvitAlert({
        isOpen: true,
        user: access?.valueSeq().get(0)?.getUser() || invit
      });
      return true;
    }
    return false;
  };

  const handleEditInvitation = (id = null) => {
    dispatch(editAccess({ id: null }));
    dispatch(
      editInvitation({
        id
      })
    );
  };

  const handleRevokeInvit = invitation => {
    if (!invitation) return;
    dispatch(
      deleteInvitation({
        organizationId,
        projectId,
        invitation
      })
    );
    handleCloseModal();
  };

  const handleResendInvit = invitation => {
    if (!invitation) return;
    dispatch(
      addInvitation({
        organizationId,
        projectId,
        email: invitation.email,
        environments: invitation.environments,
        role: invitation.role,
        force: true,
        invitation
      })
    );
    handleCloseModal();
  };

  const openInvitModal = (invitation, type) => {
    setModal({ open: true, type, invitation });
  };

  const handleCloseModal = () => {
    setModal({ open: false, type: null, access: null, invitation: null });
  };

  const closeCreationModal = () => {
    setOpenCreationModal(false);
  };

  const updateEnvironmenTypesAccess = (environmentTypeId, access) => {
    dispatch(
      updateEnvironmentTypeAccess(
        organizationId,
        projectId,
        environmentTypeId,
        access
      )
    );
  };

  const addEnvironmenTypesAccess = (environmentTypeId, access) => {
    dispatch(
      addEnvironmentTypeAccess(
        organizationId,
        projectId,
        environmentTypeId,
        access
      )
    );
  };

  const showAddUserButton =
    process.env.ENABLE_USER_MANAGEMENT &&
    project?.hasPermission &&
    project.hasPermission("#manage-access");

  if (!project) return null;

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project?.title} project-level access settings`}
        aria-live="polite"
      />
      <PageMeta title={`Access | ${project?.title}`} />

      {invitAlert.isOpen && (
        <InvitationAlert
          project={project.title}
          user={invitAlert.user}
          onClose={() => setInvitAlert({ isOpen: false, user: null })}
        />
      )}

      {showAddUserButton && (
        <AddButton css={{ float: "right" }} onClick={addNewAccess} />
      )}

      <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
        {intl.formatMessage({ id: "access.title" })}
      </Heading2>
      <PageDescription>
        {intl.formatMessage({ id: "project.accesslist.description" })}
      </PageDescription>

      <section aria-labelledby="settings-heading">
        {errorsAccess?.code === 409 && (
          <Error>
            {intl.formatMessage({ id: "project.accesslist.already_exists" })}
          </Error>
        )}

        {invitations?.entrySeq().map(([id, invitation]) => (
          <InvitationLine
            key={`invitation-${id}-line`}
            invitation={invitation}
            isEdited={editedInvitation === id}
            onEdit={() =>
              handleEditInvitation(editedInvitation === id ? null : id)
            }
            environments={environments}
            onResendInvitation={() => openInvitModal(invitation, "resend")}
            onRevoke={() => openInvitModal(invitation, "revoke")}
          />
        ))}

        {projectAccesses?.entrySeq().map(([userId, environmentTypes]) => {
          const access = accesses?.find(a => a.user === userId);

          if (!access) {
            return false;
          }

          const enviromentTypesArray = environmentTypes?.entrySeq();
          return (
            <SettingLine
              key={`user-${userId}`}
              icon={
                <UserIcon
                  size={30}
                  backgroundColor="black"
                  color="white"
                  padding={10}
                />
              }
              info={
                <S.InfoLayout>
                  <S.DisplayName>
                    {access?.getUser().display_name}
                  </S.DisplayName>
                  <span className="email">{access?.getUser().email}</span>
                  {/*access.hasPermission && access.hasPermission("#edit")*/}
                  {access?.role === "admin" ? (
                    <S.RoleWrapper>
                      <S.LabeledInfoWrapper>
                        <LabeledInfo label="project" value="Admin" />
                      </S.LabeledInfoWrapper>
                    </S.RoleWrapper>
                  ) : (
                    <S.RoleWrapper>
                      {ENVIRONMENT_TYPES.map(envType => {
                        const env = enviromentTypesArray.find(
                          e => e[0] === envType
                        );
                        const access = env && env[1];
                        return (
                          <S.LabeledInfoWrapper key={envType}>
                            <LabeledInfo
                              label={envType}
                              value={capitalize(
                                access?.role ||
                                  intl.formatMessage({ id: "no-access" })
                              )}
                            />
                          </S.LabeledInfoWrapper>
                        );
                      })}
                    </S.RoleWrapper>
                  )}
                </S.InfoLayout>
              }
              addNewTitle={intl.formatMessage({
                id: "project.accesslist.add_user",
                defaultMessage: "Add user"
              })}
              isOpen={editedAccess === userId}
              openText={
                access?.hasPermission && access.hasPermission("#edit")
                  ? intl.formatMessage({ id: "edit" })
                  : intl.formatMessage({ id: "view" })
              }
              onClick={() =>
                handleEditAccess(editedAccess === userId ? null : userId)
              }
            >
              {editedAccess === userId && (
                <EnvironmentAccessListFields
                  access={access}
                  environmentTypes={environmentTypes}
                  enabled={
                    access?.hasPermission && access.hasPermission("#edit")
                  }
                  environments={environments}
                  onCancel={cancel}
                  onDelete={() =>
                    setModal({ open: true, type: "delete", access })
                  }
                  projectErrors={errorsAccess}
                  updateProjectAccess={updateProjectAccess}
                  updateEnvironmenTypesAccess={updateEnvironmenTypesAccess}
                  addEnvironmenTypesAccess={addEnvironmenTypesAccess}
                />
              )}
            </SettingLine>
          );
        })}

        <ModalConfirmDelete
          isOpen={modal.open && modal.type === "delete"}
          closeModal={handleCloseModal}
          deleteFunction={() =>
            dispatch(
              deleteAccess({
                organizationId,
                projectId,
                access: modal.access
              })
            )
          }
          itemType="user"
          itemName={modal.access?.getUser().display_name}
          itemId={modal.access?.id}
        />

        <InvitationRevokeModal
          isOpen={modal.open && modal.type === "revoke"}
          closeModal={handleCloseModal}
          email={modal.invitation?.email}
          revokeInvitation={() => handleRevokeInvit(modal.invitation)}
        />

        <InvitationResendModal
          isOpen={modal.open && modal.type === "resend"}
          closeModal={handleCloseModal}
          email={modal.invitation?.email}
          resendInvitation={() => handleResendInvit(modal.invitation)}
        />

        <CreationModal
          isOpen={openCreationModal}
          closeModal={closeCreationModal}
          environments={environments}
          environmentTypes={fromJS(DEFAULT_ENVIRONMENTS_TYPES)}
          onSave={handleCreateInvitation}
          errors={errorsInvitation}
          status={statusInvitation}
        />

        {isLoading && <Loading />}
      </section>
    </S.Wrapper>
  );
};

export default withReducers({
  invitation: () => import("Reducers/invitation"),
  enviromentType: () => import("Reducers/environment/type"),
  project: () => import("Reducers/project"),
  projectAccess: () => import("Reducers/project/access")
})(ProjectAccessList);
