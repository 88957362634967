import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const Layout = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 18px;

  ${semiBoldAlias};

  color:var(--section-slate-color,var(--section-slate,var(--slate)));

  & > div {
    margin-right: 2px;
  }
`;
