import styled from "styled-components";

import { css, SECTION, ICON, INPUT } from "Libs/themes";

export const Wrapper = styled.section`
  padding: 32px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 14px;

  &:focus {
    outline: none;
  }

  h2 {
    margin-bottom: 16px;
    line-height: 33px;

    span {
      font-size: 14px;
      color:var(--section-granite-color,var(--section-granite,var(--granite)));
      font-weight: normal;
    }
  }

  p,
  ul {
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    font-size: 14px;
  }
  p {
    line-height: 24px;
    margin: 0 0 5px;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const NoCopyCode = styled.pre`
  padding: 12px 24px;
  line-height: 19px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-style: italic;
  margin-bottom: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const CodeExample = styled.div`
  position: relative;
  margin: 29px 0 20px;

  .copyable-area {
    position: relative;
    pre {
      white-space: pre;
      margin: 0;
    }
    .link-style {
      position: absolute;
      top: -36px;
      right: 0;
      button.copy-to-clipboard {
        padding: 0;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 0;
          path {
            fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
          }
        }
        span {
          display: none;
        }
        &:hover {
          background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
          svg path {
            fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)));
          }
        }
      }
      .copied-message {
        height: 32px;
        line-height: 32px;
      }
    }
    &.single-line {
      .copy-content-wrapper {
        height: 32px;
      }

      button {
        width: 32px;
        height: 32px;
      }

      pre {
        line-height: 32px !important;
      }
    }
  }
`;
