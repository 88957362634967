import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import * as Icon from "Components/icons";
import * as S from "./Specificities.styles";

import GetThirdParties from "../../../third_parties";

const Git = ({ integration }) => {
  const intl = useIntl();

  const config = GetThirdParties(intl).find(
    elt => elt.type === integration.type
  );

  const getSetting = kind => {
    const Ico = integration[kind] ? Icon.Tick : Icon.Minus;
    return (
      <S.SettingsLine key={`l-${kind}`} isActivate={integration[kind]}>
        <Ico />
        {intl.formatMessage(
          {
            id: `integration.git.${kind}`,
            defaultMessage: `Setting ${kind}`
          },
          {
            name: (
              <span>
                {intl.formatMessage({
                  id: `integration.type.${integration.type}`
                })}
              </span>
            )
          }
        )}
      </S.SettingsLine>
    );
  };

  const repository = (() => {
    let path = [];
    path.push(integration.base_url ? integration.base_url : config?.base_url);
    if (integration.project) path.push(integration.project);
    if (integration.repository) path.push(integration.repository);
    return path.join("/");
  })();

  return (
    <S.Wrapper>
      {repository && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.git.repository",
              defaultMessage: "Repository"
            })}
          </label>
          <a href={repository} target="_blank" rel="noopener noreferrer">
            {repository}
          </a>
        </p>
      )}

      {integration.base_url && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.git.base_url",
              defaultMessage: "Enterprise base URL"
            })}
          </label>
          <a
            href={integration.base_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {integration.base_url}
          </a>
        </p>
      )}

      <dl>
        <dt>
          {intl.formatMessage({
            id: "integration.git.settings",
            defaultMessage: "Project settings"
          })}
        </dt>

        {config?.options.map(kind => getSetting(kind))}
      </dl>
    </S.Wrapper>
  );
};

Git.propTypes = {
  integration: PropTypes.object
};

export default Git;
