import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectIsActivitiesOverlayVisible } from "Reducers/metrics/settings.selector";
import { toggleActivitiesOverlay } from "Reducers/metrics/settings.slice";

import Toggle from "../Toggle";

const ToggleActivites = () => {
  const dispatch = useDispatch();

  const isActivitiesOverlayVisible = useSelector(
    selectIsActivitiesOverlayVisible
  );

  const onChange = useCallback(() => dispatch(toggleActivitiesOverlay()), []);

  return (
    <Toggle
      label="Deployments"
      checked={isActivitiesOverlayVisible}
      onChange={onChange}
    />
  );
};

export default React.memo(ToggleActivites);
