import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import CreateOrganization from "./create/Page";
import Page from "./Page";

const NewOrganizationRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`}>
        <Page>
          <CreateOrganization />
        </Page>
      </Route>
    </Switch>
  );
};

export default NewOrganizationRoute;
