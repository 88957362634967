import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

import InputField from "Components/fields/InputField";

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    border-radius: 0 0 4px 4px;
    margin-bottom: 16px;
  }
`;

export const FormWrapper = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: 264px;
  width: 100%;
`;

export const Note = styled.dl`
  margin: 0 0 24px 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));

  dt {
    margin-bottom: 5px;
  }
  dd {
    margin: 0;
    padding: 0;
    &:before {
      content: "• ";
      margin: 0 0 0 5px;
    }
  }
`;

export const Input = styled(InputField)`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  padding-top: 16px;
`;
