import styled, { css as styledCSS } from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, getCSSVarString, TAB } from "Libs/themes";

export const Name = styled.span`
  flex: 1;
  ${semiBoldAlias};
  font-size: 15px;
  line-height: 33px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  text-transform: capitalize;
`;

export const Id = styled.span`
  align-self: center;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const Item = styled.article`
  display: flex;
  width: 100%;
  padding: 0 24px 0 16px;
  height: 48px;
  align-items: center;
  background: ${({ theme, isActive }) => {
    if (theme.name === "dark" && isActive)
      return"var(--tab-ebony-background-color,var(--tab-ebony,var(--ebony)))";
    return"var(--tab-snow-background-color,var(--tab-snow,var(--snow)))";
  }};
  border-radius: 2px;
  box-sizing: border-box;
  flex-direction: row;
  cursor: pointer;
  svg {
    margin-right: 16px;
    opacity: 0.75;
  }

  & + article {
    margin-top: 8px;
  }

  &:hover {
    background-color:var(--side-bar-hover-tab-background-color,var(--tab-snow-background-color,var(--tab-snow,var(--snow))));
    box-shadow: 0 4px 8px
      ${props =>
        props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};
  }

  ${({ isActive }) =>
    isActive &&
    styledCSS`
      box-shadow: 0 4px 8px
        ${props =>
          props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};

      svg {
        opacity: 1;
      }
      ${Name} {
        color:var(--tab-skye-color,var(--tab-skye,var(--skye)));
      }
    `};
`;
