import parseDimensions from "./parseDimensions";
import sortAlphabetically from "./sortAlphabetically";

export const mergeSameTimestampData = data => {
  const point = {
    timestamp: new Date(data[0].timestamp)
  };

  for (let i = 0; i < data.length; i++) {
    let { value, name, instance } = data[i];
    let key;

    if (name === "max") {
      key = "max";
      value = Math.max(value, point.max || 0);
    } else if (name === "average.value") {
      key = "average@value";
    } else if (name === "average.percentage") {
      key = "average@percentage";
    } else {
      key = `${instance?.split(".")[1]}@${name}`;
    }

    point[key] = value;
  }

  return point;
};

export const groupByTimestamp = metrics => {
  const list = {};

  for (let i = 0; i < metrics.length; i++) {
    const point = metrics[i];
    const group = list[point.timestamp] || [];
    group.push(point);
    list[point.timestamp] = group;
  }

  return Object.values(list);
};

export const explodePoint = point => {
  if (!point?.values) {
    return [];
  }
  const explodedPoints = [];
  const dimensions = parseDimensions(point.dimension);

  for (let i = 0; i < point.values.length; i++) {
    const value = point.values[i];
    if (value) {
      const [type, ...name] = value.info.name.split(".");
      const isMax = name.includes("max");

      let pointValue = 0;
      if (isMax) {
        pointValue = value.value.max;
      } else {
        const sum = value.value.sum || 0;
        const count = value.value.count || 1;
        pointValue = sum / count;
      }

      explodedPoints.push(
        Object.assign(
          {
            type: type,
            name: name.join("."),
            value: pointValue,
            timestamp: point.timestamp
          },
          dimensions
        )
      );
    }
  }

  return explodedPoints;
};

export const explodeMetrics = data =>
  data?.metrics?.map(point => explodePoint(point.point)).flat();

/**
 * Returns a list of available services.
 *
 * @param {Array<{dimension: {serivce: string}}>} metrics
 * @returns {Array<string>} services
 */
export const extractServices = metrics =>
  Array.from(new Set(metrics.map(point => point.dimension.service)));

const selectInstance = point => point.dimension.instance.split(".")[1];
const selectHostname = point => point.dimension.hostname;
const transformInstanceHost = host => ({
  id: `Host ${host}`,
  label: host
});
const transformRegularHost = host => ({ id: host, label: host });
const selectHosts = (metrics, selector) =>
  Array.from(new Set(metrics.map(selector)));
/**
 * Returns a list of hosts. Allows to choose between the instance number or
 * the hostname.
 *
 * @param {Array<{dimension: {instance: string, hostname: string}}>} metrics
 * @param {boolean} useInstance Whether to pull the instance or the hostname
 * @returns {Array<string>} hosts
 */
export const extractHosts = (metrics, useInstance) => {
  const selector = useInstance ? selectInstance : selectHostname;
  const transform = useInstance ? transformInstanceHost : transformRegularHost;

  const hosts = selectHosts(metrics, selector).map(transform);
  const labels = sortAlphabetically(hosts, ({ id }) => id).map(
    ({ label }) => label
  );

  return ["average", ...labels];
};

const selectMountpoints = metrics => {
  const uniqueMountpoints = new Set(
    metrics.map(point => point.dimension.mountpoint)
  );
  uniqueMountpoints.delete(undefined);
  return Array.from(uniqueMountpoints);
};

/**
 * Returns an alphabetically sorted list of mountpoints.
 *
 * @param {Array<{dimension: {mountpoint?: string}}>} metrics
 * @returns {Array<string>} mountpoints
 */
export const extractMountpoints = metrics =>
  sortAlphabetically(selectMountpoints(metrics));

/**
 * Returns all the metrics for the given service.
 *
 * @param {Array<{dimension: {service: string}}>} metrics
 * @param {string} service
 */
export const selectServiceMetrics = (metrics, service) =>
  metrics.filter(point => point.dimension.service === service);

/**
 *
 * @param {Array<{dimension: {service: string}}>} metrics
 * @param {Array<string>} services
 * @returns {{[string]: Object}}
 */
export const groupMetricsByService = (metrics, services) =>
  services.reduce(
    (accumulator, service) =>
      Object.assign(accumulator, {
        [service]: selectServiceMetrics(metrics, service)
      }),
    {}
  );
