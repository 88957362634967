import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

import { CloseButton as BaseCloseButton } from "ds/Button";

export const Wrapper = styled.section`
  position: relative;
  margin: 24px 0 42px 0;
  padding: 32px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  border-radius: 2px;

  h2 {
    margin-bottom: 8px;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
  }
`;

export const CloseButton = styled(BaseCloseButton)`
  right: 24px;
  position: absolute;
  top: 24px;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;
