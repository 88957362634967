import { useEffect } from "react";

import { debounce } from "../utils/debounce";

const useOnResize = callback => {
  useEffect(() => {
    if (!callback) {
      return;
    }
    const debounced = debounce(callback);

    window.addEventListener("resize", debounced);

    return () => window.removeEventListener("resize", debounced);
  }, [callback]);
};

export default useOnResize;
