import styled from "styled-components";
import ChevronIconBase from "Icons/ChevronIcon";

import { css, SECTION, HEADER } from "Libs/themes";

const TOTALS_COLUMN_SIZES = `
  min-width: 50px;
  max-width: 20%;
`;

const breakpoints = [
  "32rem", // 512
  "48rem", // 768
  "64rem", // 1024
  "80rem", // 1280
  "96rem", // 1536
  "112rem", // 1792
  "128rem" // 2048
];

export const OrderDetailSS = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export const HeaderSS = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px 8px;
  font-size: 13px;
  border-bottom: 1px solid;
  border-bottom-color:var(--header-periwinkle-grey-border-bottom-color,var(--header-periwinkle-grey,var(--periwinkle-grey)));
  color:var(--header-granite-color,var(--header-granite,var(--granite)));
`;

export const TotalSS = styled.div`
  ${TOTALS_COLUMN_SIZES};
  flex-grow: 1;
  margin-right: 48px;
  text-align: right;

  @media (min-width: ${breakpoints[1]}) {
    margin-right: 8.5rem;
  }
`;

export const LineItemsListSS = styled.ul`
  margin: 0;
  padding: 0;
`;

export const LineItemSS = styled.li`
  display: flex;
  margin: 0 24px;
  padding: 16px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 1px solid;
  border-bottom-color:var(--section-periwinkle-grey-border-bottom-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));

  &:last-child {
    border-bottom: none;
  }
`;

export const ProjectTitleSS = styled.span`
  display: inline-block;
  font-size: 14px;
  flex-grow: 1;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ProjectTotalSS = styled.span`
  ${TOTALS_COLUMN_SIZES};
  text-align: right;
  margin-right: 24px;
  flex-grow: 1;

  @media (min-width: ${breakpoints[1]}) {
    margin-right: 7rem;
  }
`;

export const ChevronIcon = styled(ChevronIconBase)`
  transform: rotate(-90deg);
`;

export const OrderTotalsContainerSS = styled.section`
  margin: 0;
  padding: 0;
  font-size: 15px;
  background-color:var(--section-slate-background-color,var(--section-slate,var(--slate)));
`;

export const OrderPreTotalSS = styled.ul`
  margin: 0;
  padding: 16px 0;
  border-bottom: 1px solid;
  border-bottom-color:var(--section-periwinkle-grey-border-bottom-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));
`;

export const OrderPreTotalItemSS = styled.li`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0 24px;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OrderPreTotalLabelSS = styled.span`
  flex-grow: 1;
`;

export const OrderPreTotalCurrencySS = styled.span`
  ${TOTALS_COLUMN_SIZES};
  text-align: right;
  flex-grow: 1;
  margin-right: 48px;

  @media (min-width: ${breakpoints[1]}) {
    margin-right: 8.5rem;
  }
`;

export const OrderTotalSS = styled.div`
  padding: 16px 24px;
  background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  display: flex;
  flex-wrap: wrap;
`;

export const OrderTotalLabelSS = styled.span`
  font-weight: 700;
  flex-grow: 1;
`;

export const OrderTotalAmountSS = styled.span`
  ${TOTALS_COLUMN_SIZES};
  margin-right: 48px;
  text-align: right;
  font-weight: 700;

  @media (min-width: ${breakpoints[1]}) {
    margin-right: 8.5rem;
  }
`;

export const OrderTotalInfoSS = styled.span`
  width: 100%;
  order: 2;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 16px;
  margin-right: 48px;

  @media (min-width: ${breakpoints[2]}) {
    order: 0;
    width: auto;
    margin-top: 0;
  }
`;

export const OrderInfoSS = styled.footer`
  padding: 24px;
  margin-bottom: 48px;

  @media (min-width: ${breakpoints[2]}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const OrderInfoBlockSS = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${breakpoints[2]}) {
    min-width: 360px;
    flex-grow: 1;
  }
`;

export const OrderInfoHeaderSS = styled.h4`
  margin: 0;
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 700;
`;

export const OrderInfoLineSS = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const PDFButtonSS = styled.div`
  @media (min-width: ${breakpoints[2]}) {
    min-width: 100%;
    flex-grow: 1;
    margin-top: 24px;
  }
`;

export const LinkPlaceholder = styled.div`
  ${TOTALS_COLUMN_SIZES};
  min-width: 25px;
`;
