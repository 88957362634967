import styled from "styled-components";
import { css, SECTION, HEADER, MODAL } from "Libs/themes";

export const Wrapper = styled.div`
  display: flex;
  color:var(--modal-periwinkle-grey-color,var(--modal-periwinkle-grey,var(--periwinkle-grey)));
  justify-content: center;
  align-items: center;
  width: 86px;

  &.active,
  &.complete:hover,
  &.complete:focus {
    cursor: pointer;
    outline: none;
    .name {
      color:var(--modal-skye-color,var(--modal-skye,var(--skye)));
      position: relative;
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color:var(--modal-skye-background-color,var(--modal-skye,var(--skye)));
      }
    }
  }
  &.complete {
    .name {
      color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
    }
  }
  .icon-success-wrapper {
    height: 16px;
    width: 16px;
    padding: 0;
    margin-right: 8px;
  }
  @media (min-width: 1280px) {
    width: 116px;
  }
`;

export const Name = styled.div`
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  margin-left: 8px;
`;
