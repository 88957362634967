import { formatAsCurrency } from "Libs/utils";
import momentjs from "moment";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { getCSSVarString, SECTION, css, HEADER } from "Libs/themes";
import OrderDetailProjectTotal from "../OrderDetailProjectTotal";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const ProjectDetailLayout = styled.div`
  overflow: scroll;
  .table-head-header {
    padding: 24px 0px 0px;
    border-bottom: 1px solid
      var(--section-ebony-light-border-bottom-color,var(--section-ebony-light,var(--ebony-light)));
  }

  .table-data,
  .table-head {
    padding: 16px 24px;
    text-align: left;
    font-size: 15px;
    &:last-child {
      text-align: right;
    }
    white-space: nowrap;
  }
  .table-head {
    font-weight: 600;
    font-size: 13px;
    color:var(--header-granite-color,var(--header-granite,var(--granite)));
    padding: 24px;
  }
  .total {
    font-weight: 700;
  }
  .sub-total-row,
  .discount-row {
    background-color:var(--section-slate-background-color,var(--section-slate,var(--slate)));
  }
  tr.total-row {
    border: none;
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }
`;

const TableWrapper = ({ headers, children }) => {
  return (
    <Table className="table-main">
      <thead className="table-head-header">
        <tr className="table-row table-head-row">
          {headers?.map(header => (
            <th className="table-head" key={header}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="table-body">{children}</tbody>
    </Table>
  );
};

TableWrapper.propTypes = {
  headers: PropTypes.array,
  children: PropTypes.node
};

function OrderProjectDetail(props) {
  const { subscriptionId, lineItemsByOrder } = props;
  const lineItems = lineItemsByOrder[subscriptionId].line_items;
  const durationDate = date => momentjs(date).format("MMM D");
  const orderCurrency = lineItems[0].components.base_price.currency;
  // We need subscriptions API endpoint to get plan sizes here

  return (
    <ProjectDetailLayout>
      <TableWrapper
        headers={["Product", "Duration", "Unit price", "Quantity", "Total"]}
      >
        {lineItems.map((lineItem, index) => (
          <React.Fragment key={index}>
            <tr>
              <td className="cell product table-data">
                <span className={lineItem.product.toLowerCase()}>
                  {lineItem.product}
                </span>
              </td>

              <td className="cell duration table-data">
                {`${durationDate(lineItem.start)} – ${durationDate(
                  lineItem.end
                )}`}
              </td>

              <td className="cell unit-price table-data">
                <span>
                  {formatAsCurrency(
                    lineItem.components.base_price.amount,
                    orderCurrency
                  )}
                </span>
                <span className="discount">
                  {lineItem.components.discount &&
                    formatAsCurrency(
                      lineItem.components.discount.amount,
                      orderCurrency
                    )}
                </span>
              </td>

              <td className="cell quantity table-data">{lineItem.quantity}</td>

              <td className="cell total table-data">
                {formatAsCurrency(lineItem.total_price, orderCurrency)}
              </td>
            </tr>
          </React.Fragment>
        ))}

        <OrderDetailProjectTotal
          lineItems={lineItemsByOrder[subscriptionId].line_items}
        />
      </TableWrapper>
    </ProjectDetailLayout>
  );
}

OrderProjectDetail.propTypes = {
  subscriptionId: PropTypes.string,
  lineItemsByOrder: PropTypes.array
};

export default OrderProjectDetail;
