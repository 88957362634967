import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

const Email = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export default Email;
