export const providers = [
  {
    href: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
    name: "Google Authenticator (Android/iPhone/BlackBerry"
  },
  {
    href: "https://authy.com/",
    name: "Authy (Android/iPhone)"
  },
  {
    href: "https://www.microsoft.com/en-us/p/microsoft-authenticator/9nblgggzmcj6",
    name: "Authenticator (Windows Phone)"
  },
  {
    href: "https://freeotp.github.io/",
    name: "FreeOTP (Android/iPhone)"
  },
  {
    href: "https://chrome.google.com/webstore/detail/gauth-authenticator/jcmgkikfgdbehjdajjdnebnnmmknfblm",
    name: "GAuth Authenticator (Firefox OS, desktop, others)"
  }
];
