import styled from "styled-components";

import { css, HEADER, SECTION } from "Libs/themes";

import Heading2 from "Components/styleguide/Heading2";
import InfoDialog from "Components/InfoDialog";

export const Wrapper = styled.div`
  padding: 0;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Heading2} {
    margin: 12px 0 24px 0;
    line-height: 33px;
  }
`;

export const Info = styled(InfoDialog)`
  margin-left: 8px;
  vertical-align: middle;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  margin: 0 0 4px 0;
  color:var(--header-slate-color,var(--header-slate,var(--slate)));
`;

export const Count = styled.span`
  font-weight: normal;
  font-size: 13px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const List = styled.div`
  padding: 0 32px;
  & + ${Title} {
    margin-top: 32px;
  }
`;

export const EmptyText = styled.div`
  margin-top: 70px;
  margin-bottom: 300px;
  text-align: center;
  .image-wrapper {
    margin: 0 auto 32px;
  }
  h2 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 32px;
    color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
    font-size: 15px;
  }
`;
