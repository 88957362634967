import styled from "styled-components";

import { breakpoints } from "Libs/theme";
import { Button } from "ds/Button";

export const HeaderWrapper = styled.div`
  padding-top: 25px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin-top: ${props => (props.hasBackButton ? "0" : "15px")};
    margin-bottom: 24px;
  }
  @media (max-width: ${breakpoints[1]}) {
    h2 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 9px;
    }
  }
`;

export const StickerLayout = styled.div`
  .sticker-message {
    margin-bottom: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 15px;
`;

export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DeleteButton = styled(Button)`
  margin-right: auto;
  text-transform: none;
`;
