import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./VariableLine.styles";

const VariableLine = ({
  children,
  expand,
  isInactive = false,
  isOpen = false,
  isOverriddenFrom = false,
  openText,
  variable
}) => {
  const intl = useIntl();

  const labels = useMemo(() => {
    return [
      "is_json",
      "is_sensitive",
      "visible_build",
      "visible_runtime",
      "is_inheritable"
    ]
      .reduce((acc, cu) => {
        if (variable[cu])
          acc.push(
            intl.formatMessage({ id: `settings.variables.labels.${cu}` })
          );
        return acc;
      }, [])
      .join(", ");
  }, [variable]);

  const isEnabled = useMemo(() => {
    if (typeof variable.is_enabled === "undefined") return true;
    return variable.is_enabled;
  }, []);

  return (
    <S.Line
      key={`var-${variable.name}-read`}
      id={`variable-list-${variable.name}`}
      info={
        <S.Info disabled={!isEnabled || isInactive}>
          <S.InfoMain>
            <S.Name className="variable-name">{variable.name}</S.Name> ={" "}
            <S.Value className="variable-value">
              <S.Truncate>
                {variable.is_sensitive ? "*****" : variable.value}
              </S.Truncate>
            </S.Value>
          </S.InfoMain>
          <S.Labels>{labels}</S.Labels>

          <S.Badges>
            {variable.inherited && (
              <AccessibleTooltip
                tooltipProps={{
                  id: `inherited-${variable.name}`,
                  children: intl.formatMessage({
                    id: "settings.variables.inherited.details"
                  })
                }}
              >
                <S.Inherited>
                  {intl.formatMessage({ id: "settings.variables.inherited" })}
                </S.Inherited>
              </AccessibleTooltip>
            )}

            {!isEnabled && (
              <S.Disabled>
                {intl.formatMessage({ id: "settings.variables.disabled" })}
              </S.Disabled>
            )}

            {isOverriddenFrom && (
              <AccessibleTooltip
                tooltipProps={{
                  id: `overridden-${variable.name}`,
                  children: intl.formatMessage({
                    id: `settings.variables.overridden.details.${isOverriddenFrom}`
                  })
                }}
              >
                <S.Overridden>
                  {intl.formatMessage({ id: "settings.variables.overridden" })}
                </S.Overridden>
              </AccessibleTooltip>
            )}

            {isInactive && (
              <AccessibleTooltip
                tooltipProps={{
                  id: `overridden-${variable.name}`,
                  children: intl.formatMessage({
                    id: "settings.variables.inactive.details"
                  })
                }}
              >
                <S.Inactive>
                  {intl.formatMessage({ id: "settings.variables.inactive" })}
                </S.Inactive>
              </AccessibleTooltip>
            )}
          </S.Badges>
        </S.Info>
      }
      isNew={false}
      isOpen={isOpen}
      openText={openText || intl.formatMessage({ id: "edit" })}
      onClick={expand}
      width="auto"
    >
      {children}
    </S.Line>
  );
};

VariableLine.propTypes = {
  children: PropTypes.node,
  expand: PropTypes.func.isRequired,
  isInactive: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOverriddenFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  openText: PropTypes.string,
  variable: PropTypes.object.isRequired
};

export default VariableLine;
