import React from "react";
import PropTypes from "prop-types";

import Checkbox from "ds/Checkbox";
import IconFilter from "Icons/IconFilter";

import * as S from "./HostSelector.styles";
import useUniqueId from "Libs/useUniqueId";

const HostSelector = ({ hosts = [], selected = [], onChange, ...props }) => {
  const uid = useUniqueId();

  return (
    <S.Dropdown
      id="environments-filter"
      label="Filter"
      icon={
        <span className="icon">
          <IconFilter />
        </span>
      }
      iconAfter={true}
      withArrow={false}
      closeOnContentClick={false}
      {...props}
    >
      {hosts.map(hostName => (
        <Checkbox
          name={`${uid}-${hostName}`}
          forId={`${uid}-${hostName}`}
          label={hostName === "average" ? "average" : `Host ${hostName}`}
          checked={!!selected.includes(hostName)}
          key={hostName}
          onCheckedChanged={checked => {
            const updated = !checked
              ? selected.filter(host => host !== hostName)
              : [...selected, hostName];
            onChange(updated);
          }}
          disabled={false}
        />
      ))}
    </S.Dropdown>
  );
};

HostSelector.propTypes = {
  hosts: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

export default HostSelector;
