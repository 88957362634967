import styled from "styled-components";

import { css, SECTION } from "Libs/themes";

import InputField from "Components/fields/InputField";

export const FormContainer = styled.form`
  padding: 33px 33px 38px;

  > button + button {
    margin-left: 8px;
  }
`;

export const HelpText = styled.div`
  font-size: 14px;
  line-height: 26px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  margin-bottom: 19px;
`;

export const TokenName = styled(InputField)`
  margin-bottom: 32px;
`;
