import React from "react";
import PropTypes from "prop-types";

import ActivitySection from "../../../../../../../common/containers/ActivitySection";
import { Details, Header } from "../../components";

import * as S from "./ListIntegrations.styles";

const Integration = ({ integration }) => {
  return (
    <S.Content>
      <Header integration={integration} />
      <Details integration={integration} />
      <ActivitySection context={`integration.${integration?.type}`} />
    </S.Content>
  );
};

Integration.propTypes = {
  integration: PropTypes.object
};

export default Integration;
