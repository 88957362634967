import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import { css, SECTION, LINK } from "Libs/themes";

export const ContainerSS = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
`;

export const OrderStatusSS = styled.span`
  display: none;
  font-size: 13px;
  text-align: left;
  flex-grow: 1;

  @media (min-width: 48rem) {
    display: inline-block;
    max-width: 10rem;
  }
`;

export const OrderIdSS = styled(RouterLink)`
  color:var(--link-ebony-color,var(--link-ebony,var(--ebony)));
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;

  @media (min-width: 48rem) {
    font-size: 15px;
    flex-grow: 1;
  }
`;

export const OrderPeriodSS = styled.span`
  font-size: 13px;
  color:var(--section-snow-color,var(--section-snow,var(--snow)));
  text-align: left;
  @media (min-width: 32rem) {
    min-width: 12rem;
    max-width: 32rem;
    color: inherit;
  }
  @media (min-width: 48rem) {
    flex-grow: 1;
  }
`;

export const OrderTotalSS = styled.span`
  display: flex;
  align-items: center;
  font-weight: 700;

  @media (min-width: 48rem) {
    margin-right: 5rem;
  }
`;

export const OrderTotalLabelSS = styled.span`
  display: none;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  font-size: 13px;
  font-weight: 500;
  margin-right: 32px;

  @media (min-width: 32rem) {
    display: inline-block;
  }
`;
