import styled from "styled-components";
import { css, SECTION, getCSSVarString, ICON } from "Libs/themes";

export const Wrapper = styled.section`
  margin-top: 24px;
  p {
    margin: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color:var(--section-skye-color,var(--section-skye,var(--skye)));
    & + p {
      margin-top: 16px;
    }
  }
  label {
    display: block;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
  }

  a {
    text-decoration: none;
  }

  dl {
    margin: 24px 0 0 0;
  }
  dt {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const SettingsLine = styled.dd`
  position: relative;
  margin: 0 0 8px 0;
  padding: 0 0 0 21px;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  opacity: ${props => (props.isActivate ? 1 : 0.5)};
  span {
    text-transform: capitalize;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  svg {
    position: absolute;
    left: -3px;
    top: 1px;
    & > path {
      fill: ${props =>
        props.isActivate
          ? "#4786FF"
          :"var(--icon-ebony-light-fill,var(--icon-ebony-light,var(--ebony-light)))"};
    }
  }
`;

export const Tag = styled.span`
  display: inline-block;
  margin: 0 4px 0 0;
  padding: 2px 4px;
  font-family: "Overpass Mono", monospace;
  font-size: 12px;
  line-height: 16px;
  background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  border-radius: 2px;
`;

export const EmailRecipient = styled.span`
  color:var(--section-skye-color,var(--section-skye,var(--skye)));
`;
