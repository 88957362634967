import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Map } from "immutable";

import AccessibleTooltip from "Components/AccessibleTooltip";
import { PROVIDERS } from "Constants/providers";
import { loadConnectedAccounts } from "Reducers/organization/settings/connectedAccounts";
import withReducers from "Hocs/withReducers";

import * as S from "./style";

const Accounts = () => {
  const currentUser = useSelector(state => state.app?.get("me"))?.toJS();
  const dispatch = useDispatch();

  const connectedAccounts = useSelector(state =>
    state.connectedAccounts?.getIn(["data", currentUser.username], new Map())
  );

  useEffect(() => {
    dispatch(loadConnectedAccounts(currentUser));
  }, []);

  return (
    <S.Container>
      {PROVIDERS.map(({ name, image }) => {
        const isConnected = connectedAccounts?.get(name);

        const account = (
          <AccessibleTooltip
            key={`${name}_account`}
            tooltipProps={{
              children: (
                <FormattedMessage
                  id={isConnected ? "account.connected" : "account.connect"}
                  values={{
                    accountName: `${name.charAt(0).toUpperCase()}${name.slice(
                      1
                    )}`
                  }}
                />
              )
            }}
          >
            <S.Icon
              isConnected={isConnected}
              width={16}
              height={16}
              src={image}
              alt={`${name}-logo`}
            />
          </AccessibleTooltip>
        );
        return isConnected ? (
          <Link
            key={`${name}_account`}
            to={`/-/users/${currentUser.username}/settings/accounts`}
          >
            {account}
          </Link>
        ) : (
          <S.Link
            key={`${name}_account`}
            rel="noopener noreferrer"
            href={`${process.env.AUTH_URL}/auth/authorize/${name}?intent=signup`}
          >
            {account}
          </S.Link>
        );
      })}
    </S.Container>
  );
};

export default withReducers({
  connectedAccounts: () =>
    import("Reducers/organization/settings/connectedAccounts")
})(Accounts);
