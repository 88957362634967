import styled from "styled-components";
import { css, getCSSVarString, SECTION } from "Libs/themes";

export const LabelWrapper = styled.div`
  display: flex;
  margin: -8px -32px 0;
  padding: 0 32px;
  border-bottom: 1px solid
    var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
  .label {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
    background-color: transparent;
    border: none;
    padding: 0;

    span.chevron {
      min-width: 16px;
      margin-top: 2px;
    }
    &.descending span.chevron {
      margin-top: 0;
      margin-bottom: 2px;
    }
    @media (max-width: 1024px) {
      min-width: 24%;
    }
  }
  .label-project {
    width: 100%;
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const EmptyProjectsListLayout = styled.div`
  text-align: center;
  margin-top: 130px;
  width: 100%;
  h2 {
    margin-bottom: 16px;
  }
  p {
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
    font-size: 14px;
    width: 432px;
    max-width: 100%;
    margin: 0 auto 40px;
  }
  &.no-projects {
    span.create-project {
      display: inline-block;
      margin-bottom: 170px;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin: 0 auto 24px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
  &.no-projects-image {
    width: 100%;
    margin-bottom: 60px;
  }
`;
