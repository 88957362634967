import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, getCSSVarString, INPUT, SECTION, BUTTON } from "Libs/themes";

export const Wrapper = styled.div`
  padding: 0;
  .rccs {
    float: right;
  }
  button + button {
    margin-left: 10px;
  }
`;

export const PaymentWrapper = styled.div`
  h3 {
    margin-top: 0;
    font-size: 15px;
    line-height: 33px;
  }
`;

export const AddressWrapper = styled.form`
  width: 316px;
  .field {
    width: 100%;
    &.administrative_area {
      display: inline-block;
    }
    &.postal_code {
      display: inline-block;
      width: calc(40% - 10px);
      float: right;
    }
    input {
      font-size: 15px;
      font-family: "Open Sans";
      &::placeholder {
        color:var(--input-granite-color,var(--input-granite,var(--granite)));
        opacity: 1;
      }
    }
    .Select-placeholder {
      font-size: 14px;
      color: #4a4a4a;
      opacity: 0.4;
    }
  }
  .dropdown {
    margin-bottom: 16px;
  }
  .inline-fields {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .input-wrapper {
    width: 100% !important;
  }
  .select-box {
    width: 100% !important;
  }
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 316px 316px;
    grid-column-gap: 32px;
    width: auto;
  }
`;

export const CardWrapper = styled.div`
  margin-bottom: 32px;
  max-width: 100%;
  .checkbox-field {
    margin-bottom: 0;
    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .InputElement.is-empty {
    font-size: 14px;
    color: #4a4a4a;
    opacity: 0.4;
  }
  &.card-form-credit-card {
    width: 434px;
  }
`;

export const Voucher = styled.div`
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
  font-size: 32px;
  line-height: 33px;
  margin-bottom: 16px;
  font-weight: 700;
`;

export const VoucherLabel = styled.label`
  font-size: 15px;
  line-height: 33px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  margin-bottom: 0;
  ${semiBoldAlias};
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0px 24px 0px;
  div + div {
    margin-left: 4px;
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  padding: 6px 12px;
  font-weight: 600;
  background-color: ${props =>
    props.active &&"var(--button-skye-background-color,var(--button-skye,var(--skye)))"};
  color: ${props => props.active &&"var(--button-snow-color,var(--button-snow,var(--snow)))"};
  border-radius: 2px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const InlineFields = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 32px;
  .input-field {
    width: calc(43% - 30px);
    &:first-child {
      width: 57%;
      margin-right: 20px;
    }
  }
`;
