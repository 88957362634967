import styled from "styled-components";

import Priority from "../../../../common/components/Priority";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const Layout = styled(Priority)`
  ${semiBoldAlias};
  font-size: 15px;
  line-height: 24px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;
