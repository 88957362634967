import ndjson from "can-ndjson-stream";

import identity from "./identity";

/**
 * Parses a \n separated string of json objects and combines into a Js array.
 *
 * @template K Type of each object on the stream
 * @template T Type of the object after processing
 *
 * @param {ReadableStream<Uint8Array>} stream \n separated json objects
 * @param {(value: K) => T} [transform=(value: K) => K]  Optional transform fuction that will
 * be applied to each element after being parsed.
 *
 * @returns {Array<T>} Array of transformed objects
 */
const streamToArray = async (stream, transform = identity) => {
  const data = ndjson(stream).getReader();
  const points = [];
  let done = false;

  do {
    let result;
    try {
      result = await data.read();
      if (result.value) {
        points.push(transform(result.value));
      }

      done = result.done;
    } catch (error) {
      done = true;
      throw error;
    }
  } while (!done);

  return points;
};

export default streamToArray;
