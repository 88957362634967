import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { addSshKey } from "Reducers/organization/settings/sshKey";

import Label from "Components/fields/Label";
import Loading from "Components/Loading";
import SuccessStateIcon from "Components/icons/SuccessStateIcon";

import * as S from "./SshKey.styles";

const SshKey = ({ user }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [key, setKey] = useState();
  const [isSaved, setIsSaved] = useState(false);

  const isLoading = useSelector(state =>
    state.userSshKey?.get("isLoading", false)
  );
  const sshKeys = useSelector(state =>
    state.userSshKey?.get("data", new Map())?.valueSeq()?.toJS()
  );
  const status = useSelector(state => state.userSshKey?.get("status"));
  const error = useSelector(state => state.userSshKey?.get("error", new Map()));

  useEffect(() => {
    if (!key && sshKeys.length) {
      setKey(
        sshKeys.sort(
          (a, b) => new Date(a.data.changed) < new Date(b.data.changed)
        )[0]?.value
      );
    }
  }, [sshKeys.length]);

  useEffect(() => {
    if (status === "added") {
      setIsSaved(true);
    }
  }, [status]);

  const handlePaste = e => {
    const newKey = e.clipboardData.getData("text");
    handleSave(newKey);
  };

  const handleSave = newKey => {
    const newSshKey = newKey.trim();
    if (newSshKey !== "" && !sshKeys.find(k => k.value === newSshKey)) {
      setIsSaved(false);
      dispatch(
        addSshKey({
          sshKey: {
            title: "",
            value: newSshKey
          }
        })
      );
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.Layout>
          {isLoading ? (
            <Loading />
          ) : (
            <S.Textarea
              id="sshkey-value-input"
              label="Public SSH Key"
              value={key}
              onChange={e => setKey(e.target.value)}
              error={error.get("value") || error.get("title")}
              onBlur={e => handleSave(e.target.value)}
              onPaste={handlePaste}
              height={160}
              description={
                isSaved ? (
                  <>
                    <SuccessStateIcon color="#23b7b7" />
                    {intl.formatMessage({ id: "wizard.ssh.saved" })}
                  </>
                ) : null
              }
              required={false}
            />
          )}
        </S.Layout>
      </S.Wrapper>

      {user.ssh_keys?.length !== 0 && (
        <Label>
          {intl.formatMessage({
            id: "ssh_key_autofilled"
          })}
        </Label>
      )}
    </>
  );
};

SshKey.propTypes = {
  value: PropTypes.string,
  user: PropTypes.object
};

export default SshKey;
