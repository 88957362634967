import styled, { css as styledCSS } from "styled-components";
import { css, SECTION } from "Libs/themes";

export const EnvironmentServicesLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 7px;
  .service-tree-wrapper {
    background: none;
    margin-right: 32px;
    max-width: 316px;
    min-width: 316px;
    width: 316px;
    .services-tree {
      background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    }
  }

  @media (max-device-width: 900px), (max-width: 900px) {
    display: flex;
    flex-direction: column-reverse;

    .service-tree-wrapper {
      background: none;
      margin-right: 0;
      margin-top: 32px;
    }

    & > div {
      width: 100%;
      margin: 0;
    }
  }

  ${props =>
    props.theme.name !== "dark" &&
    styledCSS`
      path.edgePath {
        stroke: #fff;
      }
    `};
`;

export const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
`;
