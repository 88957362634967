import styled from "styled-components";
import {
  css,
  LINK,
  SECTION,
  BUTTON,
  getCSSVarString,
  HEADER
} from "Libs/themes";

export const Wrapper = styled.button.attrs({ type: "button" })`
  display: inline-flex;
  flex-direction: column;
  border-radius: 2px;
  background-color:var(--setup-card-section-background-color,var(--section-snow-background-color,var(--section-snow,var(--snow))));
  font-size: 15px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  width: 316px;
  align-items: center;
  padding: 32px 32px 53px;
  box-sizing: border-box;
  border: none;
  font-weight: 400;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;

  &:hover,
  &:focus {
    box-shadow: var(--section-box-shadow-box-shadow,var(--section-box-shadow,var(--box-shadow)))
      var(--section-ebony-shadow-box-shadow-color,var(--section-ebony-shadow,var(--ebony-shadow)));
    outline: none;
    ${props =>
      props.theme.name === "dark" &&
      `&.active {
      box-shadow: none;
    }
  `};
  }
  &.active {
    background: ${props =>
      props.theme.name === "dark"
        ?"var(--section-dark-shade-background-color,var(--section-dark-shade,var(--dark-shade)))": "none"};
    position: relative;
    .title {
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
    }
    span.active {
      position: absolute;
      display: inline-block;
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background-color:var(--button-skye-background-color,var(--button-skye,var(--skye)));
      border: 2px solid #fff;
      top: -2px;
      right: -2px;
    }
  }
  &:hover,
  &.active {
    svg .highlight {
      fill: #ffeeee;
      &.primary {
        fill: #ffbdbb;
      }
    }
  }
`;

export const ImgWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
  margin-bottom: 32px;
`;

export const Title = styled.strong`
  font-weight: bold;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin-bottom: 8px;
`;

export const Description = styled.span`
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;
