import styled from "styled-components";

import { css, HEADER, SECTION } from "Libs/themes";

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 32px;
  padding-top: 10px;
  h2 {
    margin: 0;
  }
`;

export const BaseIcon = styled.div`
  max-height: 40px;
  width: 40px;
  margin-right: 20px;
  img {
    max-height: 100%;
  }
`;

export const TemplateInfo = styled.div`
  color:var(--section-night-color,var(--section-night,var(--night)));
  font-size: 15px;
  margin-bottom: 24px;
  h4 {
    margin: 0 0 8px;
    color:var(--header-night-color,var(--header-night,var(--night)));
  }
  &.description {
    color:var(--section-night-color,var(--section-night,var(--night)));
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
    p {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
  &.blank-description {
    margin-bottom: 48px;
  }
  &.settings {
    margin-bottom: 32px;
  }
`;

export const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 4px;
`;

export const ActionLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
