import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Button, ButtonWrapper } from "ds/Button";

import Pane from "../../../../components/Pane";
import Paragraph from "../../../../components/Paragraph";

const ManageTFA = ({ isTFAEnabled, onRemove }) => (
  <Pane
    header={
      <strong>
        {isTFAEnabled ? (
          <FormattedMessage id="security.manage.header.enabled" />
        ) : (
          <FormattedMessage id="security.manage.header.disabled" />
        )}
      </strong>
    }
  >
    <Paragraph>
      <FormattedMessage id="security.manage.description" />
    </Paragraph>
    {isTFAEnabled ? (
      <Button onClick={onRemove} variant="outline">
        <FormattedMessage id="security.manage.disable" />
      </Button>
    ) : (
      <ButtonWrapper className="actions">
        <Button as={Link} to="./security/setup">
          <FormattedMessage id="security.manage.setup" />
        </Button>
      </ButtonWrapper>
    )}
  </Pane>
);

ManageTFA.propTypes = {
  isTFAEnabled: PropTypes.bool,
  onRemove: PropTypes.func
};

export default ManageTFA;
