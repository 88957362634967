import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";

import ModalWrapper from "Components/Modal";
import * as S from "./styles";
import { Button, ButtonWrapper } from "ds/Button";
import Checkbox from "ds/Checkbox";
import LabeledInfo from "Components/LabeledInfo";
import { PERMISSIONS } from "Constants/constants";
import Error from "Components/Error";
import InfoDialog from "Components/InfoDialog";
import Heading3 from "Components/styleguide/Heading3";

const EditMemberModal = ({
  closeModal,
  isOpen,
  editMember,
  editedMember,
  users,
  errors
}) => {
  const [permissions, setPermissions] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    if (editedMember) {
      setPermissions(editedMember.permissions);
    }
  }, [editedMember]);

  const onPermissionChange = (checked, value) => {
    const index = permissions.indexOf(value);
    if (checked && index === -1) {
      permissions.push(value);
    } else if (!checked && index !== -1) {
      permissions.splice(index, 1);
    }

    setPermissions([...permissions]);
  };

  const user = users?.get(editedMember?.user_id);

  return (
    <ModalWrapper
      modalClass={`modal-medium`}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <S.ModalBodyWrapper>
        <Heading3>{intl.formatMessage({ id: "edit_user" })}</Heading3>
        <S.InformationText>
          {intl.formatMessage({ id: "edit_user_organization" })}
        </S.InformationText>
        {errors && <Error>{errors}</Error>}
        <LabeledInfo
          label={intl.formatMessage({ id: "email_address" })}
          value={user?.email}
        />

        <S.LabelWithInfo>
          <S.CustomLabel>
            <FormattedMessage id="organizations.permissions" />
          </S.CustomLabel>
          <InfoDialog
            text={
              <FormattedMessage
                id="organizations.add_modal.info"
                values={{
                  documentationLink: (
                    <a
                      href={intl.formatMessage({
                        id: "links.documentation.organizations"
                      })}
                    >
                      documentation
                    </a>
                  )
                }}
              />
            }
          />
        </S.LabelWithInfo>
        {PERMISSIONS.map((p, i) => (
          <Checkbox
            forId={p.value}
            key={p.value}
            label={p.label}
            id={p.value}
            tabIndex={i}
            checked={permissions.find(val => val === p.value) ? true : false}
            onCheckedChanged={checked => onPermissionChange(checked, p.value)}
          />
        ))}

        <ButtonWrapper hasSpacing justifyContent="end">
          <Button
            id={`add-billing-modal-button`}
            type="submit"
            onClick={() => {
              editMember(permissions);
            }}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
        </ButtonWrapper>
      </S.ModalBodyWrapper>
    </ModalWrapper>
  );
};

EditMemberModal.propTypes = {
  closeModal: PropTypes.func,
  editMember: PropTypes.func,
  isOpen: PropTypes.bool,
  editedMember: PropTypes.object,
  users: PropTypes.object,
  errors: PropTypes.string
};

export default EditMemberModal;
