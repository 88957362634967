import { createSlice } from "@reduxjs/toolkit";
import { entities } from "Libs/platform";

const initialState = {
  activeActivity: undefined,
  selectedActivity: undefined
};

const activitiesSlice = createSlice({
  name: "activitiesSlice",
  initialState,
  reducers: {
    setActiveActivity: (state, action) => {
      state.activeActivity = action.payload
        ? new entities.Activity(action.payload)
        : undefined;
    },
    setSelectedActivity: (state, action) => {
      state.selectedActivity = action.payload
        ? new entities.Activity(action.payload)
        : undefined;
    }
  }
});

export const { setActiveActivity, setSelectedActivity } =
  activitiesSlice.actions;

export default activitiesSlice.reducer;
