import React from "react";

import styled from "styled-components";
import { css, ICON } from "Libs/themes";
import { LiveMessage } from "react-aria-live";

export const Input = styled.button`
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  background: transparent;
  border: none;
  color:var(--icon-granite-color,var(--icon-granite,var(--granite)));

  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  position: relative;
  margin-right: 8px;
  z-index: 10;
`;

export const Aria = expanded =>
  expanded ? (
    <LiveMessage
      message="Filter popup menu has been opened."
      aria-live="polite"
    />
  ) : (
    <LiveMessage
      message="Filter popup menu has been closed."
      aria-live="polite"
    />
  );
