import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import Loading from "Components/Loading";

import withReducers from "Hocs/withReducers";
import {
  integrationsSelector,
  integrationsLoadingSelector
} from "Reducers/integration";

import withProjectRedirect from "../../../../../common/hocs/withProjectRedirect";

import Button from "ds/Button";

import {
  EmptyIntegrations,
  GettingStarted,
  ListIntegrations
} from "./containers";

import { Flex, Section, Heading2 } from "./Details.styles";

const ProjectIntegration = () => {
  const intl = useIntl();
  const { organizationId, projectId, integrationId } = useDecodedParams();
  const loading = useSelector(integrationsLoadingSelector);
  const integrations = useSelectorWithUrlParams(integrationsSelector);

  const currentIntegration =
    integrations.find(x => x.id === integrationId) ?? integrations[0];

  if (loading) {
    return <Loading iconOnly={true} />;
  }

  // TODO:SETTINGS change ss to settings
  const ADD_INTEGRATION_ROUTE = `/${organizationId}/${projectId}/-/ss/integrations/setup`;

  const showGettingStarted = integrations.length === 1;

  return (
    <Section>
      <Flex>
        <Heading2>Integrations</Heading2>
        <Button as={Link} to={ADD_INTEGRATION_ROUTE}>
          {intl.formatMessage({
            id: "integrations.add",
            defaultMessage: "Add integration"
          })}
        </Button>
      </Flex>

      {showGettingStarted && <GettingStarted />}

      {currentIntegration && integrations.length > 0 ? (
        <ListIntegrations
          integration={currentIntegration}
          showGettingStarted={showGettingStarted}
        />
      ) : (
        <EmptyIntegrations />
      )}
    </Section>
  );
};

export default withReducers({
  integration: () => import("Reducers/integration")
})(withProjectRedirect(ProjectIntegration));
