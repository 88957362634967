import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import InfoDialog from "Components/InfoDialog";

import CardSkeleton from "../components/CardSkeleton";
import Chart from "../components/Chart";

import formatBytes from "../utils/formatBytes";
import formatPercentage from "../utils/formatPercentage";

import * as S from "../styles";

const Memory = ({ data, isLoading, hosts, timeframe, onBrush }) => {
  const intl = useIntl();

  if (isLoading || !data) {
    return <CardSkeleton />;
  }

  return (
    <S.Container>
      <S.ChartHeading>
        <S.Row>
          <S.ChartTitle>
            {intl.formatMessage({ id: "metrics.ram.title" })}
          </S.ChartTitle>
          <S.InfoDialogWrapper>
            <InfoDialog
              title={intl.formatMessage({ id: "metrics.ram.dialog.title" })}
              text={intl.formatMessage({ id: "metrics.ram.dialog.text" })}
              to={intl.formatMessage({ id: "links.documentation.metric" })}
              linkText={intl.formatMessage({ id: "learnmore" })}
            />
          </S.InfoDialogWrapper>
        </S.Row>
        <S.Info>
          <S.Current>
            {formatBytes(data.last[`average@value`]?.toFixed(2) || 0, "GB")}
          </S.Current>
          /{formatBytes(data.last[`max`] || 0, "GB")}{" "}
          <S.Percentage>
            {formatPercentage(data.last[`average@percentage`])}
          </S.Percentage>
          <S.Description>
            {intl.formatMessage({ id: "metrics.currentavg" })}
          </S.Description>
        </S.Info>
      </S.ChartHeading>
      <Chart
        hosts={hosts}
        data={data.data}
        max={data.max}
        yFormatter={value => formatBytes(value, "GB")}
        tooltipFormatter={value => formatBytes(value, "GB")}
        timeframe={timeframe}
        onBrush={onBrush}
      />
    </S.Container>
  );
};

Memory.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  hosts: PropTypes.array,
  timeframe: PropTypes.object,
  onBrush: PropTypes.func
};

export default Memory;
