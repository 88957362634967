import { fromJS, Map, List } from "immutable";

import { normalize } from "Libs/utils";

import {
  Action,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { StoreMapStateType, MapStoreStateType } from "Reducers/types";

import type AuthUser from "platformsh-client/types/model/AuthUser";
import type Account from "platformsh-client/types/model/Account";
export interface UserAction extends Action {
  payload: unknown;
}

export const loadUsers = createAsyncThunk(
  "app/users/load_users",
  async (users: AuthUser[], { getState }) => {
    // Merge the current state of users with the old one.
    const mergedUsers = users;

    const prevUsers = (getState() as MapStoreStateType).user.get("data", Map());

    const mappedUsers = fromJS(normalize(users));

    if (typeof prevUsers !== "undefined") {
      return prevUsers.merge(mappedUsers);
    }

    return mergedUsers;
  }
);

export const loadUser = createAsyncThunk(
  "app/users/load_user",
  async (userId: string, { getState }) => {
    const isInProgress = (getState() as MapStoreStateType).user
      .get("loadInProgress", List())
      .includes(userId);

    if (isInProgress) {
      return;
    }

    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    return await client.getAccount(userId);
  }
);

const removeLoadInProgress = (state: StoreMapStateType, id: string) => {
  const loadInProgress = state.get("loadInProgress", List());
  const updated = loadInProgress.filter((_id: string) => _id !== id);
  return state.set("loadInProgress", updated);
};

const user = createSlice({
  name: "user",
  initialState: Map({ data: Map() }) as StoreMapStateType,
  reducers: {},
  extraReducers: builder => {
    // LOAD USER
    builder.addCase(loadUser.pending, state => state.set("loading", true));
    builder.addCase(
      loadUser.fulfilled,
      (state, { payload }: PayloadAction<Account | undefined>) => {
        return removeLoadInProgress(state, payload?.id as string)
          .setIn(["data", payload?.id, payload], fromJS(payload))
          .set("loading", false);
      }
    );
    builder.addCase(loadUser.rejected, (state, action: UserAction) => {
      return removeLoadInProgress(state, action.payload as string)
        .set("loading", false)
        .set("errors", action.payload);
    });

    // LOAD USERS
    builder.addCase(
      loadUsers.fulfilled,
      (state, { payload }: PayloadAction<AuthUser[]>) => {
        return state
          .set("data", fromJS(normalize(payload)))
          .set("loading", false);
      }
    );
  }
});

export const usersSelector = (state: MapStoreStateType) =>
  state.user.get("data");

export default user.reducer;
