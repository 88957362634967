import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import Button from "ds/Button";
import EditIcon from "Icons/EditIcon";
import DeleteIcon from "Icons/DeleteIcon";
import UserIcon from "Icons/UserIcon";
import { deleteProfilePicture } from "Reducers/profile";
import {
  Layout,
  PreviewWrapper,
  FileInput,
  FileDescription,
  MaxSizeText
} from "./ImageUpload.styles";

const ImageUpload = ({ username, picture, onChange, className }) => {
  const dispatch = useDispatch();

  const showDeleteButton = picture?.startsWith?.("http");
  const showEmptyAvatar = !picture;

  return (
    <Layout className={className}>
      <PreviewWrapper empty={showEmptyAvatar}>
        {showEmptyAvatar ? (
          <UserIcon color="#f0f2f5" />
        ) : (
          <img data-test="user-picture" src={picture} alt={username} />
        )}
      </PreviewWrapper>
      <FileDescription>
        <Button
          variant="secondary"
          as="label"
          id="image-upload-button"
          htmlFor="image-upload"
          content="icon"
        >
          <EditIcon color="#98A0AB" />
        </Button>
        <FileInput
          type="file"
          accept="image/*"
          id="image-upload"
          name="image-upload"
          onChange={event => {
            if (event?.target?.files.length) {
              onChange(event?.target?.files[0]);
            }
          }}
        />
        {showDeleteButton && (
          <Button
            content="icon"
            variant="secondary"
            data-test="delete-picture"
            onClick={event => {
              event.preventDefault();
              dispatch(deleteProfilePicture());
            }}
          >
            <DeleteIcon color="#98A0AB" />
          </Button>
        )}

        <MaxSizeText>
          <FormattedMessage id="settings.profile.maxfilesize" />
        </MaxSizeText>
      </FileDescription>
    </Layout>
  );
};

ImageUpload.propTypes = {
  className: PropTypes.string,
  username: PropTypes.string,
  picture: PropTypes.string,
  onChange: PropTypes.func
};

export default ImageUpload;
