import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, HEADER } from "Libs/themes";
import { Label, Value } from "Components/LabeledInfo/styles";

export const Wrapper = styled.div`
  text-align: left;
  ${Value} {
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  }

  ${Label} {
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 18px;
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }
`;

export const InfoLayout = styled.div`
  display: grid;
  grid-template-columns: 25% 30% auto;

  @media only screen and (max-width: 1400px) {
    grid-template-columns: 15% 30% auto;
  }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 15% 25% auto;
  }
  align-items: center;
  .user {
    display: flex;
    flex-align: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .display-name {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .email {
    font-weight: normal;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .role {
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    font-size: 13px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
    ${semiBoldAlias};
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    idsplay: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
`;

export const RoleWrapper = styled.div`
  display: inline-flex;
  flex: 1;
  margin-left: 20px;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  font-size: 13px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  ${semiBoldAlias};
  &:first-letter {
    text-transform: uppercase;
  }
  max-width: 500px;
`;

export const LabeledInfoWrapper = styled.div`
  width: 100px;
  min-width: 100px;

  span {
    text-transform: none !important;
  }
`;

export const DisplayName = styled.span`
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  ${semiBoldAlias};
  font-size: 15px;
  line-height: 24px;
`;
