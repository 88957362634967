import styled from "styled-components";
import { css, MODAL } from "Libs/themes";

import Heading2 from "Components/styleguide/Heading2";
import Heading3 from "Components/styleguide/Heading3";

export const Layout = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 0;
`;

export const Wrapper = styled.div`
  background-color:var(--modal-snow-background-color,var(--modal-snow,var(--snow)));
  border-radius: 2px;
  color:var(--modal-night-color,var(--modal-night,var(--night)));
  z-index: 9;
  padding: 26px 32px;
  box-sizing: border-box;
  width: 100%;
  min-height: 570px;
  display: flex;
  flex-direction: column;
  // position: absolute;
  // top: -30px;
  // margin: 0 auto;
  // left: 0;
  // right: 0;
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 54px;

  ${Heading2} {
    position: absolute;
    left: 0;
    line-height: inherit;
  }

  > span {
    position: absolute;
    right: 0;
  }
`;

export const Content = styled.div`
  ${Heading3} {
    margin: 0 0 32px 0;
    text-align: center;
  }

  form {
    display: block;
    max-width: 432px;
    margin: 0 auto;

    > footer {
      justify-content: center;
    }
  }
`;
