import styled from "styled-components";

export const QRTitle = styled.p`
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 30px;
`;

export const QRCode = styled.img`
  width: 283px;
  margin-left: -25px;
`;
