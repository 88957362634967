const dagre = require("dagre");

// maximum treeWidth for large/medium padding
const LARGE_PADDING_THRESHOLD = 4;
const MED_PADDING_THRESHOLD = 7;

function position(g, options) {
  let layering = dagre.util.buildLayerMatrix(g);

  // calculate tree width as # of nodes in widest layer
  let treeWidth = Math.max.apply(
    Math,
    layering.map(function (layer) {
      return layer.length;
    })
  );

  const gridStepY = options.gridStepY;
  let gridStepX = options.gridStepX;

  // reduce padding for wide graphs
  if (treeWidth > LARGE_PADDING_THRESHOLD) {
    gridStepX = 80;
  }
  if (treeWidth > MED_PADDING_THRESHOLD) {
    gridStepX = 55;
  }

  // Assign y positions on the grid.
  layering.forEach(function (layer, yi) {
    layer.forEach(function (n, xi) {
      let node = g.node(n);
      node.y = yi * gridStepY;

      // Initial x position.
      node.x = xi * gridStepX;
    });
  });

  // Assign x positions.
  /*layering.forEach(function(layer, i) {
    if (!i) {
      return;
    }

    positionPass(g, layer, gridStepX, function(n) {
      return g.inEdges(n).map(function(p) {
        return p.v;
      });
    });
  });*/

  // Assign x positions.
  /*layering.reverse().forEach(function(layer, i) {
    if (!i) {
      return;
    }

    positionPass(g, layer, gridStepX, function(n) {
      return g
        .outEdges(n)
        .filter(function(e) {
          return !isDummyNode(e.w);
        })
        .map(function(p) {
          return p.w;
        });
    });
  });*/
}

/*function isDummyNode(n) {
  return n.startsWith("_d");
}

// Sort nodes in the layer so that:
// - Dummy nodes are processed first,
// - Nodes with the least incoming edges are processed first.
const sortBy = key => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

function positionPass(g, layer, gridStep, precedessorGetter) {
  layer = layer.map(function(n, i) {
    let predecessors = precedessorGetter(n);
    let hasDummyPredecessor = false;

    predecessors.forEach(function(p) {
      if (isDummyNode(p)) {
        hasDummyPredecessor = true;
      }
    });

    return {
      id: n,
      index: i,
      predecessors: predecessors,
      dummy: hasDummyPredecessor,
      x: null
    };
  });

  let nodeOrder = layer.sort(
    sortBy(function(n) {
      return n.dummy ? -1 : 1;
    })
  );

  nodeOrder.forEach(function(n, i) {
    let node = g.node(n.id);
    // console.log('positioning node', n, node);

    // Optimal position as the median of the position of the predecessors.
    // In case of an even number of predecessors, use the left of the two
    // center predecessors. In case there are no precedessors, place in the
    // left most possible position.
    let optimalX;
    if (n.predecessors.length > 0) {
      let positions = n.predecessors.map(function(p) {
        return g.node(p).x;
      });
      positions.sort();

      // console.log('predecessor positions', positions);

      optimalX = positions[Math.floor((positions.length - 1) / 2)];
    } else {
      optimalX = -Infinity;
    }

    // console.log('optimal position', optimalX);

    // Now compute the range of valid positions.
    if (i) {
      let minX = -Infinity,
        maxX = Infinity;

      let paddingLeft = 0;
      for (let idx = n.index - 1; idx >= 0; idx--) {
        // console.log('left', layer[idx]);
        paddingLeft += gridStep;
        if (layer[idx].x !== null) {
          minX = layer[idx].x + paddingLeft;
          break;
        }
      }

      let paddingRight = 0;
      for (let idx = n.index + 1; idx < layer.length; idx++) {
        // console.log('right', layer[idx]);
        paddingRight += gridStep;
        if (layer[idx].x !== null) {
          maxX = layer[idx].x - paddingRight;
          break;
        }
      }

      // console.log('range', minX, maxX);

      if (optimalX < minX) {
        optimalX = minX;
      } else if (optimalX > maxX) {
        optimalX = maxX;
      }
    }

    if (optimalX === -Infinity) {
      optimalX = 0;
    }

    n.x = optimalX;
    node.x = optimalX;
  });
}*/

export default position;
